// @flow

import {
  ADDRESSES_SUCCESS,
  ADDRESSES_CREATE_SUCCESS,
  ADDRESSES_DELETE_SUCCESS,
  ADDRESSES_CHECKOUT_UPDATE_DELIVERY_SUCCESS,
  ADDRESSES_CHECKOUT_UPDATE_BILLING_SUCCESS,
  ADDRESSES_UPDATE_SUCCESS,
} from '../actions/address';

export type Address = {
  id: number,
  first_name: string,
  last_name: string,
  phone_number: string,
  line2: string,
  street: string,
  zip: string,
  city: string,
  country: string,
  default_billing: boolean,
  default_delivery: boolean,
};

const initialState: Address[] = [];

export default (
  state: Address[] = initialState,
  action: { type: string, payload: any }
): Address[] => {
  switch (action.type) {
    case ADDRESSES_SUCCESS: {
      return [...action.payload].sort((a: Address, b: Address) => a.id - b.id);
    }
    case ADDRESSES_CREATE_SUCCESS: {
      return [...state, action.payload].sort(
        (a: Address, b: Address) => a.id - b.id
      );
    }
    case ADDRESSES_DELETE_SUCCESS: {
      return state
        .filter(item => item.id !== action.payload)
        .sort((a: Address, b: Address) => a.id - b.id);
    }
    case ADDRESSES_UPDATE_SUCCESS: {
      return [
        ...state.filter(item => item.id !== action.payload.id),
        action.payload,
      ].sort((a: Address, b: Address) => a.id - b.id);
    }
    case ADDRESSES_CHECKOUT_UPDATE_BILLING_SUCCESS:
    case ADDRESSES_CHECKOUT_UPDATE_DELIVERY_SUCCESS: {
      return [
        ...state.filter(item => item.id !== action.payload.id),
        action.payload,
      ].sort((a: Address, b: Address) => a.id - b.id);
    }
    default:
      return state;
  }
};

export const isValidAddress = (address?: Address, phoneRequired?: boolean) => {
  if (!address) {
    return false;
  }

  if (typeof address !== 'object') {
    return false;
  }

  const {
    id,
    first_name,
    last_name,
    phone_number,
    street,
    zip,
    city,
    country,
  } = address;

  if (
    [first_name, last_name, street, zip, city, country].reduce(
      (prev, next) => !!next && prev,
      true
    ) === false
  ) {
    return false;
  }

  if (zip && zip.toString().length !== 4) {
    return false;
  }

  if (first_name.length < 3 || last_name.length < 3) {
    return false;
  }

  if (street.length < 5) {
    return false;
  }

  if (city.length < 2) {
    return false;
  }

  if (country && ['Schweiz', 'CH'].indexOf(country) === -1) {
    return false;
  }

  if (!id) {
    if ((phone_number !== '' && phone_number !== null) || phoneRequired) {
      const phoneNumberValidated = /^\+?1?\d{8,15}/.exec(phone_number.trim());
      if (!phoneNumberValidated) {
        return false;
      }
    }
  }

  return true;
};
