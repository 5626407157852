// @flow

import type { Address } from './address';
import {
  isoDateToDateString,
  isoStringDateIsInPast,
} from '../utils/dateHelper';
import type { PaymentType } from './payment';

export type Subscription = {
  id: number,
  plan: number,
  serving_option: number,
  interval: number,
  nextDelivery: number,
  status: string,
  plan_title: string,
  serving_option_title: string,
  delivery_weekday: number,
  is_editable: boolean,
  start: string,
  end_date?: string,
  valid_from?: string,
  delivery_address: Address,
  billing_address: Address,
  payment_source: ?number,
  token?: string,
  livemode?: boolean,
  payment_description: {
    name?: string,
    description?: string,
    type?: PaymentType,
  },
};

export type SubscriptionPaymentUpdate = {
  id: number,
  delivery_address: Address,
  billing_address: Address,
  payment_source?: number,
  status: string,
  token?: string,
  livemode?: boolean,
  nonce?: string,
};

export const showSubscriptionStartOrEndState = (
  start: string,
  end?: string
): string => {
  if (end) {
    return `Beendet zum ${isoDateToDateString(end)}`;
  }

  return start && isoStringDateIsInPast(start) === false
    ? `Gültig ab ${isoDateToDateString(start)}`
    : '';
};
