import {
  APP_SET_TOKEN,
  APP_TOGGLE_NAVIGATION,
  APP_TOGGLE_NAVIGATION_CLOSE,
} from '../constants/actionTypes';

export const appSetToken = payload => ({
  type: APP_SET_TOKEN,
  payload,
});

export const appToggleNavigation = () => ({
  type: APP_TOGGLE_NAVIGATION,
});

export const appToggleNavigationClose = () => ({
  type: APP_TOGGLE_NAVIGATION_CLOSE,
});
