// @flow

import * as React from 'react';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import {
  dateFormatLong,
  getValidDeliveryDates,
  isoDateStringToDate,
} from '../../utils/dateHelper';
import Select from '../inputs/Select';
import Button from '../inputs/Button';
import type { Delivery, Plan, ServingOption } from '../../types';
import { CHECKOUT_DELIVERY_TIME } from '../../constants/constants';

type Props = {
  delivery: Delivery,
  setDialogClosed: Function,
  updateDelivery: Function,
  appChangeLocation: Function,
  plans: Plan[],
  status: boolean,
};

type State = {
  deliveryId: ?number,
  planId: ?number,
  servingOptionId: ?number,
  isActive: boolean,
  deliveryDate: string,
  changeForUpcoming: boolean,
};

class DeliveryDialog extends React.Component<Props, State> {
  state = {
    deliveryId: null,
    planId: null,
    servingOptionId: null,
    isActive: true,
    deliveryDate: '',
    changeForUpcoming: false,
  };

  componentWillMount() {
    const { delivery } = this.props;
    this.setState({
      deliveryId: delivery.id,
      planId: delivery.plan,
      servingOptionId: delivery.serving_option,
      isActive: delivery.status === 'OPEN',
      deliveryDate: delivery.delivery_date,
    });
  }

  handleRequestClose = () => this.props.setDialogClosed();

  handleDialogOkClick = () => {
    const { deliveryId } = this.state;
    this.props.updateDelivery(
      deliveryId,
      {
        delivery_date: this.state.deliveryDate,
        plan: this.state.planId,
        status: this.state.isActive ? 'OPEN' : 'PAUSED',
        serving_option: this.state.servingOptionId,
      },
      this.props.setDialogClosed
    );
  };

  handleServingOptionChange = (event: SyntheticInputEvent<>) =>
    this.setState({ servingOptionId: parseInt(event.target.value, 10) });

  handleDateChange = (event: SyntheticInputEvent<>) =>
    this.setState({ deliveryDate: event.target.value });

  handlePlanChange = (event: SyntheticInputEvent<>) => {
    const { plans } = this.props;

    const nextPlanId: number = parseInt(event.target.value, 10);
    const nextPlan: ?Plan = plans.find(p => p.id === nextPlanId);
    if (!nextPlan) {
      // todo: throw error
      return;
    }
    const { planId, servingOptionId } = this.state;
    const plan: ?Plan = plans.find(p => p.id === planId);

    if (!plan) {
      // todo: throw error
      return;
    }

    const servingOption = plan.serving_options.find(
      s => s.id === servingOptionId
    );

    if (!servingOption) {
      // todo: throw error
      return;
    }

    const quantity = servingOption.quantity;
    const nextServingOption = nextPlan.serving_options.find(
      s => s.quantity === quantity
    );
    const nextServingOptionId = nextServingOption
      ? nextServingOption.id
      : nextPlan.serving_options[0].id;

    this.setState({ planId: nextPlanId, servingOptionId: nextServingOptionId });
  };

  render() {
    const { plans, delivery } = this.props;
    const { planId, servingOptionId, deliveryDate, isActive } = this.state;

    if (!planId) {
      return null;
    }

    if (!servingOptionId) {
      return null;
    }

    const plan: ?Plan = plans.find(p => p.id === planId);
    if (!plan) {
      return null;
    }

    const servingOptions: ServingOption[] = plan.serving_options;
    const deliveryOptions = getValidDeliveryDates(delivery.delivery_options);

    const boxIsInDelivery =
      delivery.status === 'PROCESS' || delivery.status === 'DELIVERY';

    return (
      <React.Fragment>
        <div className="dialog__header">
          <div className="dialog__header-bar">
            <div className="dialog__title">
              <span className="dialog__title--bold">{delivery.plan_title}</span>{' '}
              für {delivery.serving_option_title}
            </div>
            <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={isActive || boxIsInDelivery}
                    onChange={(event, checked) =>
                      this.setState({
                        isActive: checked,
                      })
                    }
                    disabled={boxIsInDelivery}
                    aria-label="Aktiv"
                    color="primary"
                  />
                }
                label={`Box wird ${
                  isActive || boxIsInDelivery ? '' : 'nicht '
                } geliefert`}
              />
            </div>
          </div>
          <p className="dialog__text">{`Liefertermin: ${dateFormatLong(
            isoDateStringToDate(delivery.delivery_date)
          )}, ${CHECKOUT_DELIVERY_TIME}`}</p>

          <div className="dialog__hr" />
        </div>

        <div className="dialog__content">
          <Grid container spacing={16}>
            <Grid item xs={12}>
              Du möchtest diese Lieferung ändern?
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <label className="form-control__label" htmlFor="setBoxType">
                  Produkt
                </label>
                <Select
                  value={planId.toString()}
                  onChange={this.handlePlanChange}
                  id={'setPlanType'}
                  fullWidth
                  disabled={!isActive}
                >
                  {plans.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.title}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <label className="form-control__label" htmlFor="servingOptions">
                  Grösse der Box
                </label>
                <Select
                  value={servingOptionId.toString()}
                  onChange={this.handleServingOptionChange}
                  id={'setServingOption'}
                  disabled={!isActive}
                >
                  {servingOptions.map((item, index) => (
                    <option key={index} value={item.id}>
                      Für {item.title}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <label className="form-control__label" htmlFor="Liefertag">
                  Liefertag
                </label>
                <Select
                  value={deliveryDate}
                  onChange={this.handleDateChange}
                  id={'Liefertag'}
                  fullWidth
                  disabled={!isActive}
                >
                  {deliveryOptions.map(d => (
                    <option key={`deliveryDate${d.date}`} value={d.date}>
                      {d.title}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <label className="form-control__label" htmlFor="Lieferzeitraum">
                  Lieferzeitraum
                </label>
                <Select
                  value={CHECKOUT_DELIVERY_TIME}
                  id={'Liefertermin'}
                  fullWidth
                  disabled={!isActive}
                  onChange={() => {}}
                >
                  <option value={CHECKOUT_DELIVERY_TIME}>
                    {CHECKOUT_DELIVERY_TIME}
                  </option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              Du möchtest alle zukünftigen Lieferungen ändern? Ändere Dein{' '}
              <span
                className="link"
                onClick={() =>
                  Promise.all([
                    this.handleRequestClose(),
                    this.props.appChangeLocation(
                      `/subscriptions/${delivery.subscription}/edit`
                    ),
                  ])
                }
              >
                Abo
              </span>
              .
            </Grid>
          </Grid>
        </div>
        <div className="dialog__actions">
          <Button variant="flat" onClick={this.handleRequestClose}>
            Verwerfen
          </Button>
          <Button onClick={this.handleDialogOkClick} color="primary">
            Lieferung aktualisieren
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

export default DeliveryDialog;
