import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import AddressForm from '../forms/AddressForm';
import CheckoutNavigation from './CheckoutNavigation';
import {
  ADDRESS_BILLING,
  ADDRESS_DELIVERY,
  ADDRESS_DELIVERY_AND_BILLING,
} from '../../constants/constants';
import { CHECKOUT_TYPE_GIFTBOX_BUY } from '../../redux/checkout';
import AddressSelector from '../address/AddressSelector';

class Addresses extends Component {
  componentWillMount() {
    this.props.loadAddresses();
  }

  handleBillingAddressClick = () => {
    const { hasAlternateBilling } = this.props.checkout;
    if (hasAlternateBilling) {
      this.props.checkoutSetAddressBillingId(undefined);
    }
    this.props.checkoutToggleHasAlternateBilling();
  };

  render() {
    const { checkout, navigation } = this.props;

    return (
      <div className={'checkout__step'}>
        {checkout.productType !== CHECKOUT_TYPE_GIFTBOX_BUY ? (
          <Fragment>
            <h3>
              {checkout.hasAlternateBilling
                ? ADDRESS_DELIVERY
                : ADDRESS_DELIVERY_AND_BILLING}
            </h3>
            {this.props.addresses.length > 0 ? (
              <AddressSelector
                addressType={'delivery'}
                address={checkout.addresses.delivery}
                addresses={this.props.addresses}
                setAddress={this.props.checkoutSetAddress}
              />
            ) : null}
            {checkout.addresses.delivery &&
            checkout.addresses.delivery.hasOwnProperty('id') ? null : (
              <AddressForm
                addressType={'delivery'}
                address={checkout.addresses.delivery}
                handleFormInput={this.props.checkoutSetAddress}
                handleFormFieldBlur={this.props.checkoutSetAddressField}
              />
            )}

            {!checkout.isDeliveryOnly ? (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkout.hasAlternateBilling || false}
                    onChange={() => this.handleBillingAddressClick()}
                    color="primary"
                  />
                }
                label="Abweichende Rechnungsadresse"
              />
            ) : null}
          </Fragment>
        ) : null}

        {checkout.hasAlternateBilling || checkout.hasOnlyBillingAddress ? (
          <Fragment>
            <h3>{ADDRESS_BILLING}</h3>
            {this.props.addresses.length > 0 ? (
              <AddressSelector
                addressType={'billing'}
                address={checkout.addresses.billing}
                addresses={this.props.addresses}
                setAddress={this.props.checkoutSetAddress}
              />
            ) : null}

            {checkout.addresses.billing &&
            checkout.addresses.billing.hasOwnProperty('id') ? null : (
              <AddressForm
                showPhoneHint={
                  checkout.productType === CHECKOUT_TYPE_GIFTBOX_BUY
                }
                addressType={'billing'}
                address={checkout.addresses.billing}
                handleFormInput={this.props.checkoutSetAddress}
                handleFormFieldBlur={this.props.checkoutSetAddressField}
              />
            )}
          </Fragment>
        ) : null}

        <div>
          <CheckoutNavigation {...navigation} />
        </div>
      </div>
    );
  }
}

Addresses.propTypes = {
  navigation: PropTypes.object.isRequired,
  checkout: PropTypes.object.isRequired,
  checkoutToggleHasAlternateBilling: PropTypes.func.isRequired,
  checkoutSetAddressBillingId: PropTypes.func.isRequired,
  checkoutSetAddressField: PropTypes.func.isRequired,
  checkoutSetAddress: PropTypes.func.isRequired,
  loadAddresses: PropTypes.func.isRequired,
  addresses: PropTypes.array,
};

export default Addresses;
