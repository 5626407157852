// @flow

import * as React from 'react';
import Button from '../inputs/Button';
import InputField from '../inputs/InputField';

type Props = {
  handleFormSubmit: Function,
  redirect: string,
  labels: any,
  formType: string,
  loading: boolean,
  postAction?: Function,
};

type State = {
  email: string,
  password: string,
  hasError: boolean,
  emailErrorMessage: string,
  passwordErrorMessage: string,
};

export default class extends React.Component<Props, State> {
  state = {
    email: '',
    password: '',
    hasError: false,
    emailErrorMessage: '',
    passwordErrorMessage: '',
  };

  handleFormSubmit = async (event: SyntheticInputEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { email, password } = this.state;

    // todo: if form is register, check password strength
    if (email.length === 0 || password.length === 0) {
      this.setState({ hasError: true });
      return;
    }

    const { redirect, postAction } = this.props;
    try {
      await this.props.handleFormSubmit(
        { email, password },
        redirect,
        postAction
      );

      this.setState({ hasError: false });
    } catch (err) {
      // todo: set error
      this.setState({
        hasError: true,
        emailErrorMessage: err.hasOwnProperty('email')
          ? err.email.join(' ')
          : '',
        passwordErrorMessage: err.hasOwnProperty('password')
          ? err.password.join(' ')
          : '',
      });
    }
  };

  handleInputChange = (name: string) => (
    event: SyntheticInputEvent<HTMLInputElement>
  ) => this.setState({ [name]: event.target.value });

  render() {
    const { formType, loading, labels } = this.props;
    const {
      email,
      password,
      hasError,
      emailErrorMessage,
      passwordErrorMessage,
    } = this.state;

    return (
      <form autoComplete="off" onSubmit={this.handleFormSubmit}>
        <div className="form-control__group">
          <InputField
            id={`${formType}Email`}
            fullWidth
            type={'email'}
            hasError={hasError}
            placeholder={labels.email}
            value={email}
            error={emailErrorMessage}
            onChange={this.handleInputChange('email')}
            disabled={loading}
            required={true}
          />
        </div>
        <div className="form-control__group">
          <InputField
            id={`${formType}Password`}
            fullWidth
            type={'password'}
            hasError={hasError}
            placeholder={labels.password}
            error={passwordErrorMessage}
            value={password}
            onChange={this.handleInputChange('password')}
            disabled={loading}
            required={true}
          />
        </div>

        <Button
          type="submit"
          variant="raised"
          color="primary"
          disabled={loading}
        >
          {labels.submit}
        </Button>
      </form>
    );
  }
}
