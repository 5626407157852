import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { loginUser, registerUser } from '../../actions/user';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import LoginSignUpForm from '../../components/forms/LoginSignUpForm';
import {
  BUTTON_CREATE_ACCOUNT,
  BUTTON_LOGIN,
  FORM_LOGIN_LABEL_EMAIL,
  FORM_LOGIN_LABEL_PASSWORD,
} from '../../constants/constants';
import { ROUTE_PASSWORD_RESET } from '../../constants/routes';

class LoginRegister extends Component {
  render() {
    const {
      loginUser,
      registerUser,
      redirect,
      postAction,
      loading,
    } = this.props;

    return (
      <Grid container spacing={40}>
        <Grid item xs={12} sm={6}>
          <h2>Ich bin bereits JUTS-Kunde</h2>
          <p>Melde dich bitte mit deinen Benutzerdaten an.</p>
          <LoginSignUpForm
            formType={'login'}
            handleFormSubmit={loginUser}
            redirect={redirect}
            postAction={postAction}
            loading={loading}
            labels={{
              email: FORM_LOGIN_LABEL_EMAIL,
              password: FORM_LOGIN_LABEL_PASSWORD,
              submit: BUTTON_LOGIN,
            }}
          />
          <a
            className="link link--gutter"
            href={`${ROUTE_PASSWORD_RESET}?ref=checkout`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Passwort vergessen
          </a>
        </Grid>
        <Grid item xs={12} sm={6}>
          <h2>Ich bin Neukunde</h2>
          <p>Erstelle jetzt dein persönliches Benutzerkonto</p>
          <LoginSignUpForm
            formType={'register'}
            handleFormSubmit={registerUser}
            loading={loading}
            redirect={redirect}
            postAction={postAction}
            labels={{
              email: FORM_LOGIN_LABEL_EMAIL,
              password: FORM_LOGIN_LABEL_PASSWORD,
              submit: BUTTON_CREATE_ACCOUNT,
            }}
          />
        </Grid>
      </Grid>
    );
  }
}

LoginRegister.propTypes = {
  loginUser: PropTypes.func.isRequired,
  registerUser: PropTypes.func.isRequired,
  postAction: PropTypes.func.isRequired,
};

export default connect(
  state => ({ loading: state.loading.user }),
  {
    loginUser,
    registerUser,
  }
)(LoginRegister);
