import './utils/polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './containers/App';
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';
import createHistory from 'history/createBrowserHistory';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { routerMiddleware, ConnectedRouter } from 'react-router-redux';
import { loadState, saveState } from './middleware/localStorage';
import { loginUserFromToken, userLoginFromLocalStorage } from './actions/user';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
import { loadPlans } from './actions/plans';
import { loadAddresses } from './actions/address';
import { loadPaymentsSources } from './actions/payment';

const history = createHistory();
export const reactRouterMiddleware = routerMiddleware(history);
const preloadedState = loadState();
export const store = configureStore(preloadedState);

export const API_ROOT = process.env.REACT_APP_API_ROOT;
export const STRIPE_PK = process.env.REACT_APP_STRIPE_PK;
export const LANDING_PAGE_URL = process.env.REACT_APP_LANDING_PAGE_URL;

const theme = createMuiTheme({
  palette: {
    primary: {
      light: red[300],
      main: '#f05a23',
      dark: red[700],
    },
    secondary: {
      light: grey[300],
      main: grey[500],
      dark: grey[700],
    },
  },
  typography: {
    fontFamily: '"Bariol", "Arial", "sans-serif"',
    fontSize: '16',
    display1: {
      fontFamily: '"Luna", "Arial", "sans-serif"',
      color: '#282828',
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '1rem',
    },
  },
});

store.dispatch(userLoginFromLocalStorage());
store.dispatch(loadPlans());
store.dispatch(loadAddresses());
store.dispatch(loadPaymentsSources());
store.subscribe(() => saveState({ checkout: store.getState().checkout }));

try {
  const fragment = window.location.hash.substr(1);
  const params = new URLSearchParams(fragment);
  if (params.has('impersonate')) {
    const token = params.get('impersonate');
    store.dispatch(loginUserFromToken({ token }));
  }
} catch (err) {
  console.log(err);
}

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root')
);
unregister();

// registerServiceWorker();
