import { fetchApi } from '../middleware/api';

export const INVOICES_REQUEST = 'INVOICES_REQUEST';
export const INVOICES_SUCCESS = 'INVOICES_SUCCESS';
export const INVOICES_FAILURE = 'INVOICES_FAILURE';

export const loadInvoices = () => async (dispatch, getState) => {
  dispatch({ type: INVOICES_REQUEST });
  try {
    return await fetchApi({
      endpoint: '/api/v1/invoices/',
      authorization: getState().app.accessToken,
    }).then(response =>
      dispatch({ type: INVOICES_SUCCESS, payload: response })
    );
  } catch (err) {
    dispatch({ type: INVOICES_FAILURE, payload: err });
  }
};

export const INVOICE_SHOW_REQUEST = 'INVOICE_SHOW_REQUEST';
export const INVOICE_SHOW_SUCCESS = 'INVOICE_SHOW_SUCCESS';
export const INVOICE_SHOW_FAILURE = 'INVOICE_SHOW_FAILURE';

export const showInvoice = invoiceNumber => async (dispatch, getState) => {
  dispatch({ type: INVOICE_SHOW_REQUEST });

  try {
    const blob = await fetchApi({
      endpoint: `/api/v1/invoices/${invoiceNumber}/`,
      authorization: getState().app.accessToken,
      isFile: true,
    });

    dispatch({ type: INVOICE_SHOW_SUCCESS });
    showFile(blob, invoiceNumber.toLowerCase());
  } catch (err) {
    dispatch({ type: INVOICE_SHOW_FAILURE, payload: err });
  }
};

const showFile = (blob, fileName) => {
  const file = new Blob([blob], { type: 'application/pdf' });
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(file);
    return;
  }

  // fallback
  const fileURL = window.URL.createObjectURL(file);
  const link = document.createElement('a');
  link.href = fileURL;
  link.download = `${fileName}.pdf`;
  document.body.appendChild(link);
  link.click();

  setTimeout(function() {
    window.URL.revokeObjectURL(fileURL);
    document.body.removeChild(link);
  }, 100);
};
