import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Confirmation.css';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Address from '../address/Address';
import CheckoutVoucherBox from './CheckoutVoucherBox';
import CheckoutSubmit from './CheckoutSubmit';

import {
  CHECKOUT_TYPE_GIFTBOX_REDEEM,
  CHECKOUT_TYPE_SUBSCRIPTION,
  CHECKOUT_TYPE_SUBSCRIPTION_TRIAL,
} from '../../redux/checkout';
import PaymentCard from '../payments/PaymentCard';
import { getBillingAgreementText } from '../../utils/checkout';

const getPrice = (checkout, servingOption) => ({
  total: servingOption ? servingOption.price : null,
  voucherCode: checkout.discountVoucherCode,
  voucherAmount: checkout.discountVoucherAmount,
  voucherValid: checkout.discountVoucherValid,
  voucherDeliveries: checkout.discountVoucherDeliveries,
});

const CheckoutDetailBox = ({ title, handleEditClick, children }) => (
  <div className="confirmationBox">
    <header className="confirmationBox__header">
      <div className="confirmationBox__title">{title}</div>
      <div className="confirmationBox__action">
        <IconButton onClick={handleEditClick}>
          <EditIcon size={'small'} />
        </IconButton>
      </div>
    </header>
    <div className="conformationBox__content">{children}</div>
  </div>
);

const getCardText = checkout =>
  checkout.paymentSource && checkout.paymentSource.card
    ? `XXXX-XXXX-XXXX-${checkout.paymentSource.card.last4} ${
        checkout.paymentSource.card.exp_month
      }/${checkout.paymentSource.card.exp_year}`
    : '';

export class Confirmation extends Component {
  render() {
    const { checkout, selectedPlan, header } = this.props;
    const servingOption = selectedPlan.serving_options.find(
      s => s.id === checkout.servingOptionId
    );
    const price = getPrice(checkout, servingOption);
    const hasPrice = checkout.productType !== CHECKOUT_TYPE_GIFTBOX_REDEEM;

    return (
      <Grid container spacing={40}>
        <Grid item xs={12} sm={6}>
          {header}
        </Grid>
        <Grid item xs={12} sm={6}>
          {!checkout.hasOnlyBillingAddress ? (
            <CheckoutDetailBox
              title={'Lieferadresse'}
              handleEditClick={() => this.props.setStep('address', 'address')}
            >
              <Address address={checkout.addresses.delivery} />
            </CheckoutDetailBox>
          ) : null}
          {!checkout.isDeliveryOnly ? (
            <CheckoutDetailBox
              title={'Rechnungsadresse'}
              handleEditClick={() => this.props.setStep('address', 'address')}
            >
              {checkout.hasAlternateBilling ||
              checkout.hasOnlyBillingAddress ? (
                <Address address={checkout.addresses.billing} />
              ) : (
                <p className="confirmationBox__text">
                  Entspricht meiner Lieferadresse
                </p>
              )}
            </CheckoutDetailBox>
          ) : null}
          {checkout.productType !== CHECKOUT_TYPE_GIFTBOX_REDEEM ? (
            <CheckoutDetailBox
              title={'Zahlungsart'}
              handleEditClick={() => this.props.setStep('payment', 'payment')}
            >
              <PaymentCard
                text={getCardText(checkout)}
                brand={checkout.paymentType}
              />
            </CheckoutDetailBox>
          ) : null}
          {checkout.productType === CHECKOUT_TYPE_SUBSCRIPTION ||
          checkout.productType === CHECKOUT_TYPE_SUBSCRIPTION_TRIAL ? (
            <CheckoutVoucherBox
              checkout={checkout}
              checkoutCheckVoucher={this.props.checkoutCheckVoucher}
              checkoutClearVoucher={this.props.checkoutClearVoucher}
            />
          ) : null}

          <CheckoutSubmit
            paymentBrainTreeClientToken={checkout.paymentBrainTreeClientToken}
            paymentBrainTreeBillingAgreement={getBillingAgreementText(
              selectedPlan ? selectedPlan.title : '',
              servingOption ? servingOption.title : '',
              price ? price.total : ''
            )}
            paymentType={checkout.paymentType}
            productType={checkout.productType}
            title={'Gesamtsumme'}
            hasPrice={hasPrice}
            price={price}
            action={this.props.checkoutSubmitNew}
            disabled={this.props.loading || this.props.stepsCompleted === false}
          />
          {checkout.productType === CHECKOUT_TYPE_SUBSCRIPTION ? (
            <div className="checkout__check-container">
              <div className="checkout__check">
                Keine Mindest-Vertragslaufzeiten.
              </div>
              <div className="checkout__check">
                Pausieren und kündigen jederzeit möglich.
              </div>
            </div>
          ) : null}
        </Grid>
      </Grid>
    );
  }
}

Confirmation.propTypes = {
  isLoading: PropTypes.bool,
  checkout: PropTypes.object.isRequired,
  selectedPlan: PropTypes.object.isRequired,
  selectedSource: PropTypes.object,
  setStep: PropTypes.func.isRequired,
  checkoutCheckVoucher: PropTypes.func.isRequired,
  checkoutClearVoucher: PropTypes.func.isRequired,
  stepsCompleted: PropTypes.bool,
  loading: PropTypes.bool,
};

export default Confirmation;
