// @flow

import {
  SUBSCRIPTION_SUCCESS,
  SUBSCRIPTIONS_DELETE_SUCCESS,
  SUBSCRIPTIONS_PAUSE_SUCCESS,
  SUBSCRIPTIONS_RESUME_SUCCESS,
  SUBSCRIPTIONS_SUCCESS,
  SUBSCRIPTIONS_UPDATE_ADDRESSES_AND_PAYMENT_SUCCESS,
  SUBSCRIPTIONS_UPDATE_SUCCESS,
} from '../actions/subscription';
import type { Subscription } from '../types/subscription';

const initialState: Subscription[] = [];

export default (
  state: Subscription[] = initialState,
  action: { type: string, payload: Subscription }
): Subscription[] => {
  switch (action.type) {
    case SUBSCRIPTION_SUCCESS: {
      if (state.find(s => s.id === action.payload.id) === undefined) {
        return [...state, action.payload];
      }

      return state.map(
        (s: Subscription) => (s.id === action.payload.id ? action.payload : s)
      );
    }

    case SUBSCRIPTIONS_PAUSE_SUCCESS: {
      return state.map(
        s => (s.id === action.payload.id ? { ...s, status: 'PAUSED' } : s)
      );
    }

    case SUBSCRIPTIONS_RESUME_SUCCESS: {
      return state.map(
        s => (s.id === action.payload.id ? { ...s, status: 'ACTIVE' } : s)
      );
    }

    case SUBSCRIPTIONS_UPDATE_SUCCESS: {
      return [
        ...state.filter(d => d.id !== action.payload.id),
        action.payload,
      ].sort((a, b) => a.id - b.id);
    }

    case SUBSCRIPTIONS_DELETE_SUCCESS: {
      return state.filter(s => s.id !== action.payload.id);
    }

    // todo: change to
    case SUBSCRIPTIONS_SUCCESS:
      // $FlowFixMe
      return [...action.payload].sort((a, b) => a.id - b.id);

    case SUBSCRIPTIONS_UPDATE_ADDRESSES_AND_PAYMENT_SUCCESS:
      return state.map(
        s => (s.id === action.payload.id ? { ...s, ...action.payload } : s)
      );

    default:
      return state;
  }
};
