// @flow

import * as React from 'react';
import './Card.css';
import { getPaymentIcon } from '../../utils/format';

type Props = {
  brand: string,
  text?: string,
};

const PaymentCard = ({ brand, text }: Props) => (
  <div className="card">
    <img
      alt={brand}
      height={40}
      width={64}
      className="card__image"
      {...getPaymentIcon(brand)}
    />
    {text && <p className="card__title">{text}</p>}
  </div>
);

export default PaymentCard;

// {`XXXX-XXXX-XXXX-${card.last4} ${
//         card.exp_month
//         }/${card.exp_year}`}
