// @flow

import * as React from 'react';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import {
  CHECKOUT_DELIVERY_INTERVAL,
  CHECKOUT_PRICE_INFO,
} from '../../constants/constants';
import { deliveryIntervalOptions } from '../../redux/checkout';
import { getValidDeliveryDates } from '../../utils/dateHelper';
import { ROUTE_DASHBOARD } from '../../constants/routes';
import './SubscriptionEdit.css';
import { currencyFormat, getProductImage } from '../../utils/format';
import Button from '../inputs/Button';
import Select from '../inputs/Select';
import type {
  Subscription,
  Plan,
  DeliveryOption,
  ServingOption,
} from '../../types';

type Props = {
  subscription: Subscription,
  plans: Plan[],
  loading: boolean,
  updateSubscription: Function,
};

type State = {
  plan: number,
  interval: number,
  servingOption: number,
  isActive: boolean,
  deliveryOptions: DeliveryOption,
  selectedDeliveryWeekday: number,
  selectedPlanId: number,
  selectedServingOptionId: number,
  selectedInterval: number,
  subscriptionUpdateIsValidFrom: string,
  price: string,
  changeDelivery: string,
};

class SubscriptionEdit extends React.Component<Props, State> {
  componentDidMount() {
    const { subscription, plans } = this.props;

    const selectedPlan = plans.find(p => p.id === subscription.plan);

    if (!selectedPlan) {
      return;
    }

    const servingOptions = selectedPlan.serving_options;
    const deliveryOptions = getValidDeliveryDates(selectedPlan.delivery_dates);
    const selectedServingOption = servingOptions.find(
      s => s.id === subscription.serving_option
    );

    if (!selectedServingOption) {
      return;
    }

    const interval = subscription.interval;
    const selectedDeliveryWeekday = subscription.delivery_weekday;
    const subscriptionUpdateIsValidFrom = selectedPlan.delivery_dates[0];
    const isActive = subscription.status === 'ACTIVE';

    this.setState({
      plan: subscription.plan,
      interval: subscription.interval, // $FlowFixMe
      servingOption: subscription.serving_option,
      isActive,
      deliveryOptions,
      selectedDeliveryWeekday: selectedDeliveryWeekday || 0,
      selectedPlanId: selectedPlan.id,
      selectedInterval: interval,
      selectedServingOptionId: selectedServingOption.id,
      subscriptionUpdateIsValidFrom,
      price: selectedServingOption.price,
    });
  }

  handleSubscriptionUpdate = () => {
    const { subscription } = this.props;

    const {
      selectedPlanId,
      selectedInterval,
      selectedServingOptionId,
      selectedDeliveryWeekday,
      changeDelivery,
    } = this.state;

    const data = {
      plan: selectedPlanId,
      interval: selectedInterval,
      serving_option: selectedServingOptionId,
      status: subscription.status,
      delivery_address: subscription.delivery_address,
      billing_address: subscription.billing_address,
      payment_source: subscription.payment_source,
      delivery_weekday: selectedDeliveryWeekday,
      change_delivery: changeDelivery,
    };

    this.props.updateSubscription(
      subscription.id,
      data,
      ROUTE_DASHBOARD,
      undefined
    );
  };

  handleServingChange = (event: SyntheticInputEvent<>) =>
    this.setState({
      selectedServingOptionId: parseInt(event.target.value, 10),
    });

  handleIntervalChange = (event: SyntheticInputEvent<>) =>
    this.setState({ selectedInterval: parseInt(event.target.value, 10) });

  handleChangeDelivery = (event: SyntheticInputEvent<EventTarget>) =>
    this.setState({ changeDelivery: event.target.value });

  handleDeliveryWeekday = (event: SyntheticInputEvent<EventTarget>) =>
    this.setState({
      selectedDeliveryWeekday: parseInt(event.target.value, 10),
    });

  handlePlanChange = (event: SyntheticInputEvent<EventTarget>) => {
    const { plans } = this.props;

    const nextPlanId = parseInt(event.target.value, 10);
    const nextPlan: ?Plan = plans.find(p => p.id === nextPlanId);
    if (!nextPlan) {
      return;
    }

    const { selectedPlanId, selectedServingOptionId } = this.state;
    const plan: ?Plan = plans.find(p => p.id === selectedPlanId);
    if (!plan) {
      return;
    }

    const servingOption: ?ServingOption = plan.serving_options.find(
      s => s.id === selectedServingOptionId
    );

    if (!servingOption) {
      return;
    }

    const quantity = servingOption.quantity;
    const nextServingOption: ?ServingOption = nextPlan.serving_options.find(
      s => s.quantity === quantity
    );

    if (!nextServingOption) {
      return;
    }

    const nextServingOptionId = nextServingOption
      ? nextServingOption.id
      : nextPlan.serving_options[0].id;

    this.setState({
      selectedPlanId: nextPlanId,
      selectedServingOptionId: nextServingOptionId,
    });
  };

  render() {
    if (!this.state) {
      return null;
    }

    const {
      selectedPlanId,
      selectedServingOptionId,
      isActive,
      selectedInterval,
      changeDelivery,
      selectedDeliveryWeekday,
      price,
      plan,
    } = this.state;

    if (!selectedPlanId) {
      return null;
    }

    const { plans, subscription, loading } = this.props;

    const selectedPlan = plans.find(p => p.id === selectedPlanId);
    if (!selectedPlan) {
      return null;
    }

    const servingOptions = selectedPlan.serving_options;
    const productImageSrc = getProductImage(plan);

    const updatedPriceObject = servingOptions.find(
      s => s.id === selectedServingOptionId
    );

    if (!updatedPriceObject) {
      return null;
    }

    const updatedPrice = updatedPriceObject.price;

    const pageTitle = `${subscription.plan_title} ${
      subscription.serving_option_title
    }`;

    return (
      <div>
        <Grid container>
          <Grid item xs={12} sm={12} md={2}>
            <img className="image" src={productImageSrc} alt={pageTitle} />
          </Grid>
          <Grid item xs={12} sm={12} md={10}>
            <h2>Dein Abonnement {pageTitle}</h2>
            <p className="edit-subscription__summary">
              Hier kannst Du die Standard-Einstellungen für{' '}
              <strong>alle künftigen</strong> Lieferungen Deines Abonnements{' '}
              {pageTitle} festlegen. Einstellungen für <strong>einzelne</strong>{' '}
              Lieferungen kannst Du unter "Mein Kalender" ändern.
            </p>
          </Grid>
        </Grid>

        <Grid container spacing={40}>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} sm={6}>
            <div className="form__group">
              <Select
                fullWidth
                label="Produkt"
                value={selectedPlanId.toString()}
                onChange={this.handlePlanChange}
                disabled={!isActive || loading === true}
              >
                {plans.map((plan: Plan) => (
                  <option
                    key={`subscription_update_plan_${plan.id}`}
                    value={plan.id}
                  >
                    {plan.title}
                  </option>
                ))}
              </Select>
            </div>

            <div className="form__group">
              <Select
                fullWidth
                label="Grösse der Box"
                value={selectedServingOptionId.toString()}
                onChange={this.handleServingChange}
                disabled={!isActive || loading === true}
              >
                {servingOptions.map((item, index) => (
                  <option key={index} value={item.id}>
                    Für {item.title}
                  </option>
                ))}
              </Select>
            </div>
            <div className="form__group">
              <Select
                fullWidth
                label={CHECKOUT_DELIVERY_INTERVAL}
                value={selectedInterval.toString()}
                onChange={this.handleIntervalChange}
                disabled={!isActive || loading === true}
              >
                {deliveryIntervalOptions.map((item, index) => (
                  <option key={index} value={item.interval}>
                    {item.title}
                  </option>
                ))}
              </Select>
            </div>
            <div className="form__group">
              <Select
                fullWidth
                label="Liefertag"
                value={
                  selectedDeliveryWeekday
                    ? selectedDeliveryWeekday.toString()
                    : 'unchanged'
                }
                onChange={this.handleDeliveryWeekday}
                disabled={!isActive || loading === true}
              >
                <option value={'unchanged'}>keine Änderung</option>
                <option value="1">Dienstag</option>
                <option value="2">Mittwoch</option>
                <option value="3">Donnerstag</option>
                <option value="4">Freitag</option>
              </Select>
            </div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <div className="form__group">
              <Select
                fullWidth
                label="Änderung gültig ab"
                value={changeDelivery}
                onChange={this.handleChangeDelivery}
                disabled={!isActive || loading === true}
              >
                <option value="0">Ab nächster Lieferung</option>
                <option value="1">Ab übernächster Lieferung</option>
              </Select>
            </div>

            <div className="edit-box">
              <div className="edit-box__label">Zusammenfassung</div>
              <div className="checkoutSubmit">
                <div className="priceBox__item priceBox__item--borderBottom">
                  <div className="priceBox__title">{'Bisherige Foodbox'}</div>
                  <div className="priceBox__price priceBox__price--gutterBottom">
                    {currencyFormat(price)}
                  </div>
                </div>

                <div className="priceBox__item">
                  <div className="priceBox__title">{'Neue Foodbox'}</div>
                  <div className="priceBox__price priceBox__price--gutterBottom">
                    {currencyFormat(updatedPrice)}
                  </div>
                </div>

                <Button
                  fullWidth
                  variant={'raised'}
                  color={'primary'}
                  onClick={this.handleSubscriptionUpdate}
                  disabled={loading}
                >
                  Änderung übernehmen
                </Button>
                <small className="checkoutSubmit__price">
                  {CHECKOUT_PRICE_INFO}
                </small>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default SubscriptionEdit;
