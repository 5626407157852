import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Paper from '../layout/Paper';

/*
CLOSED
DELIVERY
CANCELED
PAUSED
OPEN
 */

const styles = () => ({
  headline: {
    marginTop: 0,
  },
  status: {
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  statusOpen: {
    color: '#28bf1c',
  },
  statusDelivery: {
    color: '#1890ff',
  },
  statusCanceled: {
    color: '#bf1c1c',
  },
  statusPaused: {
    color: 'gray',
  },
  statusClosed: {},
  actionAlignRight: {
    textAlign: 'right',
  },
});

const getStateClass = (classes, status) => {
  switch (status) {
    case 'PAUSED':
      return classes.statusPaused;
    case 'DELIVERY':
      return classes.statusDelivery;
    case 'CANCELED':
      return classes.statusCanceled;
    case 'CLOSED':
      return classes.statusClosed;
    default:
      return classes.statusOpen;
  }
};

const getDeliveryState = deliveryState => {
  switch (deliveryState) {
    case 'PAUSED':
      return 'Pausiert';
    case 'DELIVERY':
      return 'In Zustellung';
    case 'CLOSED':
      return 'Abgeschlossen';
    default:
      return 'Aktiv';
  }
};

const DeliveryListItem = ({
  title,
  servingOptionTitle,
  deliveryState,
  deliveryDate,
  editHandler,
  isTrial,
  isGift,
  classes,
}) => (
  <Paper>
    <Grid container alignItems={'center'}>
      <Grid item xs={12} sm={2}>
        {deliveryDate.weekday} <strong>{deliveryDate.date}</strong>
      </Grid>
      <Grid item xs={12} sm={5}>
        <strong>{title}</strong> {' für '} {servingOptionTitle}
        {isTrial ? <div>{'Probebox'}</div> : null}
        {isGift ? <div>{'Geschenkbox'}</div> : null}
      </Grid>
      <Grid item xs={6} sm={2}>
        <div
          className={[
            classes.status,
            getStateClass(classes, deliveryState),
          ].join(' ')}
        >
          {getDeliveryState(deliveryState)}
        </div>
      </Grid>
      <Grid item xs={6} sm={3} className={classes.actionAlignRight}>
        {deliveryState === 'DELIVERY' ||
        deliveryState === 'CLOSED' ||
        isGift ? null : (
          <Button
            size="small"
            color={'primary'}
            disabled={
              deliveryState === 'DELIVERY' ||
              deliveryState === 'CLOSED' ||
              isGift
            }
            onClick={() => editHandler()}
          >
            {'Bearbeiten'}
          </Button>
        )}
      </Grid>
    </Grid>
  </Paper>
);

DeliveryListItem.propTypes = {
  title: PropTypes.string.isRequired,
  servingOptionTitle: PropTypes.string.isRequired,
  deliveryState: PropTypes.string.isRequired,
  deliveryDate: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  editHandler: PropTypes.func,
  isGift: PropTypes.bool,
  isTrial: PropTypes.bool,
};

export default withStyles(styles)(DeliveryListItem);
