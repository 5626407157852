import {
  ADDRESSES_CHECKOUT_CREATE_BILLING_FAILURE,
  ADDRESSES_CHECKOUT_CREATE_BILLING_REQUEST,
  ADDRESSES_CHECKOUT_CREATE_BILLING_SUCCESS,
  ADDRESSES_CHECKOUT_CREATE_DELIVERY_FAILURE,
  ADDRESSES_CHECKOUT_CREATE_DELIVERY_REQUEST,
  ADDRESSES_CHECKOUT_CREATE_DELIVERY_SUCCESS,
  ADDRESSES_CHECKOUT_UPDATE_BILLING_FAILURE,
  ADDRESSES_CHECKOUT_UPDATE_BILLING_REQUEST,
  ADDRESSES_CHECKOUT_UPDATE_BILLING_SUCCESS,
  ADDRESSES_CHECKOUT_UPDATE_DELIVERY_FAILURE,
  ADDRESSES_CHECKOUT_UPDATE_DELIVERY_REQUEST,
  ADDRESSES_CHECKOUT_UPDATE_DELIVERY_SUCCESS,
  ADDRESSES_CREATE_FAILURE,
  ADDRESSES_CREATE_REQUEST,
  ADDRESSES_CREATE_SUCCESS,
  ADDRESSES_UPDATE_FAILURE,
  ADDRESSES_UPDATE_REQUEST,
  ADDRESSES_UPDATE_SUCCESS,
} from '../actions/address';
import {
  USER_EMAIL_CHANGE_FAILURE,
  USER_EMAIL_CHANGE_REQUEST,
  USER_EMAIL_CHANGE_SUCCESS,
  USER_INFO_FAILURE,
  USER_INFO_REQUEST,
  USER_INFO_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_PASSWORD_CHANGE_FAILURE,
  USER_PASSWORD_CHANGE_REQUEST,
  USER_PASSWORD_CHANGE_SUCCESS,
  USER_PASSWORD_RESET_FAILURE,
  USER_PASSWORD_RESET_REQUEST,
  USER_PASSWORD_RESET_SUCCESS,
  USER_REGISTER_FAILURE,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
} from '../actions/user';
import {
  SUBSCRIPTION_FAILURE,
  SUBSCRIPTION_REQUEST,
  SUBSCRIPTION_SUCCESS,
  SUBSCRIPTIONS_DELETE_FAILURE,
  SUBSCRIPTIONS_DELETE_REQUEST,
  SUBSCRIPTIONS_DELETE_SUCCESS,
  SUBSCRIPTIONS_FAILURE,
  SUBSCRIPTIONS_REQUEST,
  SUBSCRIPTIONS_SUCCESS,
  SUBSCRIPTIONS_UPDATE_ADDRESSES_AND_PAYMENT_FAILURE,
  SUBSCRIPTIONS_UPDATE_ADDRESSES_AND_PAYMENT_REQUEST,
  SUBSCRIPTIONS_UPDATE_ADDRESSES_AND_PAYMENT_SUCCESS,
  SUBSCRIPTIONS_UPDATE_REQUEST,
  SUBSCRIPTIONS_UPDATE_SUCCESS,
  SUBSCRIPTIONS_UPDATE_FAILURE,
} from '../actions/subscription';
import {
  CHECKOUT_SOURCE_CREATE_FAILURE,
  CHECKOUT_SOURCE_CREATE_REQUEST,
  CHECKOUT_SOURCE_CREATE_SUCCESS,
  PAYMENTS_FAILURE,
  PAYMENTS_REQUEST,
  PAYMENTS_STRIPE_FAILURE,
  PAYMENTS_STRIPE_REQUEST,
  PAYMENTS_STRIPE_SUCCESS,
  PAYMENTS_SUCCESS,
  SOURCE_PAYPAL_CLIENT_TOKEN_FAILURE,
  SOURCE_PAYPAL_CLIENT_TOKEN_REQUEST,
  SOURCE_PAYPAL_CLIENT_TOKEN_SUCCESS,
} from '../actions/payment';
import {
  DELIVERIES_FAILURE,
  DELIVERIES_REQUEST,
  DELIVERIES_SUCCESS,
  DELIVERIES_UPDATE_FAILURE,
  DELIVERIES_UPDATE_REQUEST,
  DELIVERIES_UPDATE_SUCCESS,
} from '../actions/deliveries';
import {
  VOUCHER_DISCOUNT_FAILURE,
  VOUCHER_DISCOUNT_REQUEST,
  VOUCHER_DISCOUNT_SUCCESS,
} from '../actions/voucher';
import {
  CHECKOUT_CREATE_CARD_FAILURE,
  CHECKOUT_CREATE_CARD_REQUEST,
  CHECKOUT_CREATE_CARD_SUCCESS,
  CHECKOUT_CREATE_GIFTBOX_FAILURE,
  CHECKOUT_CREATE_GIFTBOX_REQUEST,
  CHECKOUT_CREATE_GIFTBOX_SUCCESS,
  CHECKOUT_NEW_SUBMIT_FAILURE,
  CHECKOUT_NEW_SUBMIT_REQUEST,
  CHECKOUT_NEW_SUBMIT_SUCCESS,
} from '../actions/checkout';
import {
  INVOICE_SHOW_FAILURE,
  INVOICE_SHOW_REQUEST,
  INVOICE_SHOW_SUCCESS,
  INVOICES_FAILURE,
  INVOICES_REQUEST,
  INVOICES_SUCCESS,
} from '../actions/invoices';

const initialState = {
  address: false,
  user: false,
  source: false,
  deliveries: false,
  checkout: false,
  invoices: false,
  subscription: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DELIVERIES_REQUEST:
    case DELIVERIES_UPDATE_REQUEST: {
      return {
        ...state,
        deliveries: true,
      };
    }

    case DELIVERIES_FAILURE:
    case DELIVERIES_SUCCESS:
    case DELIVERIES_UPDATE_FAILURE:
    case DELIVERIES_UPDATE_SUCCESS: {
      return {
        ...state,
        deliveries: false,
      };
    }

    case PAYMENTS_REQUEST:
    case CHECKOUT_SOURCE_CREATE_REQUEST:
    case CHECKOUT_CREATE_CARD_REQUEST:
    case SOURCE_PAYPAL_CLIENT_TOKEN_REQUEST:
    case PAYMENTS_STRIPE_REQUEST: {
      return {
        ...state,
        source: true,
      };
    }

    case PAYMENTS_SUCCESS:
    case PAYMENTS_FAILURE:
    case CHECKOUT_SOURCE_CREATE_FAILURE:
    case CHECKOUT_SOURCE_CREATE_SUCCESS:
    case CHECKOUT_CREATE_CARD_SUCCESS:
    case CHECKOUT_CREATE_CARD_FAILURE:
    case SOURCE_PAYPAL_CLIENT_TOKEN_SUCCESS:
    case SOURCE_PAYPAL_CLIENT_TOKEN_FAILURE:
    case PAYMENTS_STRIPE_SUCCESS:
    case PAYMENTS_STRIPE_FAILURE: {
      return {
        ...state,
        source: false,
      };
    }

    case CHECKOUT_NEW_SUBMIT_REQUEST:
    case CHECKOUT_CREATE_GIFTBOX_REQUEST:
      return { ...state, checkout: true };

    case CHECKOUT_NEW_SUBMIT_SUCCESS:
    case CHECKOUT_NEW_SUBMIT_FAILURE:
    case CHECKOUT_CREATE_GIFTBOX_SUCCESS:
    case CHECKOUT_CREATE_GIFTBOX_FAILURE:
      return { ...state, checkout: false };

    case ADDRESSES_CREATE_REQUEST:
    case ADDRESSES_UPDATE_REQUEST:
    case ADDRESSES_CHECKOUT_CREATE_DELIVERY_REQUEST:
    case ADDRESSES_CHECKOUT_CREATE_BILLING_REQUEST:
    case ADDRESSES_CHECKOUT_UPDATE_DELIVERY_REQUEST:
    case ADDRESSES_CHECKOUT_UPDATE_BILLING_REQUEST: {
      return { ...state, address: true };
    }

    case VOUCHER_DISCOUNT_REQUEST: {
      return { ...state, checkout: true };
    }

    case VOUCHER_DISCOUNT_SUCCESS:
    case VOUCHER_DISCOUNT_FAILURE: {
      return { ...state, checkout: false };
    }

    case ADDRESSES_CREATE_SUCCESS:
    case ADDRESSES_CREATE_FAILURE:
    case ADDRESSES_UPDATE_SUCCESS:
    case ADDRESSES_UPDATE_FAILURE:
    case ADDRESSES_CHECKOUT_CREATE_DELIVERY_FAILURE:
    case ADDRESSES_CHECKOUT_CREATE_DELIVERY_SUCCESS:
    case ADDRESSES_CHECKOUT_CREATE_BILLING_FAILURE:
    case ADDRESSES_CHECKOUT_CREATE_BILLING_SUCCESS:
    case ADDRESSES_CHECKOUT_UPDATE_DELIVERY_FAILURE:
    case ADDRESSES_CHECKOUT_UPDATE_DELIVERY_SUCCESS:
    case ADDRESSES_CHECKOUT_UPDATE_BILLING_FAILURE:
    case ADDRESSES_CHECKOUT_UPDATE_BILLING_SUCCESS: {
      return { ...state, address: false };
    }

    case USER_LOGIN_REQUEST:
    case USER_REGISTER_REQUEST:
    case USER_EMAIL_CHANGE_REQUEST:
    case USER_PASSWORD_CHANGE_REQUEST:
    case USER_INFO_REQUEST:
    case USER_PASSWORD_RESET_REQUEST:
      return { ...state, user: true };

    case USER_LOGIN_SUCCESS:
    case USER_LOGIN_FAILURE:
    case USER_REGISTER_SUCCESS:
    case USER_REGISTER_FAILURE:
    case USER_EMAIL_CHANGE_SUCCESS:
    case USER_EMAIL_CHANGE_FAILURE:
    case USER_PASSWORD_CHANGE_SUCCESS:
    case USER_PASSWORD_CHANGE_FAILURE:
    case USER_INFO_SUCCESS:
    case USER_INFO_FAILURE:
    case USER_PASSWORD_RESET_SUCCESS:
    case USER_PASSWORD_RESET_FAILURE: {
      return { ...state, user: false };
    }

    case INVOICE_SHOW_REQUEST:
    case INVOICES_REQUEST: {
      return { ...state, invoices: true };
    }

    case INVOICE_SHOW_SUCCESS:
    case INVOICE_SHOW_FAILURE:
    case INVOICES_SUCCESS:
    case INVOICES_FAILURE: {
      return { ...state, invoices: false };
    }

    case SUBSCRIPTIONS_DELETE_REQUEST:
    case SUBSCRIPTION_REQUEST:
    case SUBSCRIPTIONS_REQUEST:
    case SUBSCRIPTIONS_UPDATE_REQUEST:
    case SUBSCRIPTIONS_UPDATE_ADDRESSES_AND_PAYMENT_REQUEST: {
      return {
        ...state,
        subscription: true,
      };
    }

    case SUBSCRIPTIONS_UPDATE_ADDRESSES_AND_PAYMENT_SUCCESS:
    case SUBSCRIPTIONS_UPDATE_ADDRESSES_AND_PAYMENT_FAILURE:
    case SUBSCRIPTIONS_DELETE_SUCCESS:
    case SUBSCRIPTIONS_DELETE_FAILURE:
    case SUBSCRIPTION_SUCCESS:
    case SUBSCRIPTION_FAILURE:
    case SUBSCRIPTIONS_FAILURE:
    case SUBSCRIPTIONS_SUCCESS:
    case SUBSCRIPTIONS_UPDATE_SUCCESS:
    case SUBSCRIPTIONS_UPDATE_FAILURE: {
      return {
        ...state,
        subscription: false,
      };
    }

    default:
      return state;
  }
};
