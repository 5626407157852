import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  FORM_ADDRESS_LABEL_FIRST_NAME,
  FORM_ADDRESS_LABEL_LINE2,
  FORM_ADDRESS_LABEL_LAST_NAME,
  FORM_ADDRESS_LABEL_CITY,
  FORM_ADDRESS_LABEL_STREET,
  FORM_ADDRESS_LABEL_ZIP,
  FORM_ADDRESS_PLACEHOLDER_PHONE_NUMBER,
} from '../../constants/constants';
import Grid from '@material-ui/core/Grid';
import { isValidAddress } from '../../redux/addresses';
import Button from '../inputs/Button';
import InputField from '../inputs/InputField';
import './AddressForm.css';

export const initialAddressState = {
  first_name: '',
  last_name: '',
  line2: '',
  street: '',
  zip: '',
  city: '',
  phone_number: '',
  country: 'Schweiz',
  id: null,
};

class AddressForm extends Component {
  state = {
    ...initialAddressState,
    isLoading: false,
  };

  componentWillReceiveProps(nextProps) {
    if (!nextProps.address) {
      this.setState(this.initialState);
      return;
    }

    const { id, ...address } = nextProps.address;

    const addressHasUpdated =
      JSON.stringify(address) !== JSON.stringify(this.state);

    if (addressHasUpdated) {
      this.setState({ ...address });
    }
  }

  componentWillMount() {
    const { address, id, error } = this.props;

    if (!address) {
      return;
    }

    this.setState({
      first_name: address.first_name || '',
      last_name: address.last_name || '',
      line2: address.line2 || '',
      street: address.street || '',
      zip: address.zip || '',
      city: address.city || '',
      phone_number: address.phone_number || '',
      id: id || null,
      error: error || false,
    });
  }

  handleCreateAddress() {
    const { id, ...address } = this.state;

    id === null
      ? this.props.handleCreate(address, this.props.addressType)
      : this.props.handleUpdate(id, address, this.props.onClose);
  }

  handleInputStateChange = name => event => {
    if (name === 'zip' && event.target.value !== '') {
      const zip = /^(\d{1,4})\b/.exec(event.target.value.trim());
      if (!zip) {
        return;
      }

      event.target.value = zip[0];
    }

    if (name === 'phone_number') {
      const phoneNumber = /^\+?1?\d{0,15}/.exec(event.target.value.trim());
      if (!phoneNumber) {
        return;
      }
      event.target.value = phoneNumber;
    }

    this.setState({ [name]: event.target.value });

    const address = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      line2: this.state.line2,
      street: this.state.street,
      zip: this.state.zip,
      city: this.state.city,
      phone_number: this.state.phone_number,
      country: this.state.country,
      [name]: event.target.value,
    };

    this.props.handleFormInput({
      addressType: [`${this.props.addressType}`],
      address: address,
    });
  };

  handleInputFieldBlur = () => {};

  render() {
    const {
      first_name,
      last_name,
      line2,
      street,
      zip,
      city,
      phone_number,
    } = this.state;

    const { isLoading, hasSubmit } = this.props;

    const submitAllowed = isValidAddress(this.state);
    const title = this.state.id
      ? 'Addresse aktualisieren'
      : 'Adresse hinzufügen';

    return (
      <form
        autoComplete="off"
        onSubmit={this.handleFormSubmit}
        className="addressForm"
      >
        <Grid container spacing={16}>
          <Grid item xs={12} sm={6}>
            <InputField
              fullWidth
              id="firstName"
              placeholder={FORM_ADDRESS_LABEL_FIRST_NAME}
              value={first_name}
              onChange={this.handleInputStateChange('first_name')}
              onBlur={this.handleInputFieldBlur('first_name')}
              disabled={isLoading}
              required
              autoFocus
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <InputField
              fullWidth
              id="lastName"
              placeholder={FORM_ADDRESS_LABEL_LAST_NAME}
              value={last_name}
              onChange={this.handleInputStateChange('last_name')}
              onBlur={this.handleInputFieldBlur('last_name')}
              disabled={isLoading}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <InputField
              fullWidth
              id="line2"
              placeholder={FORM_ADDRESS_LABEL_LINE2}
              value={line2}
              onChange={this.handleInputStateChange('line2')}
              onBlur={this.handleInputFieldBlur('line2')}
              disabled={isLoading}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <InputField
              fullWidth
              id="street"
              placeholder={FORM_ADDRESS_LABEL_STREET}
              value={street}
              onChange={this.handleInputStateChange('street')}
              onBlur={this.handleInputFieldBlur('street')}
              disabled={isLoading}
              required
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <InputField
              fullWidth
              id="zip"
              placeholder={FORM_ADDRESS_LABEL_ZIP}
              value={zip}
              onChange={this.handleInputStateChange('zip')}
              onBlur={this.handleInputFieldBlur('zip')}
              disabled={isLoading}
              pattern={'[0-9]{4}'}
              required
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <InputField
              fullWidth
              id="city"
              placeholder={FORM_ADDRESS_LABEL_CITY}
              value={city}
              onChange={this.handleInputStateChange('city')}
              onBlur={this.handleInputFieldBlur('city')}
              disabled={isLoading}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <InputField
              fullWidth
              id="phone_number"
              placeholder={FORM_ADDRESS_PLACEHOLDER_PHONE_NUMBER}
              value={phone_number}
              onChange={this.handleInputStateChange('phone_number')}
              onBlur={this.handleInputFieldBlur('phone_number')}
              disabled={isLoading}
              required={this.props.addressType === 'delivery'}
              helperText={
                this.props.addressType === 'delivery' ||
                this.props.showPhoneHint
                  ? 'Für den Fall, dass es Probleme mit Deiner Lieferung gibt'
                  : null
              }
            />
          </Grid>
        </Grid>
        {hasSubmit ? (
          <Button
            color={'primary'}
            variant="raised"
            onClick={() => this.handleCreateAddress()}
            disabled={isLoading || submitAllowed === false}
          >
            {title}
          </Button>
        ) : null}
      </form>
    );
  }
}

AddressForm.propTypes = {
  addressType: PropTypes.string.isRequired,
  address: PropTypes.object,
  handleFormFieldBlur: PropTypes.func,
  handleFormInput: PropTypes.func,
  isLoading: PropTypes.bool,
  showPhoneHint: PropTypes.bool,
};

export default AddressForm;

/*
 <Grid item xs={12} sm={6}>
  <FormControl fullWidth>
    <TextField
      id="country"
      label={FORM_ADDRESS_LABEL_COUNTRY}
      value={'Schweiz'}
      onChange={this.handleInputStateChange('country')}
      onBlur={this.handleInputFieldBlur('country')}
      disabled={isLoading}
      required
    />
  </FormControl>
</Grid>
 */
