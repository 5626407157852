// @flow
import { PLANS_SUCCESS } from '../actions/plans';
import type { Plan } from '../types';

export type ServingOption = {
  id: number,
  title: string,
  currency: string,
  initial_visible: boolean,
  plan: number,
  price: string,
  quantity: number,
  tax: string,
};

const addPlans = (plans: Plan[]): Plan[] =>
  plans.map(p => ({
    ...p,
    serving_options: p.serving_options.reverse(),
  }));

export default (state: Plan[] = [], action: any) => {
  switch (action.type) {
    case PLANS_SUCCESS:
      return action.payload ? addPlans(action.payload) : state;
    default:
      return state;
  }
};
