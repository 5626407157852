import React from 'react';
import { Switch, Route } from 'react-router-dom';
import BoxList from '../../containers/plans/List';
import DeliveriesList from '../../containers/subscriptions/DeliveryList';
import SubscriptionList from '../../containers/subscriptions/SubscriptionList';
import Grid from '@material-ui/core/Grid';
import SiteNavigation from '../navigation/SiteNavigation';
import { withStyles } from '@material-ui/core/styles';
import ViewDashboard from './ViewDashboard';
import ViewInvoices from './ViewInvoices';
import ViewCalendar from './ViewCalendar';
import ViewProfile from './ViewProfile';
import PasswordChange from '../../containers/auth/PasswordChange';
import EmailChange from '../../containers/auth/EmailChange';
import {
  ROUTE_PROFILE_PASSWORD_CHANGE,
  ROUTE_PROFILE,
  ROUTE_PROFILE_CALENDAR,
  ROUTE_PROFILE_INVOICES,
  ROUTE_PROFILE_EMAIL_CHANGE,
  ROUTE_DELIVERIES,
  ROUTE_SUBSCRIPTION_EDIT,
} from '../../constants/routes';
import ViewSubscriptionEdit from '../../containers/subscriptions/ViewSubscriptionEdit';

const styles = theme => ({
  content: {
    [theme.breakpoints.up('sm')]: {
      border: '1px solid #e9e9e9',
      padding: theme.spacing.unit * 4,
    },
  },
});

const ViewsWithSideNavigation = ({ classes }) => (
  <Grid container spacing={0}>
    <Grid item xs={12} sm={3}>
      <SiteNavigation />
    </Grid>
    <Grid item xs={12} sm={9}>
      <div className={classes.content}>
        <Switch>
          <Route exact path="/boxes" component={BoxList} />
          <Route exact path={ROUTE_PROFILE} component={ViewProfile} />
          <Route
            exact
            path={ROUTE_PROFILE_PASSWORD_CHANGE}
            component={PasswordChange}
          />
          <Route
            exact
            path={ROUTE_PROFILE_EMAIL_CHANGE}
            component={EmailChange}
          />
          <Route
            exact
            path={ROUTE_SUBSCRIPTION_EDIT}
            component={ViewSubscriptionEdit}
          />
          <Route exact path={ROUTE_PROFILE_CALENDAR} component={ViewCalendar} />
          <Route exact path={ROUTE_PROFILE_INVOICES} component={ViewInvoices} />
          <Route exact path="/meine-foodboxen" component={SubscriptionList} />
          <Route exact path={ROUTE_DELIVERIES} component={DeliveriesList} />
          <Route exact path="/" component={ViewDashboard} />
        </Switch>
      </div>
    </Grid>
  </Grid>
);

export default withStyles(styles)(ViewsWithSideNavigation);
