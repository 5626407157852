import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadInvoices, showInvoice } from '../../actions/invoices';
import Paper from '../../components/layout/Paper';
import Grid from '@material-ui/core/Grid';
import type { Invoice } from '../../redux/invoices';
import { dateFormatLong } from '../../utils/dateHelper';
import downloadIcon from '../../static/svg/download.svg';

class InvoiceList extends Component {
  getInvoiceById(invoiceNumber) {
    this.props.showInvoice(invoiceNumber);
  }

  componentDidMount() {
    this.props.loadInvoices();
  }

  render() {
    const { invoices, isLoading } = this.props;

    if (invoices.length === 0) {
      return <div>Du hast noch keine Rechnungen.</div>;
    }

    return (
      <Grid container spacing={16}>
        {invoices.map((i: Invoice) => (
          <Grid xs={12} item key={i.invoice_number}>
            <Paper>
              <Grid container alignItems={'center'}>
                <Grid item xs={12} sm={4}>
                  <strong>{dateFormatLong(i.invoice_date)}</strong>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <strong>{i.invoice_number}</strong>
                </Grid>
                <Grid item xs={4} sm={3}>
                  <div style={{ textAlign: 'right' }}>
                    <strong>
                      {i.gross_sum} {i.currency}
                    </strong>
                  </div>
                </Grid>
                <Grid item xs={2} sm={2}>
                  <div style={{ textAlign: 'right' }}>
                    <img
                      className="link"
                      src={downloadIcon}
                      alt="Rechnung abrufen"
                      title="Rechnung abrufen"
                      onClick={() =>
                        isLoading ? null : this.getInvoiceById(i.invoice_number)
                      }
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  invoices: state.invoices,
  isLoading: state.loading.invoices,
});

export default connect(
  mapStateToProps,
  { loadInvoices, showInvoice }
)(InvoiceList, showInvoice);
