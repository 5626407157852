// @flow

import * as React from 'react';
import './Button.css';
import classNames from '../../utils/classNames';

type Props = {
  disabled?: boolean,
  onClick?: Function,
  variant?: string,
  fullWidth?: boolean,
  fullWidthMobile?: boolean,
  loading?: boolean,
  children?: React.Node,
  size?: string,
  primary?: boolean,
};

export default ({
  variant,
  fullWidth,
  disabled,
  onClick,
  loading,
  children,
  size,
  fullWidthMobile = false,
  primary = false,
}: Props) => {
  return (
    <button
      disabled={disabled || loading}
      onClick={onClick ? () => onClick() : undefined}
      className={classNames([
        'button',
        variant === 'flat' ? 'button__flat' : 'button__raised',
        variant === 'promo' ? 'button__raised button__promo' : undefined,
        primary ? 'button--primary' : undefined,
        fullWidth ? 'button--fullWidth' : undefined,
        fullWidthMobile ? 'button--fullWidthMobile' : undefined,
        loading ? 'button--isLoading' : undefined,
        size === 'small' ? 'button--small' : undefined,
      ])}
    >
      {children}
    </button>
  );
};
