import {
  APP_SET_TOKEN,
  USER_LOGOUT,
  USER_SET_CONTACT_SOURCE,
  USER_SET_PROFILE,
} from '../constants/actionTypes';
import { USER_INFO_SUCCESS, USER_REGISTER_SUCCESS } from '../actions/user';

const initialState = {
  id: null,
  email: null,
  isAuthorized: false,
  isNewUser: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case APP_SET_TOKEN: {
      return {
        ...state,
        id: action.payload.decodedToken.user_id,
        email: action.payload.decodedToken.email,
        isAuthorized: true,
      };
    }

    case USER_REGISTER_SUCCESS: {
      return {
        ...state,
        isNewUser: true,
      };
    }

    case USER_INFO_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    case USER_SET_CONTACT_SOURCE:
      return {
        ...state,
        ...action.payload,
      };

    case USER_SET_PROFILE: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case USER_LOGOUT: {
      return initialState;
    }

    default:
      return state;
  }
};
