// @flow

import * as React from 'react';
import { HeadlineTitle } from '../../components/typography/Headlines';
import { connect } from 'react-redux';
import {
  FORM_LOGIN_LABEL_EMAIL,
  FORM_LOGIN_LABEL_PASSWORD,
} from '../../constants/constants';
import { changeUserEmail } from '../../actions/user';
import InputField from '../../components/inputs/InputField';
import Button from '../../components/inputs/Button';

type Props = {
  email: string,
  changeUserEmail: Function,
  loading: boolean,
};

type State = {
  email: string,
  emailError: boolean,
  emailErrorMessage: string,
  password: string,
  passwordError: boolean,
  passwordErrorMessage: string,
  disabled: boolean,
};

class EmailChange extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailError: false,
      emailErrorMessage: '',
      password: '',
      passwordError: false,
      passwordErrorMessage: '',
      disabled: true,
    };
  }

  async handleSubmit(event) {
    event.preventDefault();
    const { email, password } = this.state;

    try {
      await this.props.changeUserEmail({ password, email });
      this.setState({
        emailError: false,
        emailErrorMessage: '',
        passwordError: false,
        passwordErrorMessage: '',
      });
    } catch (err) {
      if (err.hasOwnProperty('new_email')) {
        this.setState({
          emailError: true,
          emailErrorMessage: err.new_email.join(' '),
        });
      }

      if (err.hasOwnProperty('current_password')) {
        this.setState({
          passwordError: true,
          passwordErrorMessage: 'Das eingegebene Passwort ist nicht korrekt.',
        });
      }
    }
  }

  handleChangeEmail = event => {
    const currentEmail = this.props.email;
    const email = event.target.value.trim();
    return this.setState({ email, emailError: currentEmail === email });
  };

  handleChangePassword = event =>
    this.setState({ password: event.target.value });

  getEmailErrorMessage = () => {
    const currentEmail = this.props.email;
    const { email, emailErrorMessage } = this.state;

    if (email === currentEmail) {
      return 'Du hast deine alte Emailadresse eingegeben';
    }

    if (emailErrorMessage) {
      return emailErrorMessage;
    }

    return '';
  };

  passwordHelperText = () => {
    return 'Gib dein aktuelles Kennwort zur Bestätigung ein';
  };

  render() {
    const {
      email,
      password,
      emailError,
      passwordError,
      passwordErrorMessage,
    } = this.state;

    const { loading } = this.props;

    const disabled =
      email === '' || password === '' || email === this.props.email;

    const passwordHelperText = this.passwordHelperText();

    return (
      <div>
        <HeadlineTitle title={'Email ändern'} />
        <p>Deine aktuelle Email Adresse: {this.props.email}</p>
        <form onSubmit={event => this.handleSubmit(event)}>
          <div className="form__group">
            <InputField
              id={'email'}
              fullWidth
              type={'email'}
              value={email}
              onChange={this.handleChangeEmail}
              placeholder={FORM_LOGIN_LABEL_EMAIL}
              hasError={emailError}
              error={this.getEmailErrorMessage()}
              helperText={'Deine neue Emailadresse'}
              disabled={loading}
            />
          </div>
          <div className="form__group">
            <InputField
              id={'password'}
              fullWidth
              type={'password'}
              placeholder={FORM_LOGIN_LABEL_PASSWORD}
              value={password}
              onChange={this.handleChangePassword}
              helperText={passwordHelperText}
              hasError={passwordError}
              error={passwordErrorMessage}
              disabled={loading}
            />
          </div>
          <Button
            variant="raised"
            type={'submit'}
            color={'primary'}
            disabled={loading || disabled}
          >
            Emailadresse ändern
          </Button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  email: state.user.email,
  loading: state.loading.user,
});
export default connect(
  mapStateToProps,
  { changeUserEmail }
)(EmailChange);
