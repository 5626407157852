import React from 'react';
import { connect } from 'react-redux';
import { checkoutSetFields, checkoutStart } from '../../actions/checkout';
import CheckoutContainer from './CheckoutContainer';
import type { Plan } from '../../redux/plans';
import { CHECKOUT_TYPE_GIFTBOX_BUY } from '../../redux/checkout';
import {
  getGiftBoxFromUrlParams,
  redirectToLandingPageWithError,
} from '../../utils/checkout';
import { getContactSourceFromQueryParams } from '../../utils/contactSource';
import { setContactSource } from '../../actions/user';

const mapStateToProps = state => ({
  plans: state.plan,
  isAuthorized: state.user.isAuthorized,
});

const mapDispatchToProps = {
  checkoutSetFields,
  checkoutStart,
  setContactSource,
};

const ViewGiftBoxCheckout = WrappedComponent =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          hasError: false,
        };
      }

      componentWillMount() {
        const {
          checkoutStart,
          isAuthorized,
          checkoutSetFields,
          setContactSource,
          plans,
        } = this.props;

        setContactSource(getContactSourceFromQueryParams());

        const urlParams = new URLSearchParams(window.location.search);
        const { planId, quantity, message, hasError } = getGiftBoxFromUrlParams(
          urlParams
        );

        if (hasError) {
          this.setState({ hasError: true });
          return;
        }

        const selectedPlan: Plan = plans.find(p => p.id === planId) || plans[0];
        const selectedServingOption =
          selectedPlan.serving_options.find(s => s.quantity === quantity) ||
          selectedPlan.serving_options[0];

        const checkout = {
          productType: CHECKOUT_TYPE_GIFTBOX_BUY,
          servingOptionId: selectedServingOption.id,
          planId: planId,
          isSubscription: false,
          hasOnlyBillingAddress: true,
          hasAlternateBilling: true,
          message,
        };

        checkoutStart(isAuthorized);
        checkoutSetFields(checkout);
      }

      render() {
        if (this.state.hasError) {
          return redirectToLandingPageWithError(CHECKOUT_TYPE_GIFTBOX_BUY);
        }

        return <WrappedComponent {...this.props} />;
      }
    }
  );

export default ViewGiftBoxCheckout(CheckoutContainer);
