import React, { Fragment } from 'react';
import SubscriptionList from '../../containers/subscriptions/SubscriptionList';
import DeliveriesList from '../../containers/subscriptions/DeliveryList';
import Divider from '@material-ui/core/Divider';

export default () => (
  <Fragment>
    <SubscriptionList />
    <Divider style={{ margin: '2rem 0' }} />
    <DeliveriesList
      showElements={5}
      showAllElementsLink={true}
      showOnlyFirstClosedDelivery={true}
    />
  </Fragment>
);
