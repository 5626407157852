import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { changeLocation } from '../../actions/router';

export const CheckoutSubscriptionSuccess = props => (
  <div>
    <Typography variant="title" gutterBottom>
      Vielen Dank für deine Bestellung!
    </Typography>

    <Typography gutterBottom>
      Du erhältst in Kürze eine Bestellbestätigung per E-Mail von uns.
    </Typography>

    <Typography variant="title" gutterBottom>
      Abo-Übersicht und Status deiner Lieferungen
    </Typography>

    <Typography gutterBottom>
      Im Benutzerkonto kannst du dein Abonnement und deine Lieferungen jederzeit
      überprüfen und anpassen.
    </Typography>

    <Typography gutterBottom>
      Du gehst in den Urlaub? Kein Problem! Du kannst die entsprechende
      Lieferung einfach pausieren.
    </Typography>

    <Typography variant="body1" gutterBottom>
      Du hast die Saisonale Foodbox abonniert, möchtest aber mal die Vegi
      Foodbox probieren? Auch dies kannst Du in deinem Benutzerkonto ändern.
    </Typography>

    <Typography variant="body1" gutterBottom>
      Bitte beachte dabei, dass Änderungen in der Vorwoche bis spätestens
      Freitag 12 Uhr vorgenommen werden müssen. Bei Fragen hierzu hilft dir
      unser Kundendienst gerne weiter.
    </Typography>

    <Button
      variant="raised"
      onClick={() => props.changeLocation('/')}
      color={'primary'}
    >
      Zum Benutzerkonto
    </Button>
  </div>
);

export const CheckoutTrialSuccess = props => (
  <div>
    <Typography variant="title" gutterBottom>
      Vielen Dank für deine Bestellung!
    </Typography>

    <Typography gutterBottom>
      Du erhältst in Kürze eine Bestellbestätigung per E-Mail von uns.
    </Typography>

    <Typography gutterBottom>
      <strong>Lieferstatus deiner Bestellung</strong>
    </Typography>

    <Typography gutterBottom>
      Im Benutzerkonto kannst du den Status deiner Lieferung jederzeit
      überprüfen. Bei Fragen hierzu hilft unser Kundendienst gerne weiter.
    </Typography>

    <Button
      variant="raised"
      onClick={() => props.changeLocation('/')}
      color={'primary'}
    >
      Zum Benutzerkonto
    </Button>
  </div>
);

export const CheckoutVoucherSuccess = props => (
  <div>
    <Typography variant="title" gutterBottom>
      Vielen Dank für deine Bestellung!
    </Typography>

    <Typography gutterBottom>
      Du erhältst in Kürze eine Bestellbestätigung per E-Mail von uns. Zum von
      Dir festgelegten Datum, erhälst du deine Foodbox.
    </Typography>

    <Typography gutterBottom>
      Bei weiteren Fragen hilft unser Kundendienst gerne weiter.
    </Typography>

    <Button
      variant="raised"
      onClick={() => props.changeLocation('/')}
      color={'primary'}
    >
      Zum Benutzerkonto
    </Button>
  </div>
);

const CheckoutGiftBoxSuccessPage = props => (
  <div>
    <Typography variant="title" gutterBottom>
      Vielen Dank für deine Bestellung!
    </Typography>

    <Typography gutterBottom>
      Du erhältst in Kürze eine Bestellbestätigung per E-Mail von uns. Zum von
      Dir festgelegten Datum, erhält der Beschenkte den Gutschein mit deiner
      persönlichen Grussbotschaft per E-Mail zugeschickt.
    </Typography>

    <Typography gutterBottom>
      <strong>Rechnung für deine Bestellung</strong>
    </Typography>

    <Typography gutterBottom>
      Im deinem Benutzerkonto kannst du im Bereich „Rechnungen“ ein PDF deiner
      Rechnung herunterladen. Bei Fragen hierzu hilft unser Kundendienst gerne
      weiter.
    </Typography>

    <Button
      variant="raised"
      onClick={() => props.changeLocation('/')}
      color={'primary'}
    >
      Zum Benutzerkonto
    </Button>
  </div>
);

export const CheckoutGiftBoxSuccess = connect(
  null,
  { changeLocation }
)(CheckoutGiftBoxSuccessPage);
