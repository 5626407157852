// @flow

import * as React from 'react';
import './PaymentTypeSelectorItem.css';
import classNames from '../../../utils/classNames';

type Props = {
  selected?: boolean,
  children: React.Node,
  onClick: () => void,
  isLoading?: boolean,
};

const PaymentTypeSelectorItem = ({
  children,
  onClick,
  selected = false,
  isLoading = false,
}: Props) => (
  <div
    onClick={() => onClick()}
    className={classNames([
      'paymentProviderSelectorItem',
      selected ? 'paymentProviderSelectorItem--selected' : undefined,
      isLoading ? 'paymentProviderSelectorItem--isLoading' : undefined,
    ])}
  >
    {children}
  </div>
);

export default PaymentTypeSelectorItem;
