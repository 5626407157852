import React from 'react';
import { connect } from 'react-redux';
import { userLogout } from '../../actions/user';
import { Link } from 'react-router-dom';

const Logout = ({ userLogout }) => {
  userLogout();
  return (
    <div>
      Thank you for using our service. Click <Link to={'/login'}>here</Link> to
      login.
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({});

export default connect(
  mapStateToProps,
  {
    userLogout,
  }
)(Logout);
