// @flow

import {
  DELIVERIES_SUCCESS,
  DELIVERIES_UPDATE_SUCCESS,
} from '../actions/deliveries';
import { SUBSCRIPTIONS_DELETE_SUCCESS } from '../actions/subscription';

/* const status = {
  active: 'ACTIVE',
  closed: 'CLOSED',
  delivery: 'DELIVERY',
  canceled: 'CANCELED',
  paused: 'PAUSED',
  open: 'OPEN',
}; */

/*
CLOSED
DELIVERY
CANCELED
PAUSED
OPEN
 */

// {id: 13, plan: 1, subscription: 4, delivery_date: "2018-01-02", status: "OPEN"}

export type Delivery = {
  id: number,
  plan: number,
  plan_title: string,
  subscription: number,
  delivery_date: string,
  serving_option: number,
  serving_option_title: string,
  status: string,
  quantity: number,
  delivery_options: string[],
  is_trial: boolean,
  is_giftbox: boolean,
};

const initialState: Delivery[] = [];

export default (state: Delivery[] = initialState, action: any) => {
  switch (action.type) {
    case DELIVERIES_SUCCESS: {
      return action.payload;
    }
    case SUBSCRIPTIONS_DELETE_SUCCESS: {
      return state.filter(d => d.subscription !== action.payload.id);
    }
    case DELIVERIES_UPDATE_SUCCESS: {
      return state.map(
        d => (d.id === action.payload.id ? { ...action.payload } : d)
      );
    }
    default:
      return state;
  }
};
