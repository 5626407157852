import React from 'react';
import './CheckoutPriceBox.css';
import { currencyFormat } from '../../utils/format';
import {
  CHECKOUT_TYPE_SUBSCRIPTION,
  CHECKOUT_TYPE_SUBSCRIPTION_TRIAL,
} from '../../redux/checkout';

const CheckoutPriceBox = ({ title, price, productType }) => {
  const total = parseFloat(price.total);
  const hasDiscount = price.voucherValid;
  const discountPrice = ((total * price.voucherAmount) / 100) * -1;
  const deliveries = price.voucherDeliveries;

  const isSubscriptionDiscount =
    productType === CHECKOUT_TYPE_SUBSCRIPTION && deliveries > 0 && hasDiscount;

  const PriceElement = isSubscriptionDiscount ? (
    <div>
      <div className="priceBox__item priceBox__price--gutterBottom">
        <span className="priceBox__title priceBox__title--strong">{`1. ${
          deliveries > 1 ? `bis ${deliveries}.` : ''
        } Foodbox`}</span>
      </div>

      <div className="priceBox__item">
        <div className="priceBox__title">{'Preis pro Foodbox'}</div>
        <div className="priceBox__price">{currencyFormat(total)}</div>
      </div>

      <div className="priceBox__item">
        <span className="priceBox__title">
          {`Rabatt (${price.voucherCode})`}
        </span>
        <span className="priceBox__price">{currencyFormat(discountPrice)}</span>
      </div>

      <div className="priceBox__item priceBox__price--gutterBottom">
        <span className="priceBox__title">{'Spezialpreis pro Foodbox'}</span>
        <span className={'priceBox__price'}>
          {currencyFormat(total + discountPrice)}
        </span>
      </div>

      <div className="priceBox__item priceBox__price--gutterBottom">
        <span className="priceBox__title priceBox__title--strong">{`Ab der ${deliveries +
          1}. Foodbox`}</span>
      </div>

      <div className="priceBox__item priceBox__price--gutterBottom">
        <div className="priceBox__title">{'Preis pro Foodbox'}</div>
        <div className="priceBox__price">{currencyFormat(total)}</div>
      </div>
    </div>
  ) : (
    <div>
      {productType === CHECKOUT_TYPE_SUBSCRIPTION_TRIAL && hasDiscount ? (
        <div className="priceBox__item priceBox__price--gutterBottom">
          <div className="priceBox__title priceBox__title--strong">
            Preis pro Foodbox
          </div>
          <div className="priceBox__price">{currencyFormat(total)}</div>
        </div>
      ) : null}

      {hasDiscount ? (
        <div className="priceBox__item priceBox__price--gutterBottom">
          <div className="priceBox__title">
            {`Rabatt (${price.voucherCode})`}
          </div>
          <div className="priceBox__price">{currencyFormat(discountPrice)}</div>
        </div>
      ) : null}

      <div className="priceBox__item priceBox__price--gutterBottom">
        <div className="priceBox__title priceBox__title--strong">
          {productType === CHECKOUT_TYPE_SUBSCRIPTION_TRIAL && hasDiscount
            ? 'Spezialpreis'
            : 'Preis pro Foodbox'}
        </div>
        <div className="priceBox__price">
          {currencyFormat(hasDiscount ? total + discountPrice : total)}
        </div>
      </div>
    </div>
  );

  return <div className="priceBox">{PriceElement}</div>;
};

export default CheckoutPriceBox;

/*
<div className="priceBox__item priceBox__price--gutterBottom">
  <div className="priceBox__title">{'Einzelpreis'}</div>
  <div className="priceBox__price">{currencyFormat(total)}</div>
</div>
*/
