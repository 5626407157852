// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { loadAddresses } from '../../actions/address';
import {
  loadSubscriptions,
  updateSubscriptionAddressesAndPayment,
} from '../../actions/subscription';
import { loadPaymentsSources } from '../../actions/payment';
import UpdatePaymentAndAddressForm from '../../components/subscription/UpdatePaymentAndAddressFormWithStripeProvider';
import type { Address, Loading, PaymentSource } from '../../types';
import { NoActiveSubscriptions } from '../../constants/constants';
import type { ServingOption } from '../../types/plan';
import type { Subscription } from '../../types/subscription';

interface Props {
  servingOptions: ServingOption[];
  subscriptions: Subscription[];
  payments: PaymentSource[];
  addresses: Address[];
  loading: Loading;
  loadAddresses: () => void;
  loadSubscriptions: boolean => void;
  loadPaymentsSources: () => void;
  updateSubscriptionAddressesAndPayment: Function;
}

interface State {
  selectedSubscriptionId?: string;
}

class ViewSubscriptionEditPaymentAndAddressPage extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);
    this.state = {
      selectedSubscriptionId: '',
    };
  }

  handleSubscriptionSelect = event => {
    const { value } = event.target;

    this.setState({
      selectedSubscriptionId: value,
    });
  };

  componentDidMount() {
    this.props.loadSubscriptions(true);
    this.props.loadAddresses();
    this.props.loadPaymentsSources();
  }

  componentWillReceiveProps(nextProps: Props) {
    const { selectedSubscriptionId } = this.state;
    const { subscriptions } = nextProps;

    if (selectedSubscriptionId === '' && subscriptions.length > 0) {
      this.setState({ selectedSubscriptionId: subscriptions[0].id.toString() });
    }
  }

  render() {
    const {
      subscriptions,
      loading,
      payments,
      updateSubscriptionAddressesAndPayment,
      servingOptions,
    } = this.props;
    const { selectedSubscriptionId } = this.state;

    const s = subscriptions.find(
      s => s.id.toString() === selectedSubscriptionId
    );

    const selectedSubscription = this.props.subscriptions.length > 0 &&
      s && (
        <UpdatePaymentAndAddressForm
          paymentSources={payments}
          subscription={s}
          addresses={this.props.addresses}
          isLoading={loading.source || loading.subscription}
          updateSubscription={updateSubscriptionAddressesAndPayment}
          servingOptions={servingOptions}
        />
      );

    return (
      <div>
        <h2>Abo Einstellungen</h2>
        {this.props.subscriptions.length > 1 ? (
          <select
            className="form-control form-control--border form-control--fullWidth"
            name=""
            id=""
            onChange={this.handleSubscriptionSelect}
            value={selectedSubscriptionId}
            disabled={loading.source || loading.subscription}
          >
            {this.props.subscriptions
              .filter(s => s.is_editable === true)
              .map(s => (
                <option value={s.id} key={`subscription_update_${s.id}`}>
                  {s.plan_title} für {s.serving_option_title} (id: {s.id})
                </option>
              ))}
          </select>
        ) : s ? (
          <div>
            {s.plan_title} für {s.serving_option_title}
          </div>
        ) : (
          <NoActiveSubscriptions />
        )}
        <div style={{ marginBottom: '2rem' }} />
        {selectedSubscription}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  subscriptions: state.subscriptions.filter(s => s.is_editable === true),
  payments: state.payments,
  addresses: state.addresses,
  loading: state.loading,
  servingOptions: state.plan.reduce(
    (servingOptions, next) => [...servingOptions, ...next.serving_options],
    []
  ),
});

export default connect(
  mapStateToProps,
  {
    loadAddresses,
    loadSubscriptions,
    loadPaymentsSources,
    updateSubscriptionAddressesAndPayment,
  }
)(ViewSubscriptionEditPaymentAndAddressPage);
