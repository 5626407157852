// @flow

import * as React from 'react';
import CheckoutNavigation from '../../components/checkout/CheckoutNavigation';
import { connect } from 'react-redux';
import { getPaypalClientToken } from '../../actions/payment';

type Props = {
  getPaypalClientToken: () => { client_token?: string },
  navigation: any,
};

class BrainTreePaymentProvider extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.getPaypalClientToken();
  }

  render() {
    return <CheckoutNavigation {...this.props.navigation} noGutter={true} />;
  }
}

const mapDispatchToProps = {
  getPaypalClientToken,
};

export default connect(
  null,
  mapDispatchToProps
)(BrainTreePaymentProvider);
