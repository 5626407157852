// @flow

import * as React from 'react';
import './PaymentIconsBar.css';
import visaImageSrc from '../../static/images/paymentIcons/visa.png';
import mastercardImageSrc from '../../static/images/paymentIcons/master.png';
import amexImageSrc from '../../static/images/paymentIcons/amex.png';
import secureImageSrc from '../../static/images/paymentIcons/secure-ssl-encryption.png';
import paypalImageSrc from '../../static/images/paymentIcons/paypal.png';
import postImageSrc from '../../static/images/paymentIcons/post.png';

export default () => (
  <div className="paymentIconsBar">
    <img
      className="paymentIconsBar__image paymentIconsBar__sslIcon"
      src={secureImageSrc}
      alt="Secure SSL Encryption"
    />
    <div className="paymentIconsBar__paymentIcons">
      <img
        className="paymentIconsBar__image"
        src={postImageSrc}
        alt="Postfinance"
      />
      <img
        className="paymentIconsBar__image"
        src={paypalImageSrc}
        alt="Paypal"
      />
      <img className="paymentIconsBar__image" src={visaImageSrc} alt="Visa" />
      <img
        className="paymentIconsBar__image"
        src={mastercardImageSrc}
        alt="Mastercard"
      />
      <img
        className="paymentIconsBar__image"
        src={amexImageSrc}
        alt="American Express"
      />
    </div>
  </div>
);
