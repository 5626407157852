// @flow

import * as React from 'react';
import Hidden from '@material-ui/core/Hidden';
import './CheckoutSteps.css';
import { NavLink } from 'react-router-dom';
import CheckoutTitle from './CheckoutTitle';
import classNames from '../../utils/classNames';

type Props = {
  index: any,
  steps: any[],
  stepsCompleted: {
    [key: string]: boolean,
  },
};

const Icon = ({ current = false, completed = false }) => (
  <div>
    <svg
      viewBox="0 0 50 50"
      xmlns="http://www.w3.org/2000/svg"
      height="50"
      width="50"
      className={classNames(['checkout__icon'])}
    >
      <g>
        <path fill="none" d="M0,0h24v24H0V0z" />
        <circle
          cx="25"
          cy="25"
          r="24"
          className={
            completed
              ? 'checkout__icon-circle--completed'
              : current
                ? 'checkout__icon-circle--active'
                : 'checkout__icon-circle'
          }
        />
        <path
          className={
            completed
              ? 'checkout__icon-check--completed'
              : current
                ? 'checkout__icon-check--active'
                : 'checkout__icon-check'
          }
          transform="translate(12.5, 12.5)"
          d="M9,16.17L5.53,12.7c-0.39-0.39-1.02-0.39-1.41,0l0,0c-0.39,0.39-0.39,1.02,0,1.41l4.18,4.18c0.39,0.39,1.02,0.39,1.41,0
    L20.29,7.71c0.39-0.39,0.39-1.02,0-1.41l0,0c-0.39-0.39-1.02-0.39-1.41,0L9,16.17z"
        />
      </g>
    </svg>
  </div>
);

const BackLink = ({ disabled = false, to }) => {
  return (
    <div
      className={classNames([
        'checkout-steps__back',
        disabled
          ? 'checkout-steps__back-link--disabled'
          : 'checkout-steps__back-link',
      ])}
    >
      <svg width="24" height="24" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
        />
      </svg>
      {to ? <NavLink to={to}>Zurück</NavLink> : <span>Zurück</span>}
    </div>
  );
};

const stepLabels = ['Erster', 'Zweiter', 'Dritter', 'Vierter', 'Fünfter'];

const CheckoutSteps = ({ index, steps, stepsCompleted }: Props) => (
  <div>
    <CheckoutTitle>Fortschritt</CheckoutTitle>

    <Hidden smUp>
      <BackLink
        disabled={steps[index].key === 'auth' || steps[index].key === 'address'}
        to={(steps[index - 1] || {}).key}
      />
    </Hidden>

    <div className="checkout-steps__container">
      {steps.filter(s => s.key !== 'success').map(step => (
        <Hidden key={`step${step.key}`} xsDown={steps[index].key !== step.key}>
          <div className="checkout-steps__step">
            <Icon
              completed={stepsCompleted[step.key]}
              current={steps[index].key === step.key}
            />

            <div className="checkout-steps__text">
              <h4 className="checkout-steps__hint">
                {stepLabels[step.index]} Schritt
              </h4>

              <h4 className="checkout-steps__title">
                {stepsCompleted[step.key] && steps[index] !== step.key ? (
                  <NavLink className="checkout-steps__link" to={step.key}>
                    {step.label}
                  </NavLink>
                ) : (
                  <span className="checkout-steps__link">{step.label}</span>
                )}
              </h4>
            </div>
          </div>
        </Hidden>
      ))}
    </div>
    <div className="stepper__hr" />
  </div>
);

export default CheckoutSteps;
