// @flow
import { INVOICES_SUCCESS } from '../actions/invoices';

export type Invoice = {
  id: number,
  invoice_date: string,
  currency: string,
  gross_sum: number,
  invoice_number: string,
};

export default (state: Invoice[] = [], action: any) => {
  switch (action.type) {
    case INVOICES_SUCCESS:
      return [...action.payload].sort((a, b) => b.id - a.id);
    default:
      return state;
  }
};
