import {
  ROUTE_AUTH,
  ROUTE_CHECKOUT,
  ROUTE_LOGIN,
  ROUTE_PASSWORD_RESET,
  ROUTE_REGISTER,
} from './constants/routes';

export const isPublicRoute = location =>
  location.hasOwnProperty('pathname') &&
  (location.pathname === ROUTE_LOGIN ||
    location.pathname === ROUTE_REGISTER ||
    location.pathname === ROUTE_AUTH ||
    location.pathname.startsWith(ROUTE_PASSWORD_RESET) ||
    location.pathname.startsWith(ROUTE_CHECKOUT) ||
    location.pathname.startsWith('/bestellung'));

export const isCheckoutRoute = location =>
  location.hasOwnProperty('pathname') &&
  (location.pathname.startsWith('/checkout') ||
    location.pathname.startsWith('/bestellung'));
