// @flow

import {
  PAYMENTS_SUCCESS,
  PAYMENTS_CREATE_SUCCESS,
  PAYMENTS_DELETE_SUCCESS,
} from '../actions/payment';

export type Source = {
  id: number,
  brand: string,
  exp_month: number,
  exp_year: number,
  last4: string,
};

const initialState: Source[] = [];

export default (state: Source[] = initialState, action: any): Source[] => {
  switch (action.type) {
    case PAYMENTS_SUCCESS: {
      return [...action.payload];
    }
    case PAYMENTS_CREATE_SUCCESS: {
      return [...state, action.payload];
    }
    case PAYMENTS_DELETE_SUCCESS: {
      return state.filter(item => item.id !== action.payload);
    }
    default:
      return state;
  }
};
