// @flow

import * as React from 'react';
import type { Address, PaymentSource } from '../../types';
import Button from '../inputs/Button';
import Addresses from '../address/Addresses';
import { isValidAddress } from '../../redux/addresses';
import { STRIPE_PK } from '../../index';
import {
  Elements,
  StripeProvider,
  injectStripe,
  CardElement,
} from 'react-stripe-elements';
import PaymentSelector from '../payments/PaymentSelectorNew';
import PaymentTypeSelector from '../payments/PaymentTypeSelector';
import { paymentTypes } from '../../types/payment';
import type { PaymentType } from '../../types/payment';
import type { StripeResponse } from '../../types/stripe';
import BrainTreePaymentButton from '../../containers/payment/BrainTreePaymentButton';
import { productTypes } from '../../redux/checkout';
import { fetchPaypalClientToken } from '../../actions/payment';
import type {
  Subscription,
  SubscriptionPaymentUpdate,
} from '../../types/subscription';
import type { ServingOption } from '../../types/plan';
import { getBillingAgreementText } from '../../utils/checkout';

type Props = {
  servingOptions: ServingOption[],
  subscription: Subscription,
  paymentSources: PaymentSource[],
  isLoading: boolean,
  addresses: Address[],
  updateSubscription: (number, SubscriptionPaymentUpdate) => void,
  stripe?: {
    createToken: Function,
  },
};

type State = {
  subscriptionId: ?number,
  disabled?: boolean,
  isLoading?: boolean,
  error: boolean,
  selectedPaymentType: PaymentType,
  activePaymentType: PaymentType,
  selectedPaymentSourceId: ?number,
  delivery_address: ?Address,
  billing_address: ?Address,
  paymentBrainTreeClientToken: ?string,
  paypalRefresh: boolean,
};

const stripeInputStyles = {
  base: {
    fontSize: '16px',
    '::placeholder': {
      color: '#c6c6c6',
    },
  },
};

class UpdatePaymentAndAddressForm extends React.Component<Props, State> {
  state = {
    error: false,
    subscriptionId: null,
    selectedPaymentType: '',
    activePaymentType: '',
    isLoading: false,
    selectedPaymentSourceId: null,
    delivery_address: null,
    billing_address: null,
    token: null,
    paymentBrainTreeClientToken: null,
    paypalRefresh: false,
  };

  componentDidMount() {
    const { subscription } = this.props;

    if (!subscription.payment_description) {
      return;
    }

    this.setSubscriptionFieldsToState(subscription);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.isLoading) {
      return;
    }

    const { subscription } = this.props;

    if (!subscription.payment_description) {
      return;
    }

    const { subscriptionId } = this.state;

    const subscriptionIdUpdated = subscriptionId !== subscription.id;

    const paymentMethodUpdated = () => {
      if (
        this.props.subscription.payment_description &&
        this.props.subscription.payment_description.type &&
        prevProps.subscription.payment_description &&
        prevProps.subscription.payment_description.type
      ) {
        return (
          this.props.subscription.payment_description.type !==
          prevProps.subscription.payment_description.type
        );
      }

      return false;
    };

    if (subscriptionIdUpdated || paymentMethodUpdated()) {
      this.setSubscriptionFieldsToState(subscription);
    }
  }

  getPaymentType = (subscription: Subscription) => {
    const { type } = subscription.payment_description;
    const { paymentBrainTreeClientToken } = this.state;

    if (
      subscription.payment_source ||
      subscription.token ||
      (type && type === paymentTypes.creditCard)
    ) {
      return paymentTypes.creditCard;
    }

    if (
      (type && type.toLowerCase() === paymentTypes.paypal) ||
      paymentBrainTreeClientToken
    ) {
      return paymentTypes.paypal;
    }

    return undefined;
  };

  setSubscriptionFieldsToState = (subscription: Subscription) =>
    this.setState({
      subscriptionId: subscription.id,
      billing_address: subscription.billing_address,
      delivery_address: subscription.delivery_address,
      selectedPaymentSourceId: subscription.payment_source,
      selectedPaymentType: this.getPaymentType(subscription),
      activePaymentType: this.getPaymentType(subscription),
      paypalRefresh: false,
    });

  updateAddressInSubscription = (addressKey: string, address: Address) =>
    this.setState({
      [addressKey]: address,
    });

  updateAddressesInSubscription = (address: Address) =>
    this.setState({
      billing_address: address,
      delivery_address: address,
    });

  handleAddressChange = ({
    addressType,
    address,
    addressesAreEqual,
  }: {
    addressType: string,
    address: Address,
    addressesAreEqual?: boolean,
  }) => {
    if (addressesAreEqual) {
      return this.updateAddressesInSubscription(address);
    }
    const addressKey = `${addressType}_address`;
    this.updateAddressInSubscription(addressKey, address);
  };

  handleAddressFieldChange({
    addressType,
    field,
    value,
    addressesAreEqual,
  }: {
    addressType: string,
    field: string,
    value: string,
    addressesAreEqual?: boolean,
  }) {
    const addressKey = `${addressType}_address`;
    const currentAddress = this.state[addressKey];
    const address = { ...currentAddress, [field]: value };
    if (addressesAreEqual) {
      return this.updateAddressesInSubscription(address);
    }
    this.updateAddressInSubscription(addressKey, address);
  }

  handleStripeInputChange = (change: { complete: boolean }) =>
    this.setState({ disabled: change.complete === false });

  handleStripeReadyChange = () =>
    this.state.subscriptionId && this.state.selectedPaymentSourceId
      ? this.setState({ disabled: true })
      : null;

  validateAddresses = () =>
    this.state.delivery_address &&
    this.state.billing_address &&
    isValidAddress(this.state.delivery_address) &&
    isValidAddress(this.state.billing_address);

  handlePaymentSourceChange = (id: number | null) =>
    this.setState({
      selectedPaymentSourceId: id,
      disabled: typeof id !== 'number',
    });

  formIsValid = (): boolean => {
    if (this.props.isLoading || this.state.isLoading) {
      return false;
    }

    if (this.validateAddresses() === false) {
      return false;
    }

    if (this.state.disabled) {
      return false;
    }

    const { selectedPaymentSourceId } = this.state;

    if (selectedPaymentSourceId) {
      return true;
      // todo: handle paypal

      /* if (subscription.token === '') {
        return false;
      } */
    }

    return true;
  };

  componentDidCatch(error, info) {
    console.log(error);
    console.log(info);
  }

  handleSubscriptionUpdate = async (props: any) => {
    const {
      selectedPaymentSourceId,
      delivery_address,
      billing_address,
      selectedPaymentType,
      paypalRefresh,
    } = this.state;

    if (!delivery_address || !billing_address) {
      return;
    }

    const updatedSubscription: SubscriptionPaymentUpdate = {
      id: this.props.subscription.id,
      delivery_address,
      billing_address,
      status: this.props.subscription.status,
    };

    if (selectedPaymentType === paymentTypes.paypal && paypalRefresh === true) {
      updatedSubscription.nonce = props.nonce;
      updatedSubscription.livemode = props.livemode;
    }

    // handle stripe communication
    if (selectedPaymentType === paymentTypes.creditCard) {
      if (selectedPaymentSourceId === null) {
        const { stripe } = this.props;

        if (!stripe) {
          console.log('stripe not found');
          return null;
        }

        this.setState({ isLoading: true });

        const stripeResponse: StripeResponse = await stripe.createToken();
        if (await stripeResponse.hasOwnProperty('error')) {
          // todo: handle errors properly
          this.setState({ isLoading: false });
          return;
        }

        if ((await stripeResponse.hasOwnProperty('token')) === false) {
          // todo: handle errors properly
          this.setState({ isLoading: false });
          return;
        }

        updatedSubscription.token = stripeResponse.token.id;
        updatedSubscription.livemode = stripeResponse.token.livemode;
      } else {
        updatedSubscription.payment_source = selectedPaymentSourceId;
      }
    }

    try {
      await this.props.updateSubscription(
        this.props.subscription.id,
        updatedSubscription
      );
      this.setState({ isLoading: false });
    } catch (err) {
      // todo: handle errors properly
      console.log(err);
      this.setState({ isLoading: false });
    }
  };

  handlePaymentTypeChange = (
    selectedPaymentType: PaymentType,
    paypalRefresh = false
  ) => {
    this.setState({ isLoading: true });

    if (selectedPaymentType === paymentTypes.paypal) {
      const noClientTokenPresentAndShouldRefresh =
        this.state.paymentBrainTreeClientToken === null &&
        paypalRefresh === true;

      const wasCreditCardBefore =
        this.state.activePaymentType === paymentTypes.creditCard;

      // todo: this check might be optimistic, the paypal token may expire
      if (noClientTokenPresentAndShouldRefresh || wasCreditCardBefore) {
        return fetchPaypalClientToken()
          .then(response =>
            this.setState({
              selectedPaymentType,
              isLoading: false,
              paymentBrainTreeClientToken: response.client_token,
              selectedPaymentSourceId: null,
              paypalRefresh: paypalRefresh || wasCreditCardBefore,
            })
          )
          .catch(() => this.setState({ error: true, isLoading: false }));
      }
    }

    this.setState({ selectedPaymentType, isLoading: false });
  };

  renderPaypalSelector = () => (
    <p>
      Dein Abo ist mit dem Paypal Konto "
      {this.props.subscription.payment_description.description}" verknüpft.{' '}
      <span
        onClick={() => this.handlePaymentTypeChange(paymentTypes.paypal, true)}
        className="link link--inline"
      >
        Mit einem anderen Konto verknüpfen
      </span>
    </p>
  );

  renderCreditCardSelector = () => {
    const { paymentSources } = this.props;
    const { selectedPaymentSourceId } = this.state;

    return (
      <React.Fragment>
        {selectedPaymentSourceId && (
          <PaymentSelector
            selectedPaymentId={selectedPaymentSourceId || null}
            paymentSources={paymentSources}
            onChange={this.handlePaymentSourceChange}
          />
        )}

        {!selectedPaymentSourceId && (
          <CardElement
            onChange={this.handleStripeInputChange}
            onReady={this.handleStripeReadyChange}
            style={stripeInputStyles}
            hidePostalCode={true}
          />
        )}

        {!selectedPaymentSourceId &&
          paymentSources.length && (
            <span
              className="link link--gutter"
              onClick={() =>
                this.handlePaymentSourceChange(paymentSources[0].id)
              }
            >
              Liste anzeigen
            </span>
          )}
      </React.Fragment>
    );
  };

  getServingOption = () => {
    const servingOptionId =
      typeof this.props.subscription.serving_option === 'number'
        ? this.props.subscription.serving_option
        : this.props.subscription.serving_option.id;

    const servingOption = this.props.servingOptions.find(
      s => s.id === servingOptionId
    );

    if (!servingOption) {
      throw new Error('Serving option not found');
    }

    return servingOption;
  };

  getPrice = () => {
    const servingOption = this.getServingOption();
    return servingOption.price;
  };

  render() {
    const { addresses } = this.props;
    const {
      activePaymentType,
      selectedPaymentType,
      delivery_address,
      billing_address,
      paymentBrainTreeClientToken,
      paypalRefresh,
    } = this.state;

    const isLoading = this.props.isLoading || this.state.isLoading;
    const price = this.getPrice();

    return (
      <div>
        <h3>Bezahlung</h3>
        <div className="form__group">
          <PaymentTypeSelector
            selected={selectedPaymentType}
            onChange={this.handlePaymentTypeChange}
            isLoading={isLoading}
          />

          {selectedPaymentType !== paymentTypes.creditCard
            ? null
            : this.renderCreditCardSelector()}

          {selectedPaymentType === paymentTypes.paypal &&
          paypalRefresh === false
            ? this.renderPaypalSelector()
            : null}
        </div>

        <div className="form__group">
          <Addresses
            addresses={addresses}
            deliveryAddress={delivery_address}
            billingAddress={billing_address}
            loading={isLoading || this.state.isLoading}
            onChange={this.handleAddressChange}
            onChangeField={this.handleAddressFieldChange}
          />
        </div>

        {selectedPaymentType === paymentTypes.creditCard && (
          <Button
            onClick={this.handleSubscriptionUpdate}
            disabled={this.formIsValid() === false}
          >
            Abo aktualisieren
          </Button>
        )}

        {activePaymentType === paymentTypes.paypal &&
          selectedPaymentType === paymentTypes.paypal &&
          paypalRefresh === false && (
            <Button
              onClick={this.handleSubscriptionUpdate}
              disabled={this.formIsValid() === false}
            >
              Abo aktualisieren
            </Button>
          )}

        {selectedPaymentType === paymentTypes.paypal &&
          price &&
          paymentBrainTreeClientToken &&
          paypalRefresh === true && (
            <BrainTreePaymentButton
              paymentBrainTreeClientToken={paymentBrainTreeClientToken}
              addresses={addresses}
              productType={productTypes.subscription}
              price={price}
              handleSubmit={this.handleSubscriptionUpdate}
              billingAgreementDescription={getBillingAgreementText(
                this.props.subscription.plan_title,
                this.getServingOption().title,
                price
              )}
            />
          )}
      </div>
    );
  }
}

const WithStripe = injectStripe(UpdatePaymentAndAddressForm);

const UpdatePaymentAndAddressFormWithStripeProvider = (props: Props) => (
  <StripeProvider apiKey={STRIPE_PK}>
    <Elements locale={'de'}>
      <WithStripe {...props} />
    </Elements>
  </StripeProvider>
);

export default UpdatePaymentAndAddressFormWithStripeProvider;
