import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { appChangeLocation } from '../../actions/router';
import { connect } from 'react-redux';

class SubscriptionListItemMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleCancelSubscription = () => {
    this.props.setDialogOpen({
      target: 'cancelSubscription',
      targetId: this.props.subscriptionId,
    });
    this.handleClose();
  };

  handlePauseSubscription = () => {
    const target =
      this.props.status === 'ACTIVE'
        ? 'pauseSubscription'
        : 'resumeSubscription';

    this.props.setDialogOpen({
      target,
      targetId: this.props.subscriptionId,
    });
    this.handleClose();
  };

  handleChangeAbo = () => {
    const { subscriptionId } = this.props;
    this.props.appChangeLocation(`/subscriptions/${subscriptionId}/edit`);
  };

  render() {
    const { anchorEl } = this.state;
    const { isEditable, status } = this.props;
    const pauseText =
      status === 'ACTIVE' ? 'Abo pausieren' : 'Abo wiederaufnehmen';

    return (
      <Fragment>
        <Button
          aria-owns={anchorEl ? 'simple-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
          size="small"
          color={'primary'}
        >
          {'Abo anpassen'}
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem
            disabled={!isEditable}
            onClick={this.handlePauseSubscription}
          >
            {pauseText}
          </MenuItem>
          <MenuItem disabled={!isEditable} onClick={this.handleChangeAbo}>
            Abo ändern
          </MenuItem>
          <MenuItem onClick={this.handleCancelSubscription}>
            Abo löschen
          </MenuItem>
        </Menu>
      </Fragment>
    );
  }
}

SubscriptionListItemMenu.propTypes = {
  subscriptionId: PropTypes.number.isRequired,
  setDialogOpen: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  isEditable: PropTypes.bool,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = { appChangeLocation };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionListItemMenu);
