import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';

const Address = ({ address }) => (
  <Fragment>
    <Typography variant="body1">
      {address.first_name} {address.last_name}
    </Typography>
    {address.line2 ? (
      <Typography variant="body1">{`${address.line2}`}</Typography>
    ) : null}
    <Typography variant="body1">{`${address.street}`}</Typography>
    <Typography variant="body1">{`${address.zip} ${address.city}`}</Typography>
  </Fragment>
);

export default Address;
