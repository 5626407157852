// @flow

import { LANDING_PAGE_URL } from '../index';

export function getSubscriptionFromUrlParams(
  urlParams: URLSearchParams,
  useDefaultParams: boolean,
  isSubscription: boolean
) {
  if (useDefaultParams) {
    return {
      planId: 1,
      quantity: 2,
      interval: 7,
      deliveryDate: null,
      hasError: false,
    };
  }

  const planId = urlParams.has('planId')
    ? parseInt(urlParams.get('planId'), 10)
    : null;

  const quantity = urlParams.has('quantity')
    ? parseInt(urlParams.get('quantity'), 10)
    : null;

  const interval = urlParams.has('interval')
    ? parseInt(urlParams.get('interval'), 10)
    : null;

  const deliveryDate = urlParams.has('deliveryDate')
    ? urlParams.get('deliveryDate')
    : null;

  const code = urlParams.has('code') ? urlParams.get('code') : undefined;

  // has plan and quantity
  if (planId === null || quantity === null) {
    return {
      hasError: true,
    };
  }

  // is subscription
  if (isSubscription) {
    if (interval === null) {
      return {
        hasError: true,
      };
    }
  }

  return {
    code,
    planId,
    quantity,
    interval,
    deliveryDate,
    hasError: false,
  };
}

export function getGiftBoxFromUrlParams(urlParams: URLSearchParams) {
  const message = {
    name: urlParams.has('name') ? urlParams.get('name') : undefined,

    email: urlParams.has('recipient_email')
      ? urlParams.get('recipient_email')
      : undefined,

    recipient_name: urlParams.has('recipient_name')
      ? urlParams.get('recipient_name')
      : undefined,

    text: urlParams.has('recipient_message')
      ? urlParams.get('recipient_message')
      : undefined,

    send_date: urlParams.has('send_date')
      ? urlParams.get('send_date')
      : undefined,
  };

  const planId = urlParams.has('planId')
    ? parseInt(urlParams.get('planId'), 10)
    : null;

  const quantity = urlParams.has('quantity')
    ? parseInt(urlParams.get('quantity'), 10)
    : null;

  // has plan and quantity
  if (planId === null || quantity === null) {
    return {
      hasError: true,
    };
  }

  return {
    planId,
    quantity,
    message,
    hasError: false,
  };
}

export function getGiftBoxRedeemFromUrlParams(urlParams: URLSearchParams) {
  const code = urlParams.has('code') ? urlParams.get('code') : null;

  const deliveryDate = urlParams.has('deliveryDate')
    ? urlParams.get('deliveryDate')
    : null;

  if (!code || !deliveryDate) {
    return {
      hasError: true,
    };
  }

  return {
    code,
    deliveryDate,
    hasError: false,
  };
}

export function redirectToLandingPageWithError(origin: string) {
  window.location = `${LANDING_PAGE_URL}?error=${origin}`;
  return null;
}

export function getBillingAgreementText(
  planTitle: string,
  servingOptionTitle: string,
  price: string
): string {
  return `${planTitle} für ${servingOptionTitle} für CHF ${price} pro Foodbox`;
}
