import React from 'react';
import './FormControl.css';

export default ({
  id,
  label,
  value,
  onChange,
  onBlur,
  disabled,
  readonly,
  required,
  autoFocus,
  fullWidth,
  placeholder,
}) => (
  <input
    id={id}
    value={value}
    readOnly={readonly}
    disabled={disabled === true}
    placeholder={placeholder}
    onChange={onChange ? ev => onChange(ev) : null}
    onBlur={onBlur ? ev => onBlur(ev) : null}
    className={[
      'form-control form-control--border',
      fullWidth !== undefined ? 'form-control--fullWidth' : undefined,
    ].join(' ')}
    required={required !== undefined}
    type="text"
  />
);
