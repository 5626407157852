import decode from 'jwt-decode';
import {
  refreshToken,
  userLogout,
  USER_LOGIN_SUCCESS,
  USER_REGISTER_SUCCESS,
  USER_TOKEN_REFRESH_SUCCESS,
  USER_LOGIN_FROM_TOKEN_ONLY,
} from '../actions/user';
import {
  USER_AUTH_FROM_LOCAL_STORAGE,
  USER_LOGOUT,
} from '../constants/actionTypes';
import { appSetToken } from '../actions/app';

const tokenHasExpired = expiresIn =>
  expiresIn !== null && Date.now() > expiresIn * 1000;

const localStorageMiddleware = store => next => action => {
  if (
    action.type === USER_LOGIN_SUCCESS ||
    action.type === USER_REGISTER_SUCCESS ||
    action.type === USER_TOKEN_REFRESH_SUCCESS
  ) {
    const token = action.payload.token;
    try {
      window.localStorage.setItem('access_token', token);
    } catch (err) {
      console.log(err);
    }
    const decodedToken = decode(token);
    store.dispatch(appSetToken({ token, decodedToken }));
  }

  if (action.type === USER_LOGIN_FROM_TOKEN_ONLY) {
    const token = action.payload.token;
    const decodedToken = decode(token);
    store.dispatch(appSetToken({ token, decodedToken }));
  }

  try {
    if (action.type === USER_LOGOUT) {
      window.localStorage.removeItem('access_token');
    }

    if (action.type === USER_AUTH_FROM_LOCAL_STORAGE) {
      if (!window.hasOwnProperty('localStorage')) {
        next(action);
        return;
      }

      const token = window.localStorage.getItem('access_token');
      if (
        token === '' ||
        token === null ||
        token === undefined ||
        token === 'undefined'
      ) {
        next(action);
        return;
      }
      const decodedToken = decode(token);
      if (tokenHasExpired(decodedToken.exp) === false) {
        const decodedToken = decode(token);
        store.dispatch(appSetToken({ token, decodedToken }));
        store.dispatch(refreshToken(token));
      } else {
        store.dispatch(userLogout);
      }
    }
  } catch (err) {
    console.log(err);
  }

  next(action);
};

export default localStorageMiddleware;
