// address delete
import { CALL_API } from '../middleware/api';

export const VOUCHER_DISCOUNT_REQUEST = 'VOUCHER_DISCOUNT_REQUEST';
export const VOUCHER_DISCOUNT_SUCCESS = 'VOUCHER_DISCOUNT_SUCCESS';
export const VOUCHER_DISCOUNT_FAILURE = 'VOUCHER_DISCOUNT_FAILURE';

const fetchVoucher = code => ({
  type: 'VOUCHER_START',
  [CALL_API]: {
    types: [
      VOUCHER_DISCOUNT_REQUEST,
      VOUCHER_DISCOUNT_SUCCESS,
      VOUCHER_DISCOUNT_FAILURE,
    ],
    endpoint: `/api/v1/voucher/${code}/`,
  },
});

export const getVoucher = code => dispatch => dispatch(fetchVoucher(code));
