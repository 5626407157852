// @flow

import * as React from 'react';
import { CHECKOUT_TYPE_SUBSCRIPTION } from '../../redux/checkout';
import { connect } from 'react-redux';
import { checkoutSubmitNew } from '../../actions/checkout';
import type { ProductType } from '../../redux/checkout';

type BrainTree = {
  paypalCheckout: {
    create: any => Promise<any>,
  },
  client: {
    create: ({ authorization: string }) => Promise<any>,
  },
};

type PaypalCheckoutInstance = {
  createPayment: any => any,
  tokenizePayment: any => any,
};

type Paypal = {
  Button: {
    render: (
      options: {
        env: string,
        style: any,
        payment: any => any,
        onAuthorize: any => PaypalCheckoutInstance,
        onCancel: any => any,
        onError: any => any,
      },
      PaymentButtonRenderElement: any
    ) => any,
  },
};

type PaymentOptions = {
  flow: 'vault' | 'checkout',
  enableShippingAddress: false,
  shippingAddressEditable: false,
  intent?: string,
  locale: string,
  currency: string,
  displayName: string,
  billingAgreementDescription: string,
  amount?: number | string,
};

function btPayment(
  PaymentButtonRenderElement,
  clientToken: string,
  productType,
  checkoutSubmitFn,
  price,
  billingAgreementDescription,
  env = 'production'
) {
  if (!window.hasOwnProperty('braintree')) {
    return null;
  }

  const braintree: BrainTree = window.braintree;
  const paypal: Paypal = window.paypal;
  const flow =
    productType === CHECKOUT_TYPE_SUBSCRIPTION ? 'vault' : 'checkout';

  const paymentOptions: PaymentOptions = {
    flow,
    enableShippingAddress: false,
    shippingAddressEditable: false,
    locale: 'de_DE',
    currency: 'CHF',
    displayName: 'Juts AG',
    billingAgreementDescription,
  };

  if (productType === CHECKOUT_TYPE_SUBSCRIPTION) {
    paymentOptions.intent = 'order';
  }

  if (productType !== CHECKOUT_TYPE_SUBSCRIPTION) {
    if (!price) {
      throw new Error(
        `Price object is missing for checkout type ${productType}`
      );
    }
    paymentOptions.amount = price.total;
  }

  // https://braintree.github.io/braintree-web/current/PayPalCheckout.html#createPayment
  // https://developers.braintreepayments.com/guides/paypal/client-side/javascript/v3

  braintree.client
    .create({
      authorization: clientToken,
    })
    .then(clientInstance =>
      braintree.paypalCheckout.create({ client: clientInstance })
    )
    .then((paypalCheckoutInstance: PaypalCheckoutInstance) =>
      paypal.Button.render(
        {
          env,
          commit: productType !== CHECKOUT_TYPE_SUBSCRIPTION,
          style: {
            label: 'paypal',
            size: 'responsive', // small | medium | large | responsive
            shape: 'rect', // pill | rect
            color: 'blue', // gold | blue | silver | black
            tagline: false,
          },
          payment: () => paypalCheckoutInstance.createPayment(paymentOptions),
          onAuthorize: (data, actions) =>
            paypalCheckoutInstance
              .tokenizePayment(data)
              .then(payload => checkoutSubmitFn(payload)),
          onCancel: data => {
            console.log('checkout.js payment cancelled', JSON.stringify(data));
          },

          onError: err => {
            console.error('checkout.js error', err);
          },
        },
        PaymentButtonRenderElement
      )
    )
    .catch(err => {
      console.error('Error!', err);
    });
}

type Props = {
  handleSubmit: any => void,
  checkoutSubmitNew: any => void,
  productType: ProductType,
  paymentBrainTreeClientToken: string,
  billingAgreementDescription: string,
  price?: {
    total: string,
  },
};

type State = {
  checkoutButtonReady: boolean,
};

class BrainTreePaymentButton extends React.Component<Props, State> {
  inputRef = React.createRef();

  constructor(props: Props) {
    super(props);
    this.state = {
      checkoutButtonReady: false,
    };
  }

  componentDidMount() {
    const {
      price,
      checkoutSubmitNew,
      handleSubmit,
      productType,
      paymentBrainTreeClientToken,
      billingAgreementDescription,
    } = this.props;

    if (!paymentBrainTreeClientToken) {
      return null; // todo: get error
    }

    if (!billingAgreementDescription) {
      throw new Error('billingAgreementDescription missing');
    }

    btPayment(
      this.inputRef.current,
      paymentBrainTreeClientToken,
      productType,
      handleSubmit || checkoutSubmitNew,
      price,
      billingAgreementDescription,
      process.env.REACT_APP_PAYPAL_ENV || 'production'
    );
  }

  render() {
    if (!this.props.paymentBrainTreeClientToken) {
      return <div>...Paypal wird geladen</div>;
    }

    return <div ref={this.inputRef} />;
  }
}

const mapStateToProps = state => ({ checkout: state.checkout });

const mapDispatchToProps = {
  checkoutSubmitNew,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrainTreePaymentButton);
