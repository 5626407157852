import React, { Fragment } from 'react';
import { HeadlineTitle } from '../typography/Headlines';
import Divider from '@material-ui/core/Divider';
import ProfileCards from '../../containers/profile/ProfileCards';
import SubscriptionEditPaymentAndAddress from '../../containers/subscriptions/ViewSubscriptionEditPaymentAndAddress';

export default () => (
  <Fragment>
    <HeadlineTitle title={'Benutzerdaten'} />
    <ProfileCards />
    <Divider style={{ margin: '2rem 0' }} />
    <SubscriptionEditPaymentAndAddress />
  </Fragment>
);
