export const APP_SET_TOKEN = 'APP_SET_TOKEN';
export const APP_TOGGLE_NAVIGATION = 'APP_TOGGLE_NAVIGATION';
export const APP_TOGGLE_NAVIGATION_CLOSE = 'APP_TOGGLE_NAVIGATION_CLOSE';

export const DIALOG_OPEN = 'DIALOG_OPEN';
export const DIALOG_CLOSE = 'DIALOG_CLOSE';

export const CHECKOUT_SET_DELIVERY_DATE = 'CHECKOUT_SET_DELIVERY_DATE';
export const CHECKOUT_SET_INTERVAL = 'CHECKOUT_SET_INTERVAL';
export const CHECKOUT_SET_SERVING_OPTION = 'CHECKOUT_SET_SERVING_OPTION';
export const CHECKOUT_SET_SOURCE_ERROR = 'CHECKOUT_SET_SOURCE_ERROR';
export const CHECKOUT_SET_STEPS_RETURNING_CUSTOMER =
  'CHECKOUT_SET_STEPS_RETURNING_CUSTOMER';
export const CHECKOUT_SET_DISCOUNT_VOUCHER_CODE =
  'CHECKOUT_SET_DISCOUNT_VOUCHER_CODE';
export const CHECKOUT_SET_PLAN = 'CHECKOUT_SET_PLAN';
export const CHECKOUT_SET_SOURCE_ID = 'CHECKOUT_SET_SOURCE_ID';
export const CHECKOUT_SET_FIELDS = 'CHECKOUT_SET_FIELDS';
export const CHECKOUT_INCREMENT_STEP = 'CHECKOUT_INCREMENT_STEP';
export const CHECKOUT_DECREMENT_STEP = 'CHECKOUT_DECREMENT_STEP';
export const CHECKOUT_SUBMIT = 'CHECKOUT_SUBMIT';
export const CHECKOUT_START = 'CHECKOUT_START';
export const CHECKOUT_START_REDEEM = 'CHECKOUT_START_REDEEM';

export const USER_SET_PROFILE = 'USER_SET_PROFILE';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_AUTH_FROM_LOCAL_STORAGE = 'USER_AUTH_FROM_LOCAL_STORAGE';
export const USER_SET_CONTACT_SOURCE = 'USER_SET_CONTACT_SOURCE';

export const CHECKOUT_SET_HAS_ALTERNATE_BILLING =
  'CHECKOUT_SET_HAS_ALTERNATE_BILLING';
export const CHECKOUT_SET_ADDRESS_DELIVERY_ID =
  'CHECKOUT_SET_ADDRESS_DELIVERY_ID';
export const CHECKOUT_SET_ADDRESS_BILLING_ID =
  'CHECKOUT_SET_ADDRESS_BILLING_ID';

export const CHECKOUT_SET_DIALOG_OPEN = 'CHECKOUT_SET_DIALOG_OPEN';
export const CHECKOUT_SET_DIALOG_CLOSE = 'CHECKOUT_SET_DIALOG_CLOSE';
export const CHECKOUT_SET_STEP = 'CHECKOUT_SET_STEP';
export const CHECKOUT_RESET_VOUCHER = 'CHECKOUT_RESET_VOUCHER';

export const SNACKBAR_OPEN = 'SNACKBAR_OPEN';
export const SNACKBAR_CLOSE = 'SNACKBAR_CLOSE';

export const CHECKOUT_SET_BRAIN_TREE_NONCE = 'CHECKOUT_SET_BRAIN_TREE_NONCE';
