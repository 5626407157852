// @flow
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { loadPlans } from '../../actions/plans';
import { Plan } from '../../redux/plans';
import {
  HeadlineHero,
  HeadlineTitle,
} from '../../components/typography/Headlines';

const ProductDetail = (plan: Plan) => (
  <div key={plan.id}>
    <HeadlineTitle title={plan.title} />
    <p>{plan.description}</p>
  </div>
);

class BoxList extends Component {
  componentWillMount() {
    this.props.loadPlans();
  }

  render() {
    const { plans } = this.props;
    return (
      <Fragment>
        <HeadlineHero title={'Produkte'} />
        {plans.map(plan => ProductDetail(plan))}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  plans: state.plan,
});

export default connect(
  mapStateToProps,
  {
    loadPlans,
  }
)(BoxList);
