// @flow

import * as React from 'react';

import { LANDING_PAGE_URL } from '../index';

export const FORM_LOGIN_LABEL_EMAIL = 'Email Adresse';
export const FORM_LOGIN_LABEL_PASSWORD = 'Passwort';
export const FORM_LOGIN_LABEL_PASSWORD_REPEAT = 'Passwort wiederholen';

export const FORM_LOGIN_LABEL_CURRENT_PASSWORD = 'Aktuelles Passwort';
export const FORM_LOGIN_LABEL_NEW_CURRENT_PASSWORD = 'Neues Passwort';

export const FORM_ADDRESS_LABEL_FIRST_NAME = 'Vorname';
export const FORM_ADDRESS_LABEL_LAST_NAME = 'Nachname';
export const FORM_ADDRESS_LABEL_LINE2 = 'Adresszusatz';
export const FORM_ADDRESS_LABEL_STREET = 'Strasse und Hausnummer';
export const FORM_ADDRESS_LABEL_ZIP = 'Postleitzahl';
export const FORM_ADDRESS_LABEL_CITY = 'Ort';
export const FORM_ADDRESS_LABEL_COUNTRY = 'Land';
export const FORM_ADDRESS_LABEL_PHONE_NUMBER = 'Mobilenummer';
export const FORM_ADDRESS_PLACEHOLDER_PHONE_NUMBER = '+41 79 000 00 00';

export const FORM_ADDRESS_LABEL_BILLING_ADDRESS = 'Rechnungsadresse';
export const FORM_ADDRESS_LABEL_DEFAULT_DELIVERY = 'Lieferadresse';

export const BUTTON_SKIP = 'Überspringen';
export const BUTTON_RESET = 'Zurücksetzen';
export const BUTTON_PREV = 'Zurück';
export const BUTTON_NEXT = 'Weiter';
export const BUTTON_ORDER = 'Bestellen';
export const BUTTON_CREATE_ACCOUNT = 'Benutzerkonto anlegen';
export const BUTTON_LOGIN = 'Anmelden';
export const BUTTON_PW_RESET = 'Neues Kennwort anfordern';
export const BUTTON_PW_RESET_CONFIRM = 'Neues Passwort setzen';

export const ADDRESS = 'Adresse';
export const ADDRESS_CREATE_NEW = 'Neue Adresse anlegen';
// const ADDRESS_DELIVERY_ADDRESS = 'Lieferaddresse';
export const ADDRESS_ALTERNATE_DELIVERY_ADDRESS = 'Abweichende Lieferadresse';
export const ADDRESS_YES = 'Ja';
export const ADDRESS_NO = 'Nein';

export const PAYMENT_ADD = 'Zahlungsmethode hinzufügen';
export const PAYMENT_METHOD = 'Zahlungsmethode';

export const EDIT_BOX_TYPE = '';
export const EDIT_BOX_SIZE = '';
export const EDIT_BOX_DELIVERY = 'Für wie viele Personen ist die Foodbox?';

export const DIALOG_ACTION_OK = 'Änderungen übernehmen';
export const DIALOG_ACTION_DISMISS = 'Abbrechen';

export const CHECKOUT_BOX_TITLE = 'Foodbox ändern';
export const CHECKOUT_BOX_TYPE = 'Welche Foodbox?';
export const CHECKOUT_BOX_SIZE = 'Für wie viele Personen ist die Foodbox?';
export const CHECKOUT_DELIVERY_FIRST_DAY = 'Wähle den ersten Liefertag';
export const CHECKOUT_DELIVERY_DAY = 'Wähle den Liefertag';
export const CHECKOUT_DELIVERY_PERIOD = 'Lieferzeitraum';
export const CHECKOUT_DELIVERY_TIME = '07:30 - 18.00 Uhr';
export const CHECKOUT_DELIVERY_INTERVAL = 'Lieferzyklus';

export const NAV_FOOD_BOXES = 'Meine Foodboxen';
export const NAV_CALENDAR = 'Mein Kalender';
export const NAV_INVOICES = 'Meine Rechnungen';
export const NAV_PROFILE = 'Meine Daten';
export const NAV_LOGOUT = 'Abmelden';

export const ADDRESSES_LIST_EMPTY = 'Du hast noch keine Adressen erfasst.';
export const PAYMENTS_LIST_EMPTY =
  'Du hast noch keine Zahlungsmethoden erfasst.';
export const DELIVERIES_LIST_EMPTY =
  'Es sind momentan keine Lieferungen geplant.';

export const CHECKOUT_PAYMENT_TITLE = 'Bezahlung';
export const ADDRESS_DELIVERY = 'Lieferadresse';
export const ADDRESS_BILLING = 'Rechnungsadresse';
export const ADDRESS_DELIVERY_AND_BILLING = 'Liefer- und Rechnungsadresse';

export const CTA_CHECKOUT_SUBSCRIPTION = 'Jetzt Abo starten';
export const CTA_CHECKOUT_SUBSCRIPTION_TRIAL = 'Jetzt Probebox bestellen';
export const CTA_CHECKOUT_TYPE_GIFTBOX_REDEEM = 'Jetzt Box liefern lassen';
export const CTA_CHECKOUT = 'Jetzt kaufen';

export const CHECKOUT_PRICE_INFO = 'Alle Preise inklusive MwSt.';

export const NoActiveSubscriptions = () => (
  <p>
    Du hast derzeit kein aktives Abo. Bestelle{' '}
    <a className="link" href={`${LANDING_PAGE_URL}/abo`}>
      {' '}
      hier{' '}
    </a>{' '}
    Dein neues Abo.
  </p>
);
