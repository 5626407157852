/* const ADDRESSES_CHECKOUT_CREATE_DELIVERY_SUCCESS =
  'Deine Lieferadresse wurde erfolgreich angelegt.';
const ADDRESSES_CHECKOUT_CREATE_DELIVERY_FAILURE =
  'Beim Anlegen Deiner Lieferadresse ist ein Fehler aufgetreten. Bitte versuche es nochmal.';
const ADDRESSES_CHECKOUT_UPDATE_DELIVERY_SUCCESS =
  'Deine Lieferadresse wurde erfolgreich aktualisiert.';
const ADDRESSES_CHECKOUT_UPDATE_DELIVERY_FAILURE =
  'Beim Aktualisieren Deiner Lieferadresse ist ein Fehler aufgetreten. Bitte versuche es nochmal.';
const ADDRESSES_CHECKOUT_CREATE_BILLING_SUCCESS =
  'Deine Rechnungsadresse wurde erfolgreich angelegt.';
const ADDRESSES_CHECKOUT_CREATE_BILLING_FAILURE =
  'Beim Anlegen Deiner Rechnungsadresse ist ein Fehler aufgetreten. Bitte versuche es nochmal.';
const ADDRESSES_CHECKOUT_UPDATE_BILLING_SUCCESS =
  'Deine Rechnungsadresse wurde erfolgreich aktualisiert.';
const ADDRESSES_CHECKOUT_UPDATE_BILLING_FAILURE =
  'Beim Aktualisieren Deiner Rechnungsadresse ist ein Fehler aufgetreten. Bitte versuche es nochmal.';
const ADDRESSES_UPDATE_SUCCESS =
  'Deine Adresse wurde erfolgreich aktualisiert.';
const ADDRESSES_UPDATE_FAILURE =
  'Beim Aktualisieren Deiner Adresse ist ein Fehler aufgetreten. Bitte versuche es nochmal.';
const ADDRESSES_CREATE_SUCCESS = 'Deine Adresse wurde erfolgreich angelegt.';
const ADDRESSES_CREATE_FAILURE =
  'Beim Anlegen Deiner Adresse ist ein Fehler aufgetreten. Bitte versuche es nochmal.';

const LOGIN_SUCCESS = 'Du hast Dich erfolgreich eingeloggt.';

const REGISTER_FAILURE =
  'Bei Deiner Registrierung ist ein Fehler aufgetreten. Bitte versuche es nochmal.';
const REGISTER_SUCCESS = 'Du hast Dich erfolgreich registriert.';
const SUBSCRIPTIONS_CREATE_FAILURE =
  'Beim Erstellen Deines JUTS Abos ist ein Fehler aufgetreten. Bitte versuche es nochmal.';
const SUBSCRIPTIONS_CREATE_SUCCESS =
  'Dein JUTS Abo wurde erfolgreich erstellt.';
const PAYMENTS_FAILURE =
  'Es ist ein Fehler bei der Durchführung der Zahlung aufgetreten. Bitte versuche es nochmal.';
const PAYMENTS_STRIPE_FAILURE =
  'Unser Zahlungsanbieter meldet leider einen Fehler. Bitte versuche die Zahlung nochmal auszuführen.';
const PAYMENTS_SUCCESS = 'Die Zahlung wurde erfolgreich ausgeführt.';
const DELIVERIES_FAILURE =
  'Deine Lieferung konnte nicht angelegt werden. Bitte versuche es erneut.';
const DELIVERIES_SUCCESS = 'Deine Liferung wurde erfolgreich angelegt.';
const DELIVERIES_UPDATE_FAILURE =
  'Beim Ändern der Lieferung ist ein Fehler aufgetreten. Bitte versuche es erneut.';
const DELIVERIES_UPDATE_SUCCESS = 'Deine Lieferung wurde erfolgreich geändert.';
const VOUCHER_DISCOUNT_FAILURE =
  'Beim Einlösen Deines Gutscheins ist ein Fehler aufgetreten. Bitte versuche es erneut.';
const VOUCHER_DISCOUNT_SUCCESS = 'Dein Gutschein wurde erfolgreich eingelöst.';
const CHECKOUT_CREATE_GIFTBOX_FAILURE =
  'Beim Erstellen des Gutscheins für eine JUTS Geschenkbox ist ein Fehler aufgetreten. Bitte nimm mit unserem Kundenservice Kontakt auf.';
const CHECKOUT_CREATE_GIFTBOX_SUCCESS =
  'Dein Gutschein für eine Geschenkbox wurde erfolgreich erstellt.';
const CHECKOUT_REDEEM_GIFTBOX_FAILURE =
  'Beim Einlösen Deiner Geschenkbox ist ein Fehler aufgetreten. Bitte versuche es nochmal.';
const CHECKOUT_REDEEM_GIFTBOX_SUCCESS =
  'Deine Geschenkbox wurde erfolgreich eingelöst.'; */

// User
export const user = {
  USER_LOGIN_FAILURE: 'E-Mail Adresse und Passwort stimmen nicht überein',
  USER_PASSWORD_RESET_SUCCESS:
    'Falls deine Emailadresse im System hinterlegt ist, bekommst Du einen Wiederherstellungs-Link per Email zugeschickt',
  USER_PASSWORD_RESET_FAILURE: '',
  USER_PASSWORD_CHANGE_SUCCESS: 'Dein Passwort wurde erfolgreich geändert',
  USER_PASSWORD_CHANGE_FAILURE:
    'Beim Ändern Deines Passwortes ist ein Fehler aufgetreten. Bitte versuche es nochmal',
  USER_PASSWORD_RESET_CONFIRM_SUCCESS:
    'Dein Passwort wurde erfolgreich geändert',
  USER_PASSWORD_RESET_CONFIRM_FAILURE:
    'Beim Ändern Deines Passwortes ist ein Fehler aufgetreten. Bitte versuche es nochmal',
  USER_EMAIL_CHANGE_FAILURE:
    'Beim Aktualisieren Deiner E-Mail Adresse ist ein Fehler aufgetreten. Bitte versuche es nochmal',
  USER_EMAIL_CHANGE_SUCCESS:
    'Deine E-Mail Adresse wurde erfolgreich aktualisiert',
  USER_REGISTER_FAILURE:
    'Fehler bei der Registrierung, bitte wende dich an unseren Kundenservice',
  USER_REGISTER_FAILURE_EMAIL_EXISTS: 'Diese E-Mail ist schon vergeben',
  GENERAL_ERROR:
    'Fehler bei der Registrierung, bitte wende dich an unseren Kundenservice',
};
