// @flow

import {
  CHECKOUT_RESET_VOUCHER,
  CHECKOUT_SET_ADDRESS_BILLING_ID,
  CHECKOUT_SET_ADDRESS_DELIVERY_ID,
  CHECKOUT_SET_BRAIN_TREE_NONCE,
  CHECKOUT_SET_DELIVERY_DATE,
  CHECKOUT_SET_DISCOUNT_VOUCHER_CODE,
  CHECKOUT_SET_FIELDS,
  CHECKOUT_SET_HAS_ALTERNATE_BILLING,
  CHECKOUT_SET_INTERVAL,
  CHECKOUT_SET_PLAN,
  CHECKOUT_SET_SERVING_OPTION,
  CHECKOUT_SET_SOURCE_ID,
  CHECKOUT_SET_STEP,
  CHECKOUT_START,
  CHECKOUT_START_REDEEM,
  CHECKOUT_SUBMIT,
} from '../constants/actionTypes';
import {
  ADDRESSES_CHECKOUT_CREATE_BILLING_SUCCESS,
  ADDRESSES_CHECKOUT_CREATE_DELIVERY_SUCCESS,
} from '../actions/address';
import {
  CHECKOUT_REMOVE_PAYMENT_SOURCE,
  CHECKOUT_SET_ADDRESS,
  CHECKOUT_SET_ADDRESS_FIELD,
  CHECKOUT_SET_PAYMENT_CARD,
  CHECKOUT_SET_PAYMENT_SOURCE,
  CHECKOUT_SET_PAYMENT_TYPE,
} from '../actions/checkout';
import {
  CHECKOUT_SOURCE_CREATE_SUCCESS,
  SOURCE_PAYPAL_CLIENT_TOKEN_SUCCESS,
} from '../actions/payment';
import { USER_REGISTER_SUCCESS } from '../actions/user';
import {
  VOUCHER_DISCOUNT_FAILURE,
  VOUCHER_DISCOUNT_SUCCESS,
} from '../actions/voucher';
import type { Address } from './addresses';

export const deliveryIntervalOptions = [
  { title: 'wöchentlich', interval: 7 },
  { title: 'alle zwei Wochen', interval: 14 },
  { title: 'alle drei Wochen', interval: 21 },
  { title: 'alle vier Wochen', interval: 28 },
];

export const CHECKOUT_TYPE_SUBSCRIPTION = 'subscription';
export const CHECKOUT_TYPE_SUBSCRIPTION_TRIAL = 'trial';
export const CHECKOUT_TYPE_GIFTBOX_BUY = 'giftbox';
export const CHECKOUT_TYPE_GIFTBOX_REDEEM = 'redeem';

export const productTypes = {
  initial: '',
  subscription: 'subscription',
  subscriptionTrial: 'trial',
  giftboxBuy: 'giftbox',
  giftboxRedeem: 'redeem',
};

export type ProductType = $Values<typeof productTypes>;
export type ProductTypes = $Values<typeof productTypes>;

export type Message = {
  name: string,
  email: string,
  recipient_name: string,
  text: string,
  send_date: string,
};

export type CheckoutStep = {
  index: number,
  location: string,
  label: string,
  key: string,
};

export type Checkout = {
  productType: ProductTypes,
  currentStep: number,
  steps: CheckoutStep[],
  addressDeliveryId?: number,
  addressBillingId?: number,
  sourceId?: number,
  planId?: number,
  servingOptionId?: number,
  firstDeliveryDate?: number | string,
  interval?: number,
  quantity?: number,
  isNewCustomer?: boolean,
  hasAlternateBilling?: boolean,
  discountVoucherCode?: string,
  discountVoucherValid?: boolean,
  discountVoucherAmount?: number,
  discountVoucherDeliveries?: number,
  giftBoxVoucherCode?: string,
  isSubscription?: boolean,
  isDeliveryOnly?: boolean,
  isTrial?: boolean,
  message?: Message,
  hasOnlyBillingAddress: boolean,
  addresses: {
    delivery?: Address,
    billing?: Address,
  },
  paymentType?: string,
  paymentBrainTreeClientToken?: string,
  paymentSource: {
    nonce?: string,
    token?: {
      livemode: boolean,
      id: string,
    },
    card?: {
      //id: 7,
      brand: string,
      exp_month: string,
      exp_year: string,
      last4: string,
      name: string,
    },
  },
};

export const checkoutSteps: CheckoutStep[] = [
  {
    index: 0,
    location: 'anmeldung',
    label: 'Anmeldung',
    key: 'auth',
  },
  {
    index: 1,
    location: 'lieferung',
    label: 'Lieferung',
    key: 'address',
  },
  {
    index: 2,
    location: 'bezahlung',
    label: 'Bezahlung',
    key: 'payment',
  },
  {
    index: 3,
    location: 'ueberblick',
    label: 'Bestätigung',
    key: 'confirmation',
  },
  {
    index: 4,
    location: 'success',
    label: 'Erfolg',
    key: 'success',
  },
];

const initialState: Checkout = {
  currentStep: 0,
  steps: checkoutSteps,
  addresses: {},
  hasOnlyBillingAddress: false,
  paymentType: '',
  productType: '',
  paymentSource: {},
};

const setAddress = (addresses = {}, addressType, address) => ({
  ...addresses,
  [addressType]: address,
});

const setAddressField = (addresses = {}, addressType, field, value) => ({
  ...addresses,
  [addressType]: {
    ...addresses[addressType],
    [field]: value,
  },
});

const setPaymentSourceStripe = action => {
  const payload = action.payload;
  if (!payload) {
    return {};
  }

  return {
    token: {
      livemode: payload.livemode,
      id: payload.id,
    },
    card: {
      //id: 7,
      brand: payload.card.brand,
      exp_month: payload.card.exp_month,
      exp_year: payload.card.exp_year,
      last4: payload.card.last4,
      name: payload.card.name,
    },
  };
};

export default (state: Checkout = initialState, action: any) => {
  switch (action.type) {
    case USER_REGISTER_SUCCESS: {
      return {
        ...state,
        loginRegister: true,
      };
    }
    case CHECKOUT_SET_STEP: {
      const nextStep = state.steps.find(s => s.key === action.payload);
      return {
        ...state,
        currentStep: nextStep ? nextStep.index : 0,
      };
    }
    case CHECKOUT_SET_ADDRESS_DELIVERY_ID: {
      return {
        ...state,
        addressDeliveryId: action.payload,
      };
    }
    case CHECKOUT_SET_ADDRESS_BILLING_ID: {
      return {
        ...state,
        addressBillingId: action.payload,
      };
    }
    case CHECKOUT_SET_ADDRESS: {
      return {
        ...state,
        addresses: setAddress(
          state.addresses,
          action.payload.addressType,
          action.payload.address
        ),
      };
    }
    case CHECKOUT_SET_ADDRESS_FIELD: {
      const addressType = action.payload.addressType;
      return {
        ...state,
        [addressType]: setAddressField(state[addressType], action),
      };
    }
    case ADDRESSES_CHECKOUT_CREATE_DELIVERY_SUCCESS:
      return {
        ...state,
        addressDeliveryId: action.payload.id,
      };
    case ADDRESSES_CHECKOUT_CREATE_BILLING_SUCCESS:
      return {
        ...state,
        addressBillingId: action.payload.id,
      };
    case CHECKOUT_SET_HAS_ALTERNATE_BILLING: {
      return {
        ...state,
        hasAlternateBilling: !state.hasAlternateBilling,
      };
    }
    case CHECKOUT_SET_DELIVERY_DATE:
      return {
        ...state,
        firstDeliveryDate: action.payload,
      };
    case CHECKOUT_SET_PLAN:
      return {
        ...state,
        planId: action.payload,
      };
    case CHECKOUT_SET_INTERVAL:
      return {
        ...state,
        interval: parseInt(action.payload, 10),
      };
    case CHECKOUT_SET_FIELDS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case CHECKOUT_SET_SERVING_OPTION: {
      return {
        ...state,
        servingOptionId: action.payload,
      };
    }
    case CHECKOUT_START:
      return {
        ...initialState,
        isNewCustomer: action.payload === false,
        currentStep: action.payload ? 1 : 0,
      };
    case CHECKOUT_START_REDEEM:
      return {
        ...initialState,
        ...action.payload,
        isNewCustomer: action.payload.isNewCustomer,
        steps: checkoutSteps.filter(s => s.index !== 2),
        currentStep: action.payload.isNewCustomer ? 0 : 1,
      };
    case CHECKOUT_SET_SOURCE_ID: {
      return {
        ...state,
        sourceId: action.payload,
      };
    }
    case CHECKOUT_SOURCE_CREATE_SUCCESS: {
      return {
        ...state,
        sourceId: action.payload.id,
      };
    }
    case CHECKOUT_SET_DISCOUNT_VOUCHER_CODE: {
      return {
        ...state,
        discountVoucherCode: action.payload,
      };
    }
    case VOUCHER_DISCOUNT_SUCCESS: {
      return {
        ...state,
        discountVoucherCode: action.payload.code,
        discountVoucherAmount: parseFloat(action.payload.discount_amount),
        discountVoucherValid: action.payload.valid,
        discountVoucherDeliveries: action.payload.number_deliveries,
      };
    }
    case VOUCHER_DISCOUNT_FAILURE: {
      return {
        ...state,
        discountVoucherValid: false,
      };
    }
    case CHECKOUT_RESET_VOUCHER: {
      return {
        ...state,
        discountVoucherCode: undefined,
        discountVoucherAmount: undefined,
        discountVoucherValid: undefined,
      };
    }
    case CHECKOUT_SET_PAYMENT_CARD: {
      return {
        ...state,
        paymentSource: {
          card: action.payload,
        },
      };
    }
    case CHECKOUT_SET_PAYMENT_SOURCE: {
      return {
        ...state,
        paymentSource: setPaymentSourceStripe(action.payload),
      };
    }
    case CHECKOUT_REMOVE_PAYMENT_SOURCE: {
      return {
        ...state,
        paymentSource: {},
      };
    }
    case SOURCE_PAYPAL_CLIENT_TOKEN_SUCCESS: {
      return {
        ...state,
        paymentType: 'paypal',
        paymentBrainTreeClientToken: action.payload.client_token,
      };
    }
    case CHECKOUT_SET_PAYMENT_TYPE: {
      return {
        ...state,
        paymentType: action.payload,
        paymentSource: {},
        paymentBrainTreeClientToken: undefined,
      };
    }
    case CHECKOUT_SET_BRAIN_TREE_NONCE: {
      return {
        ...state,
        paymentSource: {
          nonce: action.payload.nonce,
        },
      };
    }
    case CHECKOUT_SUBMIT:
      return {
        productType: state.productType,
        steps: checkoutSteps,
        addresses: {},
      };
    default:
      return state;
  }
};
