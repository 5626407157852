import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoginSignUpForm from '../../components/forms/LoginSignUpForm';
import { registerUser } from '../../actions/user';
import { Link } from 'react-router-dom';
import { ROUTE_DASHBOARD, ROUTE_LOGIN } from '../../constants/routes';
import {
  BUTTON_CREATE_ACCOUNT,
  FORM_LOGIN_LABEL_EMAIL,
  FORM_LOGIN_LABEL_PASSWORD,
} from '../../constants/constants';

export class Register extends Component {
  render() {
    const { registerUser } = this.props;

    return (
      <div className="form form__register">
        <LoginSignUpForm
          handleFormSubmit={registerUser}
          redirect={ROUTE_DASHBOARD}
          labels={{
            email: FORM_LOGIN_LABEL_EMAIL,
            password: FORM_LOGIN_LABEL_PASSWORD,
            submit: BUTTON_CREATE_ACCOUNT,
          }}
        />
        <p>
          Du hast bereits einen Account? Dann kannst du dich{' '}
          <Link to={ROUTE_LOGIN}>hier</Link> anmelden.
        </p>
      </div>
    );
  }
}

export default connect(
  () => ({}),
  {
    registerUser,
  }
)(Register);
