// @flow

import * as React from 'react';
import './FormControl.css';

type Props = {
  id: string,
  onChange?: Function,
  label?: string,
  value: string,
  onClick?: Function,
  onBlur?: Function,
  disabled: boolean,
  readonly?: boolean,
  required?: boolean,
  autoFocus?: boolean,
  fullWidth?: boolean,
  placeholder?: string,
  error?: string,
  hasError?: boolean,
  helperText?: ?string,
  type?: string,
  inputRef?: any,
};

export default function({
  id,
  label,
  value,
  onClick,
  onChange,
  onBlur,
  disabled = false,
  readonly = false,
  autoFocus = false,
  fullWidth = false,
  required = false,
  placeholder = '',
  helperText,
  error = '',
  hasError = false,
  type = 'text',
  inputRef,
}: Props) {
  return (
    <div className={label && 'form-control__group'}>
      {label && (
        <label className="form-control__label" htmlFor={`${id}`}>
          {label}
        </label>
      )}

      <input
        type={type}
        id={id}
        value={value}
        readOnly={readonly || !onChange}
        disabled={disabled === true}
        autoFocus={autoFocus}
        placeholder={placeholder}
        onClick={onClick ? ev => onClick(ev) : undefined}
        onChange={onChange ? ev => onChange(ev) : undefined}
        onBlur={onBlur ? ev => onBlur(ev) : undefined}
        className={[
          'form-control form-control--border',
          fullWidth ? 'form-control--fullWidth' : undefined,
          error || hasError ? 'form-control--error' : undefined,
        ].join(' ')}
        required={required !== undefined}
        ref={inputRef ? inputRef : undefined}
      />

      {helperText &&
        helperText !== '' && (
          <div className="form-control__text">{helperText}</div>
        )}

      {error &&
        error !== '' && <div className="form-control__error">{error}</div>}
    </div>
  );
}
