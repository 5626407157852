import { CALL_API, fetchApi } from '../middleware/api';
import { DIALOG_CLOSE } from '../constants/actionTypes';
import { loadDeliveries } from './deliveries';
import { appChangeLocation } from './router';
import { loadAddresses } from './address';
import { loadPaymentsSources } from './payment';
import { setSnackbarOpen } from './snackbar';

// subscriptions index request
export const SUBSCRIPTIONS_REQUEST = 'SUBSCRIPTIONS_REQUEST';
export const SUBSCRIPTIONS_SUCCESS = 'SUBSCRIPTIONS_SUCCESS';
export const SUBSCRIPTIONS_FAILURE = 'SUBSCRIPTIONS_FAILURE';

const fetchSubscriptions = (update = false) => {
  let endpoint = '/api/v1/subscriptions/';

  return {
    type: 'SUBSCRIPTIONS_REQUEST_START',
    [CALL_API]: {
      types: [
        SUBSCRIPTIONS_REQUEST,
        SUBSCRIPTIONS_SUCCESS,
        SUBSCRIPTIONS_FAILURE,
      ],
      endpoint,
    },
  };
};

export const loadSubscriptions = (update = false) => dispatch =>
  dispatch(fetchSubscriptions(update));

// subscriptions index request
export const SUBSCRIPTION_REQUEST = 'SUBSCRIPTION_REQUEST';
export const SUBSCRIPTION_SUCCESS = 'SUBSCRIPTION_SUCCESS';
export const SUBSCRIPTION_FAILURE = 'SUBSCRIPTION_FAILURE';

const fetchSubscription = subscriptionId => ({
  type: 'SUBSCRIPTION_REQUEST_START',
  [CALL_API]: {
    types: [SUBSCRIPTION_REQUEST, SUBSCRIPTION_SUCCESS, SUBSCRIPTION_FAILURE],
    endpoint: `/api/v1/subscriptions/${subscriptionId}/`,
  },
});

export const loadSubscriptionById = subscriptionId => dispatch =>
  dispatch(fetchSubscription(subscriptionId));

// subscriptions delete
export const SUBSCRIPTIONS_DELETE_REQUEST = 'SUBSCRIPTIONS_DELETE_REQUEST';
export const SUBSCRIPTIONS_DELETE_SUCCESS = 'SUBSCRIPTIONS_DELETE_SUCCESS';
export const SUBSCRIPTIONS_DELETE_FAILURE = 'SUBSCRIPTIONS_DELETE_FAILURE';

const fetchDeleteSubscription = (id, redirect, postAction) => ({
  type: 'SUBSCRIPTION_DELETE_START',
  [CALL_API]: {
    types: [
      SUBSCRIPTIONS_DELETE_REQUEST,
      SUBSCRIPTIONS_DELETE_SUCCESS,
      SUBSCRIPTIONS_DELETE_FAILURE,
    ],
    endpoint: `/api/v1/subscriptions/${id}/`,
    method: 'DELETE',
    payload: { id },
    redirect,
    postAction,
  },
});

export const deleteSubscription = (id, redirect, postAction) => dispatch =>
  dispatch(fetchDeleteSubscription(id, redirect, postAction));

// subscription pause
export const SUBSCRIPTIONS_PAUSE_REQUEST = 'SUBSCRIPTIONS_PAUSE_REQUEST';
export const SUBSCRIPTIONS_PAUSE_SUCCESS = 'SUBSCRIPTIONS_PAUSE_SUCCESS';
export const SUBSCRIPTIONS_PAUSE_FAILURE = 'SUBSCRIPTIONS_PAUSE_FAILURE';

export const pauseSubscription = id => async (dispatch, getState) => {
  dispatch({ type: SUBSCRIPTIONS_PAUSE_REQUEST });

  try {
    const data = {
      status: 'PAUSED',
    };

    const payload = await fetchApi({
      endpoint: `/api/v1/subscriptions/${id}/`,
      method: 'PATCH',
      authorization: getState().app.accessToken,
      payload: data,
    });

    dispatch({ type: SUBSCRIPTIONS_PAUSE_SUCCESS, payload });
    return Promise.resolve(payload)
      .then(() => dispatch(loadDeliveries()))
      .then(() => dispatch({ type: DIALOG_CLOSE }));
  } catch (err) {
    dispatch({ type: SUBSCRIPTIONS_PAUSE_FAILURE, payload: err });
    return Promise.reject(err);
  }
};

// subscription resume
export const SUBSCRIPTIONS_RESUME_REQUEST = 'SUBSCRIPTIONS_RESUME_REQUEST';
export const SUBSCRIPTIONS_RESUME_SUCCESS = 'SUBSCRIPTIONS_RESUME_SUCCESS';
export const SUBSCRIPTIONS_RESUME_FAILURE = 'SUBSCRIPTIONS_RESUME_FAILURE';

export const resumeSubscription = id => async (dispatch, getState) => {
  dispatch({ type: SUBSCRIPTIONS_RESUME_REQUEST });

  try {
    const data = {
      status: 'ACTIVE',
    };

    const payload = await fetchApi({
      endpoint: `/api/v1/subscriptions/${id}/`,
      method: 'PATCH',
      authorization: getState().app.accessToken,
      payload: data,
    });

    dispatch({ type: SUBSCRIPTIONS_RESUME_SUCCESS, payload });
    return Promise.resolve(payload)
      .then(() => dispatch(loadDeliveries()))
      .then(() => dispatch({ type: DIALOG_CLOSE }));
  } catch (err) {
    dispatch({ type: SUBSCRIPTIONS_RESUME_FAILURE, payload: err });
    return Promise.reject(err);
  }
};

// subscriptions update
export const SUBSCRIPTIONS_UPDATE_REQUEST = 'SUBSCRIPTIONS_UPDATE_REQUEST';
export const SUBSCRIPTIONS_UPDATE_SUCCESS = 'SUBSCRIPTIONS_UPDATE_SUCCESS';
export const SUBSCRIPTIONS_UPDATE_FAILURE = 'SUBSCRIPTIONS_UPDATE_FAILURE';

export const updateSubscription = (id, data, redirect, postAction) => async (
  dispatch,
  getState
) => {
  dispatch({ type: SUBSCRIPTIONS_UPDATE_REQUEST });
  try {
    const payload = await fetchApi({
      endpoint: `/api/v1/subscriptions/${id}/`,
      method: 'PUT',
      authorization: getState().app.accessToken,
      payload: data,
    });

    return Promise.resolve(payload)
      .then(() => dispatch(loadDeliveries()))
      .then(() => (redirect ? dispatch(appChangeLocation(redirect)) : null))
      .then(() => (postAction ? dispatch(postAction) : null))
      .then(() => dispatch({ type: SUBSCRIPTIONS_UPDATE_SUCCESS, payload }));
  } catch (err) {
    console.log(err);
    dispatch({ type: SUBSCRIPTIONS_UPDATE_FAILURE });
    return Promise.reject(err);
  }
};

// subscriptions update
export const SUBSCRIPTIONS_UPDATE_ADDRESSES_AND_PAYMENT_REQUEST =
  'SUBSCRIPTIONS_UPDATE_ADDRESSES_AND_PAYMENT_REQUEST';
export const SUBSCRIPTIONS_UPDATE_ADDRESSES_AND_PAYMENT_SUCCESS =
  'SUBSCRIPTIONS_UPDATE_ADDRESSES_AND_PAYMENT_SUCCESS';
export const SUBSCRIPTIONS_UPDATE_ADDRESSES_AND_PAYMENT_FAILURE =
  'SUBSCRIPTIONS_UPDATE_ADDRESSES_AND_PAYMENT_FAILURE';

export const updateSubscriptionAddressesAndPayment = (id, data) => async (
  dispatch,
  getState
) => {
  dispatch({ type: SUBSCRIPTIONS_UPDATE_ADDRESSES_AND_PAYMENT_REQUEST });
  try {
    const payload = await fetchApi({
      endpoint: `/api/v1/subscriptions/payment/${id}/`,
      method: 'PUT',
      authorization: getState().app.accessToken,
      payload: data,
    });

    dispatch({
      type: SUBSCRIPTIONS_UPDATE_ADDRESSES_AND_PAYMENT_SUCCESS,
      payload,
    });

    dispatch(loadAddresses());
    dispatch(loadPaymentsSources());
    dispatch(setSnackbarOpen({ message: 'Abo erfolgreich geändert' }));

    return payload;
  } catch (err) {
    dispatch({ type: SUBSCRIPTIONS_UPDATE_ADDRESSES_AND_PAYMENT_FAILURE });
    dispatch(
      setSnackbarOpen({
        message: 'Fehler beim Ändern des Abos, bitte kontaktiere den Support.',
      })
    );
    return Promise.reject(err);
  }
};
