import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import {
  NAV_CALENDAR,
  NAV_FOOD_BOXES,
  NAV_INVOICES,
  NAV_LOGOUT,
  NAV_PROFILE,
} from '../../constants/constants';
import {
  ROUTE_DASHBOARD,
  ROUTE_DELIVERIES,
  ROUTE_LOGOUT,
  ROUTE_PROFILE,
  ROUTE_PROFILE_CALENDAR,
  ROUTE_PROFILE_INVOICES,
  ROUTE_SUBSCRIPTION,
} from '../../constants/routes';
import withWidth from '@material-ui/core/withWidth';
import { connect } from 'react-redux';
import { appChangeLocation } from '../../actions/router';

const styles = theme => ({
  item: {
    color: '#282828',
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
      paddingLeft: theme.spacing.unit * 2 + 1,
      borderLeft: '1px solid #e9e9e9',
      borderRight: '1px solid transparent',
      borderBottom: '1px solid #e9e9e9',
      marginRight: '-1px',
      '&:first-child': {
        borderTop: '1px solid #e9e9e9',
      },
      '&:hover': {
        paddingLeft: theme.spacing.unit * 2,
        borderLeft: `2px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
      },
    },
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing.unit * 2,
      borderRight: '1px solid #ffffff',
      borderLeft: `2px solid ${theme.palette.primary.main}`,
    },
  },
  mobileNavigation: {
    marginBottom: '1rem',
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
});

const dashboardIsActive = (match, location) => {
  if (!match || !location) {
    return false;
  }

  return (
    location.pathname === ROUTE_DELIVERIES ||
    location.pathname === ROUTE_DASHBOARD ||
    location.pathname.startsWith(ROUTE_SUBSCRIPTION)
  );
};

const links = [
  {
    url: ROUTE_DASHBOARD,
    text: NAV_FOOD_BOXES,
    isActive: dashboardIsActive,
  },
  {
    url: ROUTE_PROFILE_CALENDAR,
    text: NAV_CALENDAR,
  },
  {
    url: ROUTE_PROFILE_INVOICES,
    text: NAV_INVOICES,
  },
  {
    url: ROUTE_PROFILE,
    text: NAV_PROFILE,
  },
  {
    url: ROUTE_LOGOUT,
    text: NAV_LOGOUT,
  },
];

const SiteNavigation = ({ classes, width, location, appChangeLocation }) =>
  width === 'xs' ? (
    <select
      value={location}
      onChange={ev => appChangeLocation(ev.target.value)}
      className={`form-control form-control--border form-control--fullWidth ${
        classes.mobileNavigation
      }`}
    >
      {links.map(l => (
        <option value={l.url} key={`mobileNav${l.text}`}>
          {l.text}
        </option>
      ))}
    </select>
  ) : (
    links.map(l => (
      <NavLink
        to={l.url}
        className={classes.item}
        activeClassName={classes.active}
        isActive={l.isActive ? l.isActive : undefined}
        key={`nav${l.text}`}
      >
        {l.text}
      </NavLink>
    ))
  );

const mapStateToProps = state => ({
  location: state.router.location.pathname,
});

export default connect(
  mapStateToProps,
  { appChangeLocation }
)(withStyles(styles)(withWidth()(SiteNavigation)));
