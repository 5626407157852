import React from 'react';

import { connect } from 'react-redux';
import {
  checkoutCreateAddress,
  checkoutSetDeliveryDate,
  checkoutSetDialogClose,
  checkoutSetFields,
} from '../../actions/checkout';
import { withStyles } from '@material-ui/core/styles';
import AddressFormDialog from '../../components/forms/AddressFormDialog';
import { createAddress, updateAddress } from '../../actions/address';

const styles = theme => ({
  dialogContent: {
    paddingLeft: theme.spacing.unit * 6,
    paddingRight: theme.spacing.unit * 6,
    paddingBottom: theme.spacing.unit * 2,
    paddingTop: 0,
  },
  dialogActions: {
    paddingTop: theme.spacing.unit * 2,
  },
  formControl: {
    marginTop: theme.spacing.unit * 1,
    marginBottom: theme.spacing.unit * 2,
  },
  buttonBlock: {
    display: 'block',
    width: '100%',
  },
});

class CheckoutAddressDialog extends React.Component {
  state = {
    planId: null,
    servingOptionId: null,
    firstDeliveryDate: null,
    interval: null,
  };

  setProps(props) {
    const {
      planId,
      servingOptionId,
      firstDeliveryDate,
      interval,
    } = props.checkout;

    this.setState({
      planId,
      servingOptionId,
      firstDeliveryDate,
      interval,
    });
  }

  componentWillMount() {
    this.setProps(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.setProps(nextProps);
  }

  render() {
    const {
      dialog,
      addresses,
      checkoutCreateAddress,
      updateAddress,
    } = this.props;
    const address = dialog.targetId
      ? addresses.find(a => a.id === dialog.targetId)
      : null;

    return (
      <AddressFormDialog
        address={address}
        addressType={dialog.targetType}
        handleCreate={checkoutCreateAddress}
        handleUpdate={updateAddress}
        onClose={this.props.checkoutSetDialogClose}
      />
    );
  }
}

const mapStateToProps = state => ({
  addresses: state.addresses,
  checkout: state.checkout,
  dialog: state.dialog,
  plans: state.plan,
});

export default connect(
  mapStateToProps,
  {
    checkoutSetDialogClose,
    checkoutSetDeliveryDate,
    checkoutSetFields,
    createAddress,
    checkoutCreateAddress,
    updateAddress,
  }
)(withStyles(styles)(CheckoutAddressDialog));
