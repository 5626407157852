// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { passwordResetConfirm } from '../../actions/user';
import {
  BUTTON_PW_RESET_CONFIRM,
  FORM_LOGIN_LABEL_PASSWORD,
  FORM_LOGIN_LABEL_PASSWORD_REPEAT,
} from '../../constants/constants';
import InputField from '../../components/inputs/InputField';
import Button from '../../components/inputs/Button';
import { user } from '../../constants/messages';

type Props = {
  passwordResetConfirm: Function,
  loading: boolean,
  uid: string,
  token: string,
};

type State = {
  hasError: boolean,
  errorMessage: string,
  password: string,
  passwordRepeat: string,
};

export class PasswordReset extends React.Component<Props, State> {
  state = {
    hasError: false,
    errorMessage: '',
    password: '',
    passwordRepeat: '',
  };

  handleChange = (name: string) => (event: any) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSubmit = async (event: any) => {
    event.preventDefault();
    const { password, passwordRepeat } = this.state;

    if (password === '' || passwordRepeat === '') {
      this.setState({
        hasError: true,
      });
      return;
    }

    if (password !== passwordRepeat) {
      this.setState({
        hasError: true,
        errorMessage: 'Die Passwörter müssen identisch sein',
      });
      return;
    }

    const { uid, token } = this.props;

    try {
      await this.props.passwordResetConfirm({
        new_password: this.state.password,
        uid,
        token,
      });
    } catch (err) {
      // todo: get error messages
      this.setState({
        hasError: true,
        errorMessage: user.GENERAL_ERROR,
      });
    }
  };

  render() {
    const { uid, token, loading } = this.props;
    const { hasError, errorMessage } = this.state;

    if (!uid && !token) {
      return null;
    }

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form__group">
          <InputField
            fullWidth
            type={'password'}
            hasError={hasError}
            label={FORM_LOGIN_LABEL_PASSWORD}
            placeholder={FORM_LOGIN_LABEL_PASSWORD}
            value={this.state.password}
            onChange={this.handleChange('password')}
            id={'passwort-reset'}
            disabled={loading}
          />
        </div>
        <div className="form__group">
          <InputField
            fullWidth
            type={'password'}
            hasError={hasError}
            label={FORM_LOGIN_LABEL_PASSWORD_REPEAT}
            placeholder={FORM_LOGIN_LABEL_PASSWORD_REPEAT}
            value={this.state.passwordRepeat}
            onChange={this.handleChange('passwordRepeat')}
            id={'passwort-reset-wiederholung'}
            disabled={loading}
          />
        </div>

        {errorMessage && errorMessage !== '' ? (
          <p className="error__text">{errorMessage}</p>
        ) : null}

        <Button type={'submit'} variant="raised" color="primary">
          {BUTTON_PW_RESET_CONFIRM}
        </Button>
      </form>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isLoading: state.isLoading,
  ...ownProps.match.params,
});

export default connect(
  mapStateToProps,
  {
    passwordResetConfirm,
  }
)(PasswordReset);
