// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import { loadSubscriptions } from '../../actions/subscription';
import { setDialogOpen } from '../../actions/dialog';
import Calendar from '../../components/inputs/Calendar';
import { dateFormatDaysIso } from '../../utils/dateHelper';
import type { Delivery, Subscription } from '../../types';
import { loadDeliveries } from '../../actions/deliveries';

type Props = {
  setDialogOpen: Function,
  loadSubscriptions: Function,
  loadDeliveries: Function,
  deliveries: Delivery[],
  subscriptions: Subscription[],
};

type State = {
  tabIndex: number,
};

class ProfileTabList extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
    };
  }

  componentDidMount() {
    this.props.loadSubscriptions();
    this.props.loadDeliveries();
  }

  handleChange = (event, tabIndex) => {
    this.setState({ tabIndex });
  };

  handleDayClick = (date: Date) => {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    const { tabIndex } = this.state;
    const { subscriptions, deliveries } = this.props;

    const selectedSubscription: Subscription = subscriptions[tabIndex];
    const match: ?Delivery = deliveries.find(
      d =>
        d.delivery_date === dateFormatDaysIso(date) &&
        d.subscription === selectedSubscription.id
    );

    if (!match) {
      return;
    }

    this.props.setDialogOpen({
      target: 'delivery',
      targetId: match.id,
    });
  };

  render() {
    const { tabIndex } = this.state;
    const { subscriptions, deliveries } = this.props;
    if (subscriptions.length === 0 || !subscriptions || !deliveries) {
      return <p>Du hast derzeit kein aktives kein Abo.</p>;
    }

    const selectedSubscription: Subscription = subscriptions[tabIndex];
    const activeDeliveries: Date[] = deliveries
      .filter(d => d.status === 'OPEN')
      .filter(d => d.subscription === selectedSubscription.id)
      .map(s => new Date(s.delivery_date + 'T00:00:00'));

    const pausedDeliveries: Date[] = deliveries
      .filter(d => d.status === 'PAUSED')
      .filter(d => d.subscription === selectedSubscription.id)
      .map(s => new Date(s.delivery_date));

    const deliveryDeliveries: Date[] = deliveries
      .filter(d => d.status === 'DELIVERY' || d.status === 'PROCESS')
      .filter(d => d.subscription === selectedSubscription.id)
      .map(s => new Date(s.delivery_date));

    return (
      <div>
        <AppBar
          position="static"
          color="default"
          style={{ marginBottom: '1rem' }}
        >
          <Tabs value={tabIndex} onChange={this.handleChange}>
            {this.props.subscriptions.map((s: Subscription) => (
              <Tab key={s.id} label={`Abo ${s.plan_title}`} />
            ))}
          </Tabs>
        </AppBar>

        <Calendar
          key={`calendar_for_${selectedSubscription.id}`}
          disabled={false}
          subscriptionId={selectedSubscription.id}
          onClick={this.handleDayClick}
          allowedDateRange={activeDeliveries}
          pausedDateRange={pausedDeliveries}
          deliveryDateRange={deliveryDeliveries}
          numberOfMonths={2}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  app: state.app,
  user: state.user,
  history: ownProps.history,
  subscriptions: state.subscriptions,
  deliveries: state.deliveries,
});

export default connect(
  mapStateToProps,
  {
    loadSubscriptions,
    loadDeliveries,
    setDialogOpen,
  }
)(ProfileTabList);
