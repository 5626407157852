// @flow

import * as React from 'react';
import './CheckoutSubmit.css';
import CheckoutPriceBox from './CheckoutPriceBox';
import {
  CHECKOUT_TYPE_GIFTBOX_REDEEM,
  CHECKOUT_TYPE_SUBSCRIPTION,
  CHECKOUT_TYPE_SUBSCRIPTION_TRIAL,
} from '../../redux/checkout';
import {
  CHECKOUT_PRICE_INFO,
  CTA_CHECKOUT,
  CTA_CHECKOUT_SUBSCRIPTION,
  CTA_CHECKOUT_SUBSCRIPTION_TRIAL,
  CTA_CHECKOUT_TYPE_GIFTBOX_REDEEM,
} from '../../constants/constants';
import Button from '../inputs/Button';
import BrainTreePaymentButton from '../../containers/payment/BrainTreePaymentButton';
import { paymentTypes } from '../../types/payment';
import type { ProductType } from '../../redux/checkout';
import type { PaymentType } from '../../types/payment';

const getSubmitButtonTitle = productType => {
  switch (productType) {
    case CHECKOUT_TYPE_SUBSCRIPTION:
      return CTA_CHECKOUT_SUBSCRIPTION;
    case CHECKOUT_TYPE_SUBSCRIPTION_TRIAL:
      return CTA_CHECKOUT_SUBSCRIPTION_TRIAL;
    case CHECKOUT_TYPE_GIFTBOX_REDEEM:
      return CTA_CHECKOUT_TYPE_GIFTBOX_REDEEM;
    default:
      return CTA_CHECKOUT;
  }
};

type Props = {
  title: string,
  paymentType: PaymentType,
  productType: ProductType,
  price: any,
  action: any,
  paymentBrainTreeClientToken: string,
  paymentBrainTreeBillingAgreement?: string,
  hasPrice?: boolean,
  disabled?: boolean,
};

const CheckoutSubmit = ({
  title,
  productType,
  price,
  action,
  paymentType,
  paymentBrainTreeClientToken,
  paymentBrainTreeBillingAgreement,
  disabled = false,
  hasPrice = false,
}: Props) => {
  return (
    <div className="checkoutSubmit">
      {hasPrice ? (
        <CheckoutPriceBox
          productType={productType}
          title={title}
          price={price}
        />
      ) : null}

      {paymentType === paymentTypes.paypal ? (
        <BrainTreePaymentButton
          price={price}
          paymentBrainTreeClientToken={paymentBrainTreeClientToken}
          productType={productType}
          paymentType={paymentType}
          billingAgreementDescription={paymentBrainTreeBillingAgreement}
        />
      ) : (
        <Button
          variant="promo"
          color="primary"
          fullWidth
          onClick={action}
          disabled={disabled === true}
        >
          {getSubmitButtonTitle(productType)}
        </Button>
      )}

      {hasPrice && (
        <small className="checkoutSubmit__price">{CHECKOUT_PRICE_INFO}</small>
      )}
    </div>
  );
};

export default CheckoutSubmit;
