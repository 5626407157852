import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import './CheckoutVoucherBox.css';
import TextField from '../inputs/TextField';

class CheckoutVoucherBox extends Component {
  state = {
    value: '',
    dirtyState: false,
  };

  handleValueChange = event => {
    const { value } = event.target;

    if (value === '') {
      this.props.checkoutClearVoucher();
    }

    this.setState({ value });
  };

  handleCheckVoucher = event => {
    event.preventDefault();
    const { value } = this.state;
    if (value === '') {
      this.props.checkoutClearVoucher();
    } else {
      this.props.checkoutCheckVoucher(value);
    }
  };

  handleClickCheck = event => this.handleCheckVoucher(event);

  handleClickClear = () => {
    this.props.checkoutClearVoucher();
    this.setState({
      value: '',
    });
  };

  componentWillMount() {
    const { checkout } = this.props;

    if (
      typeof checkout.discountVoucherCode === 'string' &&
      checkout.discountVoucherCode !== ''
    ) {
      this.setState({
        value: checkout.discountVoucherCode,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { discountVoucherValid } = nextProps.checkout;
    const discountVoucherValidDidChange =
      discountVoucherValid !== this.props.checkout.discountVoucherValid;

    if (discountVoucherValid === false && discountVoucherValidDidChange) {
      this.setState({ value: '' });
    }
  }

  renderControls() {
    const { value } = this.state;

    const { discountVoucherValid } = this.props.checkout;

    if (discountVoucherValid === true) {
      return (
        <span className="link link--gutter" onClick={this.handleClickClear}>
          Gutscheincode entfernen
        </span>
      );
    }

    if (value && value.length > 0) {
      return (
        <span className="link link--gutter" onClick={this.handleClickCheck}>
          Gutscheincode einlösen
        </span>
      );
    }

    return null;
  }

  renderErrorMessage() {
    if (this.props.checkout.discountVoucherValid === false) {
      return (
        <p className="error__text">
          Der Rabattcode ist leider nicht gültig. Versichere dich, dass du den
          richtigen Rabattcode verwendest.
        </p>
      );
    }
  }

  render() {
    const { value } = this.state;
    const { discountVoucherValid } = this.props.checkout;

    return (
      <div>
        <div className="voucherBox__title">Rabattcode</div>
        <form
          onSubmit={event => this.handleCheckVoucher(event)}
          className="checkout-voucher"
        >
          <FormControl fullWidth>
            <TextField
              readonly={discountVoucherValid === true}
              fullWidth
              placeholder={'Gutscheincode eingeben'}
              value={value}
              onChange={this.handleValueChange}
            />
          </FormControl>
          {this.renderControls()}
        </form>
        {this.renderErrorMessage()}
      </div>
    );
  }
}

CheckoutVoucherBox.propTypes = {
  checkout: PropTypes.object.isRequired,
  checkoutCheckVoucher: PropTypes.func.isRequired,
  checkoutClearVoucher: PropTypes.func.isRequired,
};

export default CheckoutVoucherBox;
