import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import StripeCheckout from '../../components/stripe/StripeCheckout';
import {
  // checkoutSetCreateSource,
  checkoutSetDialogClose,
  checkoutSetSourceId,
  checkoutSetSourceError,
  checkoutCreateCard,
} from '../../actions/checkout';
import {
  checkoutCreateSource,
  createPaymentsSource,
  createStripeCardFailure,
  createStripeCardRequest,
  createStripeCardSuccess,
} from '../../actions/payment';

import { DialogTitle } from '@material-ui/core/Dialog';

class CheckoutPaymentDialog extends Component {
  render() {
    return (
      <Fragment>
        <DialogTitle>Zahlungsmethode</DialogTitle>
        <StripeCheckout
          checkoutCreateSource={this.props.checkoutCreateSource}
          checkoutSetSourceId={this.props.checkoutSetSourceId}
          checkoutSetSourceError={this.props.checkoutSetSourceError}
          createPaymentsSource={this.props.createPaymentsSource}
          closeDialog={this.props.checkoutSetDialogClose}
          checkoutCreateCard={this.props.checkoutCreateCard}
          createStripeCardRequest={this.props.createStripeCardRequest}
          createStripeCardSuccess={this.props.createStripeCardSuccess}
          createStripeCardFailure={this.props.createStripeCardFailure}
          loading={this.props.loading}
        />
      </Fragment>
    );
  }
}

CheckoutPaymentDialog.propTypes = {
  dialog: PropTypes.object.isRequired,
  payments: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  dialog: state.dialog,
  payments: state.payments,
  loading: state.loading,
});

export default connect(
  mapStateToProps,
  {
    createStripeCardRequest,
    createStripeCardSuccess,
    createStripeCardFailure,
    createPaymentsSource,
    checkoutCreateCard,
    checkoutSetDialogClose,
    checkoutSetSourceId,
    checkoutCreateSource,
    checkoutSetSourceError,
  }
)(CheckoutPaymentDialog);
