import productImageSeason from '../static/images/products/season.jpg';
import productImageVegi from '../static/images/products/vegi.jpg';
import iconVisa from '../static/images/cards/visa.svg';
import iconAmericanExpress from '../static/images/cards/americanexpress.svg';
import iconMastercard from '../static/images/cards/mastercard.svg';
import iconPaypal from '../static/images/cards/paypal.svg';
import { paymentTypes } from '../types/payment';

const toNumber = s => {
  const f = parseFloat(s);
  return isNaN(f) ? null : f;
};

export const currencyFormat = n => {
  const value = toNumber(n) || n;

  return typeof value === 'number'
    ? `CHF ${value.toFixed(2).replace('.', ',')}`
    : n;
};

export const creditCardFormatter = card =>
  `XXXX XXXX XXXX ${card.last4} ${card.exp_month}/${card.exp_year}`;

export const getProductImage = productId =>
  productId === 1 ? productImageSeason : productImageVegi;

export const getCreditCardImage = cardType => {
  switch (cardType) {
    case 'MasterCard': {
      return iconMastercard;
    }
    case 'American Express': {
      return iconAmericanExpress;
    }
    default:
      return iconVisa;
  }
};

export const getPaymentIcon = cardType => {
  switch (cardType) {
    case paymentTypes.paypal:
      return { src: iconPaypal, height: 40, width: 144 };
    case 'mastercard': {
      return { src: iconMastercard, height: 40, width: 64 };
    }
    case 'american express': {
      return { src: iconAmericanExpress, height: 40, width: 64 };
    }
    case 'visa':
    default:
      return { src: iconVisa, height: 40, width: 64 };
  }
};
