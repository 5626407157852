import { CALL_API, fetchApi } from '../middleware/api';

export const PAYMENTS_STRIPE_REQUEST = 'PAYMENTS_STRIPE_REQUEST';
export const PAYMENTS_STRIPE_SUCCESS = 'PAYMENTS_STRIPE_SUCCESS';
export const PAYMENTS_STRIPE_FAILURE = 'PAYMENTS_STRIPE_FAILURE';

export const createStripeCardRequest = () => ({
  type: PAYMENTS_STRIPE_REQUEST,
});

export const createStripeCardSuccess = () => ({
  type: PAYMENTS_STRIPE_SUCCESS,
});

export const createStripeCardFailure = () => ({
  type: PAYMENTS_STRIPE_FAILURE,
});

// address index request
export const PAYMENTS_REQUEST = 'PAYMENTS_REQUEST';
export const PAYMENTS_SUCCESS = 'PAYMENTS_SUCCESS';
export const PAYMENTS_FAILURE = 'PAYMENTS_FAILURE';

const fetchPaymentsSources = () => ({
  type: 'PAYMENTS_REQUEST_START',
  [CALL_API]: {
    types: [PAYMENTS_REQUEST, PAYMENTS_SUCCESS, PAYMENTS_FAILURE],
    endpoint: `/api/v1/payment/sources/`,
  },
});

export const loadPaymentsSources = () => dispatch =>
  dispatch(fetchPaymentsSources());

// address create
export const PAYMENTS_CREATE_REQUEST = 'PAYMENTS_CREATE_REQUEST';
export const PAYMENTS_CREATE_SUCCESS = 'PAYMENTS_CREATE_SUCCESS';
export const PAYMENTS_CREATE_FAILURE = 'PAYMENTS_CREATE_FAILURE';

const fetchCreatePaymentsSource = (payment, postAction) => ({
  type: 'PAYMENTS_CREATE_START',
  [CALL_API]: {
    types: [
      PAYMENTS_CREATE_REQUEST,
      PAYMENTS_CREATE_SUCCESS,
      PAYMENTS_CREATE_FAILURE,
    ],
    endpoint: `/api/v1/payment/register/`,
    method: 'POST',
    payload: payment,
    postAction,
  },
});

export const createPaymentsSource = (payment, postAction) => dispatch =>
  dispatch(fetchCreatePaymentsSource(payment, postAction));

// Remove
export const PAYMENTS_DELETE_REQUEST = 'PAYMENTS_DELETE_REQUEST';
export const PAYMENTS_DELETE_SUCCESS = 'PAYMENTS_DELETE_SUCCESS';
export const PAYMENTS_DELETE_FAILURE = 'PAYMENTS_DELETE_FAILURE';

const fetchDeletePaymentSource = (sourceId, redirect, postAction) => ({
  type: 'PAYMENTS_DELETE_START',
  [CALL_API]: {
    types: [
      PAYMENTS_DELETE_REQUEST,
      PAYMENTS_DELETE_SUCCESS,
      PAYMENTS_DELETE_FAILURE,
    ],
    endpoint: `/api/v1/payment/sources/${sourceId}/`,
    method: 'DELETE',
    payload: sourceId,
    redirect,
    postAction,
  },
});

export const deletePaymentSource = (
  sourceId,
  redirect,
  postAction
) => dispatch =>
  dispatch(fetchDeletePaymentSource(sourceId, redirect, postAction));

// address create
export const CHECKOUT_SOURCE_CREATE_REQUEST = 'CHECKOUT_SOURCE_CREATE_REQUEST';
export const CHECKOUT_SOURCE_CREATE_SUCCESS = 'CHECKOUT_SOURCE_CREATE_SUCCESS';
export const CHECKOUT_SOURCE_CREATE_FAILURE = 'CHECKOUT_SOURCE_CREATE_FAILURE';

const checkoutFetchCreateSource = (payment, redirect, postAction) => ({
  type: 'PAYMENTS_CREATE_START',
  [CALL_API]: {
    types: [
      CHECKOUT_SOURCE_CREATE_REQUEST,
      CHECKOUT_SOURCE_CREATE_SUCCESS,
      CHECKOUT_SOURCE_CREATE_FAILURE,
    ],
    endpoint: `/api/v1/payment/register/`,
    method: 'POST',
    payload: payment,
    redirect,
    postAction,
  },
});

export const checkoutCreateSource = (
  payment,
  redirect,
  postAction
) => dispatch =>
  dispatch(checkoutFetchCreateSource(payment, redirect, postAction));

export const SOURCE_PAYPAL_CLIENT_TOKEN_REQUEST =
  'SOURCE_PAYPAL_CLIENT_TOKEN_REQUEST';
export const SOURCE_PAYPAL_CLIENT_TOKEN_SUCCESS =
  'SOURCE_PAYPAL_CLIENT_TOKEN_SUCCESS';
export const SOURCE_PAYPAL_CLIENT_TOKEN_FAILURE =
  'SOURCE_PAYPAL_CLIENT_TOKEN_FAILURE';

export const fetchPaypalClientToken = () =>
  fetchApi({
    endpoint: '/api/v1/btpayment/',
    method: 'GET',
  });

export const getPaypalClientToken = () => async (dispatch, getState) => {
  dispatch({
    type: SOURCE_PAYPAL_CLIENT_TOKEN_REQUEST,
  });

  try {
    const response = await fetchPaypalClientToken();

    dispatch({
      type: SOURCE_PAYPAL_CLIENT_TOKEN_SUCCESS,
      payload: response,
    });

    return response;
  } catch (err) {
    dispatch({
      type: SOURCE_PAYPAL_CLIENT_TOKEN_FAILURE,
    });
    // todo return error
    return {};
  }
};
