import React from 'react';
import Button from '@material-ui/core/Button';
import logo from '../../static/svg/logo.svg';
import './ViewGeneralErrorPage.css';
import PageFooter from '../layout/PageFooter';

const ViewGeneralErrorPage = ({ origin }) => (
  <div>
    <div className="page">
      <header className="page-header">
        <img className={'page-header__logo'} src={logo} alt="Juts.ch" />
      </header>

      <h3>Ooops, leider ist ein Fehler aufgetreten :-(</h3>
      <p>
        Bitte versuche es später noch einmal oder wende dich mit den folgenden
        Möglichkeiten an unseren Support:
      </p>

      <h4>Kontakt</h4>
      <p>Per Telefon: 052 722 11 44</p>
      <p>oder Email: support@juts.ch</p>

      <Button
        href={`https://www.juts.ch?errorRef=${origin ? origin : 'generic'}`}
        color={'primary'}
        variant={'raised'}
      >
        Zurück zu juts.ch
      </Button>
    </div>
    <PageFooter />
  </div>
);

export default ViewGeneralErrorPage;
