// @flow

import * as React from 'react';
import './Paper.css';

type Props = {
  children: React.Node,
  wide?: boolean,
  desktopOnly?: boolean,
  extraClassName?: string,
  extraClassNames?: string[],
};

export default function({
  children,
  wide = false,
  extraClassName = '',
  extraClassNames = [],
  desktopOnly = false,
}: Props) {
  let classes = ['paper'];

  if (wide) {
    classes.push('paper--wide');
  }

  if (desktopOnly) {
    classes.push('paper--desktopOnly');
  }

  if (extraClassName) {
    classes.push(extraClassName);
  }

  if (extraClassNames.length > 0) {
    classes = [...classes, extraClassNames];
  }

  return <div className={classes.join(' ')}>{children}</div>;
}
