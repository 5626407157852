import React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import './Progress.css';

const Progress = props => {
  const isLoading = Object.keys(props.loading).reduce(
    (prev, next) => prev || props.loading[next],
    false
  );

  return isLoading ? (
    <div className="progress">
      <LinearProgress />
    </div>
  ) : null;
};

Progress.propTypes = {
  loading: PropTypes.object.isRequired,
};

export default Progress;
