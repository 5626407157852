import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import {
  FORM_ADDRESS_LABEL_FIRST_NAME,
  FORM_ADDRESS_LABEL_LINE2,
  FORM_ADDRESS_LABEL_LAST_NAME,
  FORM_ADDRESS_LABEL_CITY,
  FORM_ADDRESS_LABEL_COUNTRY,
  FORM_ADDRESS_LABEL_STREET,
  FORM_ADDRESS_LABEL_ZIP,
  FORM_ADDRESS_LABEL_PHONE_NUMBER,
} from '../../constants/constants';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core/Dialog';

const styles = () => ({});

const countries = [{ value: 'Schweiz', label: 'Schweiz' }];

const isAddressValidated = ({ first_name, last_name, street, zip, city }) =>
  [first_name, last_name, street, city].every(item => item.length > 3) &&
  zip > 999 &&
  zip < 10000;

class AddressFormDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: '',
      last_name: '',
      line2: '',
      street: '',
      zip: '',
      city: '',
      phone_number: '',
      country: 'Schweiz',
      id: null,
    };
  }

  componentWillMount() {
    if (!this.props.address) {
      return;
    }

    const {
      id,
      first_name,
      last_name,
      line2,
      street,
      zip,
      city,
      phone_number,
    } = this.props.address;

    this.setState({
      first_name,
      last_name,
      line2,
      street,
      zip,
      city,
      phone_number,
      id,
    });
  }

  handleCreateAddress() {
    const { id, ...address } = this.state;

    id === null
      ? this.props.handleCreate(address, this.props.addressType)
      : this.props.handleUpdate(id, address, this.props.onClose);
  }

  handleInputStateChange = name => event => {
    if (name === 'zip' && event.target.value !== '') {
      event.target.value = parseInt(event.target.value, 10);
      if (event.target.value > 9999) {
        return;
      }
    }

    if (name === 'phone_number') {
      if (event.target.value.length > 16) {
        return;
      }

      event.target.value = event.target.value.trim();
    }

    this.setState({ [name]: event.target.value });
  };

  render() {
    const {
      first_name,
      last_name,
      line2,
      street,
      zip,
      city,
      country,
      phone_number,
    } = this.state;

    const submitAllowed = isAddressValidated(this.state);
    const title = this.state.id
      ? 'Addresse aktualisieren'
      : 'Adresse hinzufügen';

    return (
      <Fragment>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <form noValidate autoComplete="off" onSubmit={this.handleFormSubmit}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    id="firstName"
                    label={FORM_ADDRESS_LABEL_FIRST_NAME}
                    value={first_name}
                    onChange={this.handleInputStateChange('first_name')}
                    margin="normal"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    id="lastName"
                    label={FORM_ADDRESS_LABEL_LAST_NAME}
                    value={last_name}
                    onChange={this.handleInputStateChange('last_name')}
                    margin="normal"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <FormControl fullWidth>
              <TextField
                id="line2"
                label={FORM_ADDRESS_LABEL_LINE2}
                value={line2}
                onChange={this.handleInputStateChange('line2')}
                margin="normal"
              />
            </FormControl>

            <Grid container>
              <Grid item xs={12} sm={8}>
                <FormControl fullWidth>
                  <TextField
                    id="street"
                    label={FORM_ADDRESS_LABEL_STREET}
                    value={street}
                    onChange={this.handleInputStateChange('street')}
                    margin="normal"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <TextField
                    type="number"
                    id="zip"
                    label={FORM_ADDRESS_LABEL_ZIP}
                    value={zip}
                    onChange={this.handleInputStateChange('zip')}
                    margin="normal"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    id="city"
                    label={FORM_ADDRESS_LABEL_CITY}
                    value={city}
                    onChange={this.handleInputStateChange('city')}
                    margin="normal"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    select
                    label={FORM_ADDRESS_LABEL_COUNTRY}
                    value={country}
                    onChange={this.handleInputStateChange('country')}
                    SelectProps={{
                      native: true,
                    }}
                    // helperText="Please select your country"
                    margin="normal"
                  >
                    {countries.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label={FORM_ADDRESS_LABEL_PHONE_NUMBER}
                    value={phone_number}
                    onChange={this.handleInputStateChange('phone_number')}
                    helperText="+41123456789"
                    margin="normal"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => this.props.onClose()}>Verwerfen</Button>
          <Button
            color={'primary'}
            variant="raised"
            onClick={() => this.handleCreateAddress()}
            disabled={submitAllowed === false}
          >
            {title}
          </Button>
        </DialogActions>
      </Fragment>
    );
  }
}

AddressFormDialog.propTypes = {
  addressType: PropTypes.string,
  address: PropTypes.object,
  handleCreate: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(AddressFormDialog);
