// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { loginUserDataLayerPush } from '../../actions/user';
import { NavLink } from 'react-router-dom';
import { ROUTE_DASHBOARD, ROUTE_PASSWORD_RESET } from '../../constants/routes';
import LoginSignUpForm from '../../components/forms/LoginSignUpForm';
import {
  BUTTON_LOGIN,
  FORM_LOGIN_LABEL_EMAIL,
  FORM_LOGIN_LABEL_PASSWORD,
} from '../../constants/constants';

type Props = {
  loginUserDataLayerPush: Function,
  loading: boolean,
};

export class Login extends React.Component<Props, {}> {
  render() {
    const { loginUserDataLayerPush, loading } = this.props;

    return (
      <div className="form form__login">
        <h2 className="form__title">Ich bin bereits JUTS-Kunde</h2>
        <p>Melde dich bitte mit deinen Benutzerdaten an.</p>
        <LoginSignUpForm
          formType={'login'}
          handleFormSubmit={loginUserDataLayerPush}
          labels={{
            email: FORM_LOGIN_LABEL_EMAIL,
            password: FORM_LOGIN_LABEL_PASSWORD,
            submit: BUTTON_LOGIN,
          }}
          redirect={ROUTE_DASHBOARD}
          loading={loading}
        />

        <p>
          <NavLink
            className="link link--gutter link--block"
            to={ROUTE_PASSWORD_RESET}
          >
            Passwort vergessen
          </NavLink>
        </p>

        <div>
          <p className="form__title">Du hast noch kein Benutzerkonto?</p>
          <a className="link" href="https://juts.ch">
            Wähle hier Dein Abonnement und erstelle Dein persönliches
            Benutzerkonto.
          </a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({ loading: state.loading.user });
export default connect(
  mapStateToProps,
  {
    loginUserDataLayerPush,
  }
)(Login);
