import React, { Component } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { setSnackbarClosed } from '../../actions/snackbar';

const styles = theme => ({
  close: {
    width: theme.spacing.unit * 4,
    height: theme.spacing.unit * 4,
  },
  snackbar: {
    bottom: '2rem',
  },
});

class SnackBarContainer extends Component {
  handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    this.props.setSnackbarClosed();
  }

  render() {
    const { classes, snackbar } = this.props;

    return (
      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackbar.open}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        onClose={(event, reason) => this.handleClose(event, reason)}
        message={<span id="message-id">{snackbar.message}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={(event, reason) => this.handleClose(event, reason)}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    );
  }
}

const mapStateToProps = state => ({
  snackbar: state.snackbar,
});

export default connect(
  mapStateToProps,
  { setSnackbarClosed }
)(withStyles(styles)(SnackBarContainer));
