// @flow
type ContactSource = {
  firstContactSource?: string,
  lastContactSource?: string,
};

export const getContactSourceFromQueryParams = (): ContactSource => {
  const queryParams = new URLSearchParams(window.location.search);

  return {
    firstContactSource: queryParams.has('fcs')
      ? queryParams.get('fcs')
      : undefined,
    lastContactSource: queryParams.has('lcs')
      ? queryParams.get('lcs')
      : undefined,
  };
};
