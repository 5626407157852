// @flow
import { push } from 'react-router-redux';

import { store } from '../index';

const API_ROOT = process.env.REACT_APP_API_ROOT || '';

export const headers: {
  Accept: string,
  'Content-Type': string,
  Authorization?: string,
} = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const defaultHeaders = (token: string) => {
  const authorization = token ? `Bearer ${token}` : null;
  const headers: {
    Accept: string,
    'Content-Type': string,
    Authorization?: string,
  } = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    // 'X-Juts-Client': 'app.juts-foodbox.com',
  };

  if (authorization) {
    headers.Authorization = authorization;
  }

  return headers;
};

const parseJson = response => {
  try {
    return Promise.resolve(response.json());
  } catch (err) {
    return Promise.reject({
      err: 'Could not parse JSON',
      code: response.status,
    });
  }
};

export const fetchApi = async ({
  endpoint = '',
  method = 'GET',
  payload = {},
  isFile = false,
}: {
  endpoint: string,
  method: string,
  authorization: string,
  payload: any,
  isFile: boolean,
}) => {
  const { accessToken = '' } = store.getState().app;

  const fullUrl =
    endpoint.indexOf(API_ROOT) === -1 ? API_ROOT + endpoint : endpoint;
  const headers = defaultHeaders(accessToken);

  const body = Object.keys(payload).length
    ? JSON.stringify(payload)
    : undefined;

  try {
    const response = await fetch(fullUrl, { method, headers, body });

    // Todo: Check 200, 201, 204 or any error
    if (response.status === 401) {
      return Promise.reject({ err: 'unauthorized', code: 401 });
    }

    if (!response.ok) {
      const message = await parseJson(response);
      return Promise.reject({
        error: message,
        code: response.status,
      });
    }

    if (response.status === 204) {
      return Promise.resolve();
    }

    if (isFile) {
      return response.blob();
    }

    return parseJson(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const callApi = async (
  endpoint: string,
  method: string = 'GET',
  authentication: string,
  payload: any
) => {
  const fullUrl =
    endpoint.indexOf(API_ROOT) === -1 ? API_ROOT + endpoint : endpoint;

  if (typeof authentication === 'string') {
    headers.Authorization = `Bearer ${authentication}`;
  }

  if (method === 'DELETE') {
    return fetch(fullUrl, { method, headers }).then(response => {
      if (!response.ok && response.status !== 204) {
        return Promise.reject({ error: 'API error' });
      }

      return payload;
    });
  }

  return fetch(fullUrl, {
    method,
    headers,
    body: method !== 'GET' ? JSON.stringify(payload) : undefined,
  }).then(response => {
    if (!response.ok) {
      return Promise.reject({
        // $FlowFixMe
        error: response.statusText.error || 'API error',
      });
    }

    if (response.status === 204) {
      return {};
    }

    return response.json().then(json => json);
  });
};

export const CALL_API = 'Call API';

const apiMiddleware = (store: any) => (next: any) => (action: any) => {
  const callAPI = action[CALL_API];
  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  let { endpoint } = callAPI;
  const { types, payload, method, redirect, postAction } = callAPI;

  if (typeof endpoint === 'function') {
    console.log('endpoint is function', endpoint);
  }

  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.');
  }

  const actionWith = data => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_API];
    return finalAction;
  };

  const [requestType, successType, failureType] = types;

  next(actionWith({ type: requestType }));

  const authentication = store.getState().app.accessToken;

  return callApi(endpoint, method, authentication, payload)
    .then(
      response =>
        next(
          actionWith({
            type: successType,
            payload: response || payload,
            redirect,
            postAction,
          })
        ),
      error => Promise.reject(error)
    )
    .then(
      () => (typeof postAction === 'function' ? store.dispatch(postAction) : {})
    )
    .then(
      () => (typeof redirect === 'string' ? store.dispatch(push(redirect)) : {})
    )
    .catch(error => {
      return next(
        actionWith({
          type: failureType,
          error: error || 'Something bad happened',
        })
      );
    });
};

export default apiMiddleware;
