import React from 'react';
import PropTypes from 'prop-types';
import './Headlines.css';

export const HeadlineHero = ({ title }) => (
  <h1 className="headline headline__hero">{title}</h1>
);

HeadlineHero.propTypes = {
  title: PropTypes.string.isRequired,
};

export const HeadlineTitle = ({ title, color }) => (
  <h3
    className={`headline headline__title ${
      color === 'gray' ? 'headline--gray' : null
    }`}
  >
    {title}
  </h3>
);

HeadlineTitle.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export const SubHeading = ({ title }) => (
  <h4 className={'headline headline__subheading'}>{title}</h4>
);

SubHeading.propTypes = {
  title: PropTypes.string.isRequired,
};
