// @flow

import * as React from 'react';
import Select from '../inputs/Select';
import type { PaymentSource } from '../../types';
const CHECKOUT_ADD_NEW_CREDITCARD = 'Neue Kreditkarte hinzufügen';

interface Props {
  onChange: (number | null) => any;
  selectedPaymentId: number | null;
  paymentSources: PaymentSource[];
}

export default class extends React.Component<Props> {
  handlePaymentChange = (event: SyntheticInputEvent<EventTarget>) => {
    const { value } = event.target;

    if (value) {
      return this.props.onChange(parseInt(event.target.value, 10));
    }

    this.props.onChange(null);
  };

  render() {
    const { selectedPaymentId, paymentSources = [] }: Props = this.props;
    const optionList = paymentSources.map(a => (
      <option key={`cardId${a.id}`} value={a.id}>
        {a.brand}, XXXX-XXXX-XXXX-
        {a.last4}, {a.exp_month}/{a.exp_year}
      </option>
    ));

    const selectedPaymentIdString = selectedPaymentId
      ? selectedPaymentId.toString()
      : '';

    return (
      <div className="form__group">
        <Select
          fullWidth
          native
          onChange={this.handlePaymentChange}
          value={selectedPaymentIdString}
        >
          {optionList}
          <option value="">-- {CHECKOUT_ADD_NEW_CREDITCARD} --</option>
        </Select>

        {selectedPaymentId ? (
          <span
            className="link link--gutter"
            onClick={() => this.props.onChange(null)}
          >
            {CHECKOUT_ADD_NEW_CREDITCARD}
          </span>
        ) : null}
      </div>
    );
  }
}
