import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ViewGiftBoxCheckout from '../../containers/cart/ViewGiftBoxCheckout';
import ViewSubscriptionCheckout from '../../containers/cart/ViewSubscriptionCheckout';
import ViewGiftBoxRedeemCheckout from '../../containers/cart/ViewGiftBoxRedeemCheckout';
import ViewSubscriptionTrialCheckout from '../../containers/cart/ViewSubscriptionTrialCheckout';
import { CheckoutGiftBoxSuccess } from './CheckoutSuccess';
import CheckoutContainer from '../../containers/cart/CheckoutContainer';

const Checkout = () => (
  <Switch>
    <Route
      exact
      path="/checkout/subscription"
      component={ViewSubscriptionCheckout}
    />
    <Route
      exact
      path="/checkout/trial"
      component={ViewSubscriptionTrialCheckout}
    />
    <Route exact path="/checkout/giftbox" component={ViewGiftBoxCheckout} />
    <Route
      exact
      path="/checkout/redeem/success"
      component={CheckoutGiftBoxSuccess}
    />
    <Route
      exact
      path="/checkout/redeem/:step"
      component={ViewGiftBoxRedeemCheckout}
    />
    <Route
      exact
      path="/checkout/redeem"
      component={ViewGiftBoxRedeemCheckout}
    />
    <Route exact path="/checkout/:step" component={CheckoutContainer} />
  </Switch>
);

export default Checkout;
