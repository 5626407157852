// @flow

import * as React from 'react';
import DayPicker from 'react-day-picker';
import './Calendar.css';

import {
  CALENDAR_LABELS,
  MONTHS,
  WEEKDAYS_LONG,
  WEEKDAYS_SHORT,
} from '../../utils/dateHelper';

type Props = {
  selectedDay?: Date,
  disabled: boolean,
  allowedDateRange: Date[],
  pausedDateRange: Date[],
  deliveryDateRange: Date[],
  onClick: Function,
  subscriptionId?: number,
  numberOfMonths?: number,
};

class Calendar extends React.Component<Props> {
  render() {
    const {
      allowedDateRange,
      pausedDateRange,
      deliveryDateRange,
      selectedDay,
      onClick,
      numberOfMonths = 1,
    } = this.props;

    const allowedPickerRange = [...allowedDateRange, ...pausedDateRange].sort(
      (a: Date, b: Date) => a - b
    );

    const fromMonth = allowedPickerRange.length
      ? allowedPickerRange[0]
      : undefined;

    const toMonth = allowedPickerRange.length
      ? allowedPickerRange[allowedPickerRange.length - 1]
      : undefined;

    return (
      <div className="calendar__wrapper">
        <DayPicker
          locale="de"
          months={MONTHS['de']}
          weekdaysLong={WEEKDAYS_LONG['de']}
          weekdaysShort={WEEKDAYS_SHORT['de']}
          firstDayOfWeek={1}
          labels={CALENDAR_LABELS['de']}
          numberOfMonths={numberOfMonths}
          modifiers={{
            active: allowedDateRange,
            selected: selectedDay,
            delivery: deliveryDateRange,
            disabled: { daysOfWeek: [6, 0, 1] },
            paused: pausedDateRange,
          }}
          onDayClick={onClick}
          initialMonth={fromMonth}
          fromMonth={fromMonth}
          toMonth={toMonth}
        />
      </div>
    );
  }
}

export default Calendar;
