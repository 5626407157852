// @flow

import isAfter from 'date-fns/is_after';
import format from 'date-fns/format';
import de from 'date-fns/locale/de';

export const dateFormatDays = (date: Date | number) =>
  format(date, 'dddd, DD.MM.YYYY ([KW] W)', { locale: de });

export const dateFormatLong = (date: Date | number) =>
  format(date, 'dddd, DD.MM.YYYY', { locale: de });

export const dateFormatShort = (date: Date | number) =>
  format(date, 'DD.MM.YYYY', { locale: de });

export const dateFormatDeliveryDate = (
  d: string
): { weekday: string, date: string } => {
  const date = isoDateStringToDate(d);

  return {
    weekday: format(date, 'dddd', { locale: de }),
    date: format(date, 'DD.MM.YYYY', { locale: de }),
  };
};

export const dateFormatDaysIso = (date: Date) =>
  format(new Date(date), 'YYYY-MM-DD');

export const WEEKDAYS_LONG = {
  de: [
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
    'Sonntag',
  ],
};
export const WEEKDAYS_SHORT = {
  de: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
};
export const MONTHS = {
  de: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ],
};

export const isoDateStringToDate = (s: string): Date =>
  new Date(s + 'T00:00:00');

export const isoDateToDateString = (s: string) =>
  dateFormatShort(isoDateStringToDate(s));

export const isoStringDateIsInPast = (s: string) =>
  isAfter(new Date(), isoDateStringToDate(s));

export const getValidDeliveryDates = (days: string[]): any =>
  days.map(d => ({ date: d, title: dateFormatLong(isoDateStringToDate(d)) }));

export const CALENDAR_LABELS = {
  de: { nextMonth: 'Nächster Monat', previousMonth: 'Letzter Monat' },
};
