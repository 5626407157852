import React, { Fragment } from 'react';
import { HeadlineTitle } from '../typography/Headlines';
import InvoiceList from '../../containers/profile/InvoiceList';

export default () => (
  <Fragment>
    <HeadlineTitle title={'Rechnungen'} />
    <InvoiceList />
  </Fragment>
);
