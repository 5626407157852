import { CALL_API } from '../middleware/api';

// deliveries index request
export const DELIVERIES_REQUEST = 'DELIVERIES_REQUEST';
export const DELIVERIES_SUCCESS = 'DELIVERIES_SUCCESS';
export const DELIVERIES_FAILURE = 'DELIVERIES_FAILURE';

const fetchDeliveries = subscriptionId => ({
  type: 'DELIVERIES_REQUEST_START',
  [CALL_API]: {
    types: [DELIVERIES_REQUEST, DELIVERIES_SUCCESS, DELIVERIES_FAILURE],
    endpoint: subscriptionId
      ? `/api/v1/subscriptions/${subscriptionId}/deliveries/`
      : `/api/v1/deliveries/`,
  },
});

export const loadDeliveriesBySubscription = subscriptionId => dispatch =>
  dispatch(fetchDeliveries(subscriptionId));

export const loadDeliveries = () => dispatch => dispatch(fetchDeliveries());

// deliveries index request
export const DELIVERIES_UPDATE_REQUEST = 'DELIVERIES_UPDATE_REQUEST';
export const DELIVERIES_UPDATE_SUCCESS = 'DELIVERIES_UPDATE_SUCCESS';
export const DELIVERIES_UPDATE_FAILURE = 'DELIVERIES_UPDATE_FAILURE';

const fetchUpdateDelivery = (deliveryId, payload, postAction) => ({
  type: 'DELIVERIES_REQUEST_START',
  [CALL_API]: {
    types: [
      DELIVERIES_UPDATE_REQUEST,
      DELIVERIES_UPDATE_SUCCESS,
      DELIVERIES_UPDATE_FAILURE,
    ],
    endpoint: `/api/v1/deliveries/${deliveryId}/`,
    method: 'PUT',
    payload,
    postAction,
  },
});

export const updateDelivery = (deliveryId, payload, postAction) => dispatch =>
  dispatch(fetchUpdateDelivery(deliveryId, payload, postAction));
