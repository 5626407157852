// @flow

import * as React from 'react';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import {
  checkoutSetDeliveryDate,
  checkoutSetDialogClose,
  checkoutSetFields,
} from '../../actions/checkout';
import type { Checkout } from '../../redux/checkout';
import {
  CHECKOUT_TYPE_GIFTBOX_BUY,
  CHECKOUT_TYPE_GIFTBOX_REDEEM,
  deliveryIntervalOptions,
} from '../../redux/checkout';
import { dateFormatDays, getValidDeliveryDates } from '../../utils/dateHelper';
import {
  CHECKOUT_BOX_SIZE,
  CHECKOUT_BOX_TITLE,
  CHECKOUT_BOX_TYPE,
  CHECKOUT_DELIVERY_DAY,
  CHECKOUT_DELIVERY_FIRST_DAY,
  CHECKOUT_DELIVERY_INTERVAL,
  CHECKOUT_DELIVERY_PERIOD,
  CHECKOUT_DELIVERY_TIME,
  DIALOG_ACTION_DISMISS,
  DIALOG_ACTION_OK,
} from '../../constants/constants';
import type { Plan, ServingOption } from '../../redux/plans';
import Select from '../../components/inputs/Select';
import Button from '../../components/inputs/Button';

type Props = {
  plans: Plan[],
  checkoutSetDialogClose: Function,
  checkoutSetFields: Function,
  checkout: Checkout,
};

type State = {
  planId: ?number,
  servingOptionId: ?number,
  firstDeliveryDate: ?string,
  interval: ?number,
};

class CheckoutDialog extends React.Component<Props, State> {
  state = {
    planId: null,
    servingOptionId: null,
    firstDeliveryDate: null,
    interval: null,
  };

  setProps(props) {
    const {
      planId,
      servingOptionId,
      firstDeliveryDate,
      interval,
    } = props.checkout;

    this.setState({
      planId,
      servingOptionId,
      firstDeliveryDate,
      interval,
    });
  }

  componentWillMount() {
    this.setProps(this.props);
  }
  componentWillReceiveProps(nextProps: Props) {
    this.setProps(nextProps);
  }

  handleSelectChange = event =>
    this.setState({ firstDeliveryDate: event.target.value });

  handleServingChange = event =>
    this.setState({ servingOptionId: parseInt(event.target.value, 10) });

  handleIntervalChange = event =>
    this.setState({ interval: parseInt(event.target.value, 10) });

  handlePlanChange = event => {
    const { plans } = this.props;

    const nextPlanId: number = parseInt(event.target.value, 10);
    const nextPlan: ?Plan = plans.find(p => p.id === nextPlanId);
    if (!nextPlan) {
      // todo: raise error
      return;
    }

    const { planId, servingOptionId } = this.state;
    const plan: ?Plan = plans.find(p => p.id === planId);
    if (!plan) {
      // todo: raise error
      return null;
    }

    const servingOption: ?ServingOption = plan.serving_options.find(
      s => s.id === servingOptionId
    );

    if (!servingOption) {
      // todo: raise error
      return null;
    }

    const quantity = servingOption.quantity;
    const nextServingOption: ?ServingOption = nextPlan.serving_options.find(
      s => s.quantity === quantity
    );

    if (!nextServingOption) {
      // todo: raise error
      return null;
    }

    this.setState({
      planId: nextPlanId,
      servingOptionId: nextServingOption.id,
    });
  };

  handleDialogSubmit = () => {
    const { planId, servingOptionId, firstDeliveryDate, interval } = this.state;

    this.props.checkoutSetFields({
      servingOptionId,
      interval,
      firstDeliveryDate,
      planId,
    });
    this.props.checkoutSetDialogClose();
  };

  render() {
    const { plans, checkoutSetDialogClose } = this.props;
    const { planId, servingOptionId, firstDeliveryDate, interval } = this.state;
    const checkout: Checkout = this.props.checkout;

    if (!planId || !servingOptionId || !firstDeliveryDate || !interval) {
      return null;
    }

    const plan = plans.find(p => p.id === planId) || plans[0];
    const servingOptions = plan.serving_options;
    const deliveryOptions = getValidDeliveryDates(plan.delivery_dates);

    return (
      <React.Fragment>
        <div className="dialog__header">
          <div className="dialog__header-bar">
            <div className="dialog__title">
              <span className="dialog__title--bold">{CHECKOUT_BOX_TITLE}</span>
            </div>
          </div>
          <div className="dialog__hr" />
        </div>
        <div className="dialog__content">
          <Grid container spacing={16}>
            {checkout.productType !== CHECKOUT_TYPE_GIFTBOX_REDEEM ? (
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <label className="form-control__label" htmlFor="setPlan">
                    {CHECKOUT_BOX_TYPE}
                  </label>
                  <Select
                    id="setPlan"
                    value={planId.toString()}
                    onChange={this.handlePlanChange}
                  >
                    {plans.map(item => (
                      <option key={item.title} value={item.id}>
                        {item.title}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ) : null}
            {checkout.productType !== CHECKOUT_TYPE_GIFTBOX_REDEEM ? (
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <label
                    className="form-control__label"
                    htmlFor="setServingOption"
                  >
                    {CHECKOUT_BOX_SIZE}
                  </label>
                  <Select
                    id="setServingOption"
                    value={servingOptionId.toString()}
                    onChange={this.handleServingChange}
                  >
                    {servingOptions.map(item => (
                      <option key={item.title} value={item.id}>
                        {item.title}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ) : null}
            {checkout.productType !== CHECKOUT_TYPE_GIFTBOX_BUY ? (
              <React.Fragment>
                {checkout.isSubscription ? (
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <label
                        className="form-control__label"
                        htmlFor="setDeliveryInterval"
                      >
                        {CHECKOUT_DELIVERY_INTERVAL}
                      </label>
                      <Select
                        id="setDeliveryInterval"
                        value={interval.toString()}
                        onChange={this.handleIntervalChange}
                      >
                        {deliveryIntervalOptions.map((item, index) => (
                          <option key={index} value={item.interval}>
                            {item.title}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : null}
                {checkout.productType !== CHECKOUT_TYPE_GIFTBOX_REDEEM ? (
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <label
                        className="form-control__label"
                        htmlFor="setDeliveryTime"
                      >
                        {CHECKOUT_DELIVERY_PERIOD}
                      </label>
                      <Select
                        value={interval.toString()}
                        onChange={this.handleIntervalChange}
                        input={<Input id="setDeliveryTime" />}
                      >
                        <option value="">{CHECKOUT_DELIVERY_TIME}</option>
                      </Select>
                    </FormControl>
                  </Grid>
                ) : null}
                <Grid
                  item
                  xs={12}
                  sm={
                    checkout.productType === CHECKOUT_TYPE_GIFTBOX_REDEEM
                      ? 12
                      : 6
                  }
                >
                  <FormControl fullWidth>
                    <label
                      className="form-control__label"
                      htmlFor="setDeliveryDay"
                    >
                      {checkout.isSubscription
                        ? CHECKOUT_DELIVERY_FIRST_DAY
                        : CHECKOUT_DELIVERY_DAY}
                    </label>
                    <Select
                      value={firstDeliveryDate.toString()}
                      onChange={this.handleSelectChange}
                      input={<Input id="setDeliveryDay" />}
                    >
                      {deliveryOptions.map(d => (
                        <option key={`deliveryDate${d.date}`} value={d.date}>
                          {dateFormatDays(d.date)}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </React.Fragment>
            ) : null}
          </Grid>
        </div>
        <div>
          <div className="dialog__actions">
            <Button
              fullWidthMobile
              variant="flat"
              onClick={checkoutSetDialogClose}
            >
              {DIALOG_ACTION_DISMISS}
            </Button>
            <Button
              fullWidthMobile
              onClick={this.handleDialogSubmit}
              color="primary"
              autoFocus
              variant="raised"
            >
              {DIALOG_ACTION_OK}
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  checkout: state.checkout,
  plans: state.plan,
});

export default connect(
  mapStateToProps,
  {
    checkoutSetDialogClose,
    checkoutSetDeliveryDate,
    checkoutSetFields,
  }
)(CheckoutDialog);
