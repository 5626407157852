import { CHECKOUT_TYPE_SUBSCRIPTION } from '../redux/checkout';

export const ROUTE_DASHBOARD = '/';
export const ROUTE_AUTH = '/auth';
export const ROUTE_LOGIN = '/login';
export const ROUTE_LOGOUT = '/logout';
export const ROUTE_REGISTER = '/register';
export const ROUTE_CHECKOUT = '/checkout';
export const ROUTE_PASSWORD_RESET = '/forgot-password';
export const ROUTE_PASSWORD_RESET_CHANGE = `${ROUTE_PASSWORD_RESET}/:uid/:token`;
export const ROUTE_PROFILE_CALENDAR = '/calendar';
export const ROUTE_PROFILE_INVOICES = '/invoices';
export const ROUTE_DELIVERIES = '/deliveries';
export const ROUTE_PROFILE = '/profile';
export const ROUTE_PROFILE_SUBSCRIPTION_VIEW = `${ROUTE_PROFILE}/subscription/:id`;
export const ROUTE_PROFILE_SUBSCRIPTION_EDIT_PAYMENT = `${ROUTE_PROFILE_SUBSCRIPTION_VIEW}/edit/payment`;
export const ROUTE_PROFILE_SUBSCRIPTION_EDIT_ADDRESS = `${ROUTE_PROFILE_SUBSCRIPTION_VIEW}/edit/address`;
export const ROUTE_PROFILE_PASSWORD_CHANGE = `${ROUTE_PROFILE}/password-change`;
export const ROUTE_PROFILE_EMAIL_CHANGE = `${ROUTE_PROFILE}/email-change`;
export const ROUTE_CHECKOUT_SUBSCRIPTION = `${ROUTE_CHECKOUT}/${CHECKOUT_TYPE_SUBSCRIPTION}`;
export const ROUTE_SUBSCRIPTION = '/subscriptions';
export const ROUTE_SUBSCRIPTION_EDIT = `${ROUTE_SUBSCRIPTION}/:id/edit`;
