// @flow
import {
  CHECKOUT_SET_DIALOG_CLOSE,
  CHECKOUT_SET_DIALOG_OPEN,
  DIALOG_CLOSE,
  DIALOG_OPEN,
} from '../constants/actionTypes';

export type Dialog = {
  open: boolean,
  target: ?string,
  targetId: ?number,
};

const initialState: Dialog = {
  open: false,
  target: undefined,
  targetId: undefined,
};

export default (state: Dialog = initialState, action: any) => {
  switch (action.type) {
    case CHECKOUT_SET_DIALOG_OPEN: {
      return {
        ...state,
        open: true,
      };
    }
    case DIALOG_OPEN: {
      return {
        open: true,
        target: action.payload.target,
        targetId: action.payload.targetId,
        targetType: action.payload.targetType,
      };
    }
    case CHECKOUT_SET_DIALOG_CLOSE:
    case DIALOG_CLOSE: {
      return initialState;
    }
    default:
      return state;
  }
};
