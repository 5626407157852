import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import Login from './auth/Login';
import Register from './auth/Register';
import { connect } from 'react-redux';
import { loadUser, userLogout } from '../actions/user';
import PageHeader from '../components/layout/PageHeader';
import { isCheckoutRoute, isPublicRoute } from '../check';
import Progress from '../components/progress/Progress';
import { appChangeLocation } from '../actions/router';

import PageFooter from '../components/layout/PageFooter';
import { HeadlineHero } from '../components/typography/Headlines';
import DialogContainer from './dialog/DialogContainer';
import ViewsWithSideNavigation from '../components/views/ViewsWithSideNavigation';
import PasswordReset from './auth/PasswordReset';
import {
  ROUTE_LOGIN,
  ROUTE_LOGOUT,
  ROUTE_PASSWORD_RESET,
  ROUTE_PASSWORD_RESET_CHANGE,
  ROUTE_PROFILE_CALENDAR,
  ROUTE_REGISTER,
} from '../constants/routes';
import PasswordResetConfirm from './auth/PasswordResetConfirm';
import Logout from './auth/Logout';
import SnackbarContainer from './snackbar/SnackbarContainer';
import Paper from '../components/layout/Paper';
import ViewGeneralErrorPage from '../components/views/ViewGeneralErrorPage';
import Checkout from '../components/checkout/Checkout';
import './App.css';
import PaymentIconsBar from '../components/payments/PaymentIconsBar';

const getSiteHeadline = location => {
  let title = '';

  switch (location) {
    case '/meine-foodboxen': {
      title = 'Meine Foodboxen';
      break;
    }
    case ROUTE_PROFILE_CALENDAR: {
      title = 'Mein Kalender';
      break;
    }
    default:
      title = 'Mein JUTS Benutzerkonto';
      break;
  }

  if (location.startsWith('/bestellung') || location.startsWith('/checkout')) {
    title = 'Bezahlvorgang';
  }

  return title !== '' ? <HeadlineHero title={title} /> : null;
};

const PageNotFound = () => (
  <div>404 - Diese Seite wurde leider nicht gefunden.</div>
);

class App extends Component {
  getUser(props) {
    const { user, loadUser, isAuthorized, loading } = props;

    if (
      isAuthorized &&
      user.id !== null &&
      (user.firstName === null || user.lastName === null) &&
      loading.user === false
    ) {
      loadUser(user.id);
    }
  }

  checkRoute(props) {
    const { isAuthorized, location } = props;
    if (isAuthorized === false && isPublicRoute(location) === false) {
      this.props.appChangeLocation(ROUTE_LOGIN);
      return false;
    }
  }

  componentWillMount() {
    this.checkRoute(this.props);
    this.getUser(this.props);
  }

  componentWillUpdate(nextProps) {
    return this.checkRoute(nextProps);
  }

  render() {
    const {
      user,
      loading,
      appChangeLocation,
      location,
      backendServiceAvailable,
      hasPlans,
    } = this.props;

    if (backendServiceAvailable === false) {
      return <ViewGeneralErrorPage />;
    }

    const showStoreButton = !isCheckoutRoute(location);

    return (
      <div className="app__site">
        <Progress loading={loading} />
        <PageHeader
          user={user}
          changeLocation={appChangeLocation}
          showStoreButton={showStoreButton}
          isAuthorized={user.isAuthorized}
          userLogout={this.props.userLogout}
        />
        <div className="app__main">
          <div className="app__content">
            {user.isAuthorized ? getSiteHeadline(location.pathname) : null}

            {hasPlans ? (
              <Paper wide>
                <Switch>
                  <Route exact path={ROUTE_LOGOUT} component={Logout} />
                  <Route exact path={ROUTE_LOGIN} component={Login} />
                  <Route exact path={ROUTE_REGISTER} component={Register} />
                  <Route
                    exact
                    path={ROUTE_PASSWORD_RESET_CHANGE}
                    component={PasswordResetConfirm}
                  />
                  <Route
                    exact
                    path={ROUTE_PASSWORD_RESET}
                    component={PasswordReset}
                  />
                  <Route path="/checkout" component={Checkout} />
                  {user.isAuthorized ? (
                    <Route path="/" component={ViewsWithSideNavigation} />
                  ) : null}
                  <Route component={PageNotFound} />
                </Switch>
              </Paper>
            ) : null}
            <PaymentIconsBar />
            <DialogContainer />
            <SnackbarContainer />
          </div>
        </div>
        <PageFooter />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthorized: state.user.isAuthorized,
  app: state.app,
  backendServiceAvailable: state.app.backendServiceAvailable,
  user: state.user,
  loading: state.loading,
  hasPlans: state.plan.length > 0,
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      loadUser,
      userLogout,
      appChangeLocation,
    }
  )(App)
);
