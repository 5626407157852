import React from 'react';
import PropTypes from 'prop-types';
import './Card.css';
import { getCreditCardImage } from '../../utils/format';

const Card = ({ classes, card }) => {
  return card ? (
    <div className="card">
      <img
        src={getCreditCardImage(card.brand)}
        alt={card.brand}
        height={40}
        width={64}
        className="card__image"
      />
      <p className="card__title">{`XXXX-XXXX-XXXX-${card.last4} ${
        card.exp_month
      }/${card.exp_year}`}</p>
    </div>
  ) : null;
};

Card.propTypes = {
  card: PropTypes.object.isRequired,
};

export default Card;
