// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { checkoutSetPaymentType } from '../../actions/checkout';
import type { Checkout } from '../../redux/checkout';
import './PaymentTypeSelector.css';
import PaymentTypeSelectorItem from '../../components/payments/selector/PaymentTypeSelectorItem';
import { getPaymentIcon } from '../../utils/format';
import { CHECKOUT_PAYMENT_TITLE } from '../../constants/constants';
import { paymentTypes } from '../../types/payment';

type Props = {
  checkoutSetPaymentType: (paymentType: string) => void,
  checkout: Checkout,
};

class PaymentTypeSelector extends React.Component<Props> {
  render() {
    const { checkout, checkoutSetPaymentType } = this.props;

    return (
      <div>
        <h3>{CHECKOUT_PAYMENT_TITLE}</h3>

        <div className="paymentTypeProvider__list">
          <PaymentTypeSelectorItem
            onClick={() => checkoutSetPaymentType(paymentTypes.paypal)}
            selected={checkout.paymentType === paymentTypes.paypal}
          >
            <img
              {...getPaymentIcon(paymentTypes.paypal)}
              className="paymentProviderSelectorItem__icon"
              alt="Bezahlen mit Paypal"
            />
          </PaymentTypeSelectorItem>

          <PaymentTypeSelectorItem
            onClick={() => checkoutSetPaymentType(paymentTypes.creditCard)}
            selected={checkout.paymentType === paymentTypes.creditCard}
          >
            <img
              {...getPaymentIcon('mastercard')}
              className="paymentProviderSelectorItem__icon"
              alt="Bezahlen mit MasterCard"
            />
            <img
              {...getPaymentIcon('visa')}
              className="paymentProviderSelectorItem__icon"
              alt="Bezahlen mit Visa"
            />
          </PaymentTypeSelectorItem>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({ checkout: state.checkout });

export default connect(
  mapStateToProps,
  {
    checkoutSetPaymentType,
  }
)(PaymentTypeSelector);
