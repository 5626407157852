import { CALL_API } from '../middleware/api';

// address index request
export const ADDRESSES_REQUEST = 'ADDRESSES_REQUEST';
export const ADDRESSES_SUCCESS = 'ADDRESSES_SUCCESS';
export const ADDRESSES_FAILURE = 'ADDRESSES_FAILURE';

const fetchAddresses = () => ({
  type: 'ADDRESSES_REQUEST_START',
  [CALL_API]: {
    types: [ADDRESSES_REQUEST, ADDRESSES_SUCCESS, ADDRESSES_FAILURE],
    endpoint: `/api/v1/addresses/`,
    schema: 'address',
  },
});

export const loadAddresses = () => dispatch => dispatch(fetchAddresses());

// address create
export const ADDRESSES_CREATE_REQUEST = 'ADDRESSES_CREATE_REQUEST';
export const ADDRESSES_CREATE_SUCCESS = 'ADDRESSES_CREATE_SUCCESS';
export const ADDRESSES_CREATE_FAILURE = 'ADDRESSES_CREATE_FAILURE';

const fetchCreateAddress = address => ({
  type: 'ADDRESSES_CREATE_START',
  [CALL_API]: {
    types: [
      ADDRESSES_CREATE_REQUEST,
      ADDRESSES_CREATE_SUCCESS,
      ADDRESSES_CREATE_FAILURE,
    ],
    endpoint: `/api/v1/addresses/`,
    method: 'POST',
    payload: address,
    schema: 'address',
  },
});

export const createAddress = address => dispatch =>
  dispatch(fetchCreateAddress(address));

// address update
export const ADDRESSES_UPDATE_REQUEST = 'ADDRESSES_UPDATE_REQUEST';
export const ADDRESSES_UPDATE_SUCCESS = 'ADDRESSES_UPDATE_SUCCESS';
export const ADDRESSES_UPDATE_FAILURE = 'ADDRESSES_UPDATE_FAILURE';

const fetchUpdateAddress = (addressId, payload, postAction) => ({
  type: 'ADDRESSES_UPDATE_START',
  [CALL_API]: {
    types: [
      ADDRESSES_UPDATE_REQUEST,
      ADDRESSES_UPDATE_SUCCESS,
      ADDRESSES_UPDATE_FAILURE,
    ],
    endpoint: `/api/v1/addresses/${addressId}/`,
    method: 'PUT',
    payload,
    postAction,
  },
});

export const updateAddress = (addressId, payload, postAction) => dispatch =>
  dispatch(fetchUpdateAddress(addressId, payload, postAction));

// address delete
export const ADDRESSES_DELETE_REQUEST = 'ADDRESSES_DELETE_REQUEST';
export const ADDRESSES_DELETE_SUCCESS = 'ADDRESSES_DELETE_SUCCESS';
export const ADDRESSES_DELETE_FAILURE = 'ADDRESSES_DELETE_FAILURE';

const fetchDeleteAddress = addressId => ({
  type: 'ADDRESSES_DELETE_START',
  [CALL_API]: {
    types: [
      ADDRESSES_DELETE_REQUEST,
      ADDRESSES_DELETE_SUCCESS,
      ADDRESSES_DELETE_FAILURE,
    ],
    endpoint: `/api/v1/addresses/${addressId}/`,
    method: 'DELETE',
    payload: addressId,
  },
});

export const deleteAddress = addressId => dispatch =>
  dispatch(fetchDeleteAddress(addressId));

// address create
export const ADDRESSES_CHECKOUT_CREATE_DELIVERY_REQUEST =
  'ADDRESSES_CHECKOUT_CREATE_DELIVERY_REQUEST';
export const ADDRESSES_CHECKOUT_CREATE_DELIVERY_SUCCESS =
  'ADDRESSES_CHECKOUT_CREATE_DELIVERY_SUCCESS';
export const ADDRESSES_CHECKOUT_CREATE_DELIVERY_FAILURE =
  'ADDRESSES_CHECKOUT_CREATE_DELIVERY_FAILURE';

export const ADDRESSES_CHECKOUT_CREATE_BILLING_REQUEST =
  'ADDRESSES_CHECKOUT_CREATE_BILLING_REQUEST';
export const ADDRESSES_CHECKOUT_CREATE_BILLING_SUCCESS =
  'ADDRESSES_CHECKOUT_CREATE_BILLING_SUCCESS';
export const ADDRESSES_CHECKOUT_CREATE_BILLING_FAILURE =
  'ADDRESSES_CHECKOUT_CREATE_BILLING_FAILURE';

// address create
export const ADDRESSES_CHECKOUT_UPDATE_DELIVERY_REQUEST =
  'ADDRESSES_CHECKOUT_UPDATE_DELIVERY_REQUEST';
export const ADDRESSES_CHECKOUT_UPDATE_DELIVERY_SUCCESS =
  'ADDRESSES_CHECKOUT_UPDATE_DELIVERY_SUCCESS';
export const ADDRESSES_CHECKOUT_UPDATE_DELIVERY_FAILURE =
  'ADDRESSES_CHECKOUT_UPDATE_DELIVERY_FAILURE';

export const ADDRESSES_CHECKOUT_UPDATE_BILLING_REQUEST =
  'ADDRESSES_CHECKOUT_UPDATE_BILLING_REQUEST';
export const ADDRESSES_CHECKOUT_UPDATE_BILLING_SUCCESS =
  'ADDRESSES_CHECKOUT_UPDATE_BILLING_SUCCESS';
export const ADDRESSES_CHECKOUT_UPDATE_BILLING_FAILURE =
  'ADDRESSES_CHECKOUT_UPDATE_BILLING_FAILURE';
