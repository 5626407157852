import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from '../redux/rootReducer';
import apiMiddleware from '../middleware/api';
import authMiddleware from '../middleware/auth';
import { reactRouterMiddleware } from '../index';
import thunk from 'redux-thunk';

const configureStore = preloadedState => {
  return createStore(
    rootReducer,
    preloadedState,
    compose(
      applyMiddleware(
        thunk,
        apiMiddleware,
        authMiddleware,
        reactRouterMiddleware
      )
    )
  );
};

export default configureStore;
