import React, { Fragment } from 'react';
import { HeadlineTitle } from '../typography/Headlines';
import ProfileTabList from '../../containers/profile/ProfileTabList';

export default () => (
  <Fragment>
    <HeadlineTitle title={'Mein Kalender'} />
    <ProfileTabList />
  </Fragment>
);
