import React from 'react';
import { connect } from 'react-redux';
import { setDialogClosed } from '../../actions/dialog';
import { loadDeliveries, updateDelivery } from '../../actions/deliveries';
import DeliveryDialog from '../../components/subscription/DeliveryDialog';
import { appChangeLocation } from '../../actions/router';

const mapStateToProps = state => ({
  delivery: state.deliveries.find(d => d.id === state.dialog.targetId),
  dialog: state.dialog,
  plans: state.plan,
});

const mapDispatchToProps = {
  setDialogClosed,
  updateDelivery,
  appChangeLocation,
};

const withDelivery = WrappedComponent =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class extends React.Component {
      render() {
        return <WrappedComponent {...this.props} />;
      }
    }
  );

export default withDelivery;
export const DeliveryDialogWithData = withDelivery(DeliveryDialog);

export const withDeliveries = WrappedComponent =>
  connect(
    state => ({
      deliveries: state.deliveries,
    }),
    {
      appChangeLocation,
      loadDeliveries,
    }
  )(
    class extends React.Component {
      componentWillMount() {
        this.props.loadDeliveries();
      }
      render() {
        const { subscriptionId, deliveries } = this.props;
        const filteredDeliveries = subscriptionId
          ? deliveries.filter(d => d.subscription === subscriptionId)
          : deliveries;

        return (
          <WrappedComponent {...this.props} deliveries={filteredDeliveries} />
        );
      }
    }
  );
