// @flow

import * as React from 'react';
import './PaymentTypeSelector.css';
import PaymentTypeSelectorItem from '../../components/payments/selector/PaymentTypeSelectorItem';
import { getPaymentIcon } from '../../utils/format';
import type { PaymentType } from '../../types/payment';
import { paymentTypes } from '../../types/payment';

type Props = {
  onChange: (paymentType: PaymentType) => void,
  selected?: PaymentType,
  isLoading?: boolean,
};

class PaymentTypeSelector extends React.Component<Props> {
  render() {
    const { selected, onChange, isLoading } = this.props;
    return (
      <div className="paymentTypeProvider__list">
        <PaymentTypeSelectorItem
          onClick={() => onChange(paymentTypes.paypal)}
          selected={selected === paymentTypes.paypal}
          isLoading={isLoading}
        >
          <img
            {...getPaymentIcon(paymentTypes.paypal)}
            className="paymentProviderSelectorItem__icon"
            alt="Bezahlen mit Paypal"
          />
        </PaymentTypeSelectorItem>

        <PaymentTypeSelectorItem
          onClick={() => onChange(paymentTypes.creditCard)}
          selected={selected === paymentTypes.creditCard}
          isLoading={isLoading}
        >
          <img
            {...getPaymentIcon('mastercard')}
            className="paymentProviderSelectorItem__icon"
            alt="Bezahlen mit MasterCard"
          />
          <img
            {...getPaymentIcon('visa')}
            className="paymentProviderSelectorItem__icon"
            alt="Bezahlen mit Visa"
          />
        </PaymentTypeSelectorItem>
      </div>
    );
  }
}

export default PaymentTypeSelector;
