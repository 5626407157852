import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Paper from '../../components/layout/Paper';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import {
  ROUTE_PROFILE_EMAIL_CHANGE,
  ROUTE_PROFILE_PASSWORD_CHANGE,
} from '../../constants/routes';

class ProfileCards extends Component {
  render() {
    const { user } = this.props;
    return (
      <Grid container spacing={16}>
        <Grid item xs={12} sm={6}>
          <Paper>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <strong>Email:</strong> <br /> {user.email}
              </div>
              <Button
                component={Link}
                to={ROUTE_PROFILE_EMAIL_CHANGE}
                size="small"
                color={'primary'}
              >
                Editieren
              </Button>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <strong>Passwort:</strong> <br /> *******
              </div>
              <Button
                component={Link}
                to={ROUTE_PROFILE_PASSWORD_CHANGE}
                size="small"
                color={'primary'}
              >
                Editieren
              </Button>
            </div>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  app: state.app,
  user: state.user,
  history: ownProps.history,
});

export default connect(
  mapStateToProps,
  {}
)(ProfileCards);
