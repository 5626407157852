// @flow

import * as React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import AddressForm, { initialAddressState } from '../forms/AddressForm';

import {
  ADDRESS_BILLING,
  ADDRESS_DELIVERY,
  ADDRESS_DELIVERY_AND_BILLING,
} from '../../constants/constants';

import AddressSelector from '../address/AddressSelector';
import type { Address } from '../../types/address';

type OnAddressChange = {
  addressType: string,
  address: Address,
  addressesAreEqual?: boolean,
};

type OnAddressFieldChange = {
  addressType: string,
  field: string,
  value: string,
  addressesAreEqual?: boolean,
};

type Props = {
  addresses: Address[],
  loadAddresses?: void => void,
  deliveryAddress: ?Address,
  billingAddress: ?Address,
  onChange: OnAddressChange => any,
  onChangeField: OnAddressFieldChange => any,
  loading?: boolean,
};

class Addresses extends React.Component<Props> {
  componentDidMount() {
    // todo: legacy?
    if (typeof this.props.loadAddresses === 'function') {
      this.props.loadAddresses();
    }
  }

  onAlternateBillingChange = () => {
    const hasAlternateBilling = this.hasAlternateBilling();

    if (hasAlternateBilling) {
      const { deliveryAddress } = this.props;

      if (!deliveryAddress) {
        return;
      }

      this.props.onChange({
        addressType: 'billing',
        address: deliveryAddress,
      });
    } else {
      this.props.onChange({
        addressType: 'billing',
        address: initialAddressState,
      });
    }
  };

  onChange = ({ addressType, address }: OnAddressChange) =>
    this.hasAlternateBilling()
      ? this.props.onChange({ addressType, address })
      : this.props.onChange({ addressType, address, addressesAreEqual: true });

  onChangeField = ({ addressType, field, value }: OnAddressFieldChange) => {
    if (this.hasAlternateBilling()) {
      this.props.onChangeField({ addressType, field, value });
    } else {
      // set both addresses
      this.props.onChangeField({
        addressType,
        field,
        value,
        addressesAreEqual: true,
      });
    }
  };

  hasAlternateBilling = () =>
    JSON.stringify(this.props.deliveryAddress) !==
    JSON.stringify(this.props.billingAddress);

  render() {
    const { deliveryAddress, billingAddress, addresses, loading } = this.props;

    const hasAlternateBilling = this.hasAlternateBilling();

    return (
      <div>
        <h3>
          {hasAlternateBilling
            ? ADDRESS_DELIVERY
            : ADDRESS_DELIVERY_AND_BILLING}
        </h3>
        {addresses.length > 0 ? (
          <AddressSelector
            addressType={'delivery'}
            address={deliveryAddress}
            addresses={addresses}
            setAddress={this.onChange}
            isLoading={loading}
          />
        ) : null}
        {deliveryAddress && deliveryAddress.id ? null : (
          <AddressForm
            addressType={'delivery'}
            address={deliveryAddress}
            handleFormInput={this.onChange}
            handleFormFieldBlur={this.onChangeField}
            handleFormSubmitCreate={() => {}}
            handleFormSubmitCreateUpdate={() => {}}
            isLoading={loading}
          />
        )}

        <FormControlLabel
          control={
            <Checkbox
              checked={hasAlternateBilling || false}
              onChange={this.onAlternateBillingChange}
              color="primary"
            />
          }
          label="Abweichende Rechnungsadresse"
        />

        {hasAlternateBilling ? (
          <React.Fragment>
            <h3>{ADDRESS_BILLING}</h3>
            {addresses.length > 0 ? (
              <AddressSelector
                addressType={'billing'}
                address={billingAddress}
                addresses={addresses}
                setAddress={this.onChange}
                isLoading={loading}
              />
            ) : null}

            {billingAddress && billingAddress.id ? null : (
              <AddressForm
                addressType={'billing'}
                address={billingAddress}
                handleFormInput={this.onChange}
                handleFormFieldBlur={this.onChangeField}
                handleFormSubmitCreate={() => {}}
                handleFormSubmitCreateUpdate={() => {}}
                isLoading={loading}
              />
            )}
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

export default Addresses;
