// @flow

import * as React from 'react';
import './FormControl.css';
import './Select.css';

type Props = {
  variant?: string,
  fullWidth?: boolean,
  label?: string,
  value: string,
  disabled?: boolean,
  onChange: Function,
  children: React.Node,
};

export default ({
  fullWidth = true,
  disabled = false,
  variant = '',
  value = '',
  label,
  onChange,
  children,
}: Props) => {
  return (
    <div>
      {label && (
        <label className="form-control__label" htmlFor="">
          {label}
        </label>
      )}

      <select
        value={value}
        disabled={disabled === true}
        onChange={onChange ? ev => onChange(ev) : null}
        className={[
          'form-control form-control--border',
          variant === 'raised' ? 'select__raised' : undefined,
          fullWidth ? 'form-control--fullWidth' : undefined,
        ].join(' ')}
      >
        {children}
      </select>
    </div>
  );
};
