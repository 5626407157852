// @flow

import * as React from 'react';
import {
  BUTTON_PW_RESET,
  FORM_LOGIN_LABEL_EMAIL,
} from '../../constants/constants';
import { requestUserPassword } from '../../actions/user';
import { connect } from 'react-redux';
import { ROUTE_LOGIN } from '../../constants/routes';
import { NavLink } from 'react-router-dom';
import InputField from '../../components/inputs/InputField';
import Button from '../../components/inputs/Button';

type Props = {
  requestUserPassword: Function,
  loading: boolean,
  location: {
    search: string,
  },
};

type State = {
  email: string,
  emailError: false,
  emailErrorMessage: string,
};

export class PasswordReset extends React.Component<Props, State> {
  state = {
    email: '',
    emailError: false,
    emailErrorMessage: '',
  };

  handleEmailChange = (event: any) =>
    this.setState({ email: event.target.value.toLowerCase() });

  handleSubmit = async (event: any) => {
    event.preventDefault();
    const { email } = this.state;

    try {
      await this.props.requestUserPassword({ email }, ROUTE_LOGIN);
    } catch (err) {
      // no error will be displayed here
    }
  };

  render() {
    const { loading, location } = this.props;
    const { email, emailError, emailErrorMessage } = this.state;

    const showBackToLogin = location.search.includes('checkout') === false;

    return (
      <div>
        <form autoComplete="off" onSubmit={this.handleSubmit}>
          <div className="form__group">
            <InputField
              id={'password-change-request'}
              fullWidth
              type={'email'}
              hasError={emailError}
              error={emailErrorMessage}
              placeholder={FORM_LOGIN_LABEL_EMAIL}
              value={email}
              onChange={this.handleEmailChange}
              disabled={loading}
            />
          </div>
          <Button
            type={'submit'}
            variant="raised"
            color="primary"
            disabled={email === '' || loading}
          >
            {BUTTON_PW_RESET}
          </Button>
        </form>

        {showBackToLogin && (
          <NavLink to={ROUTE_LOGIN} className="link link--gutter">
            Zum Login
          </NavLink>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.loading.user,
});

export default connect(
  mapStateToProps,
  { requestUserPassword }
)(PasswordReset);
