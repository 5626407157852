// @flow

import * as React from 'react';
import Button from '../inputs/Button';
import './CheckoutNavigation.css';

type Props = {
  currentStep: any,
  nextAction: any,
  stepCompleted: boolean,
};

const CheckoutNavigation = ({
  currentStep,
  nextAction,
  stepCompleted,
}: Props) => (
  <div className="checkoutNavigation">
    {nextAction ? (
      <Button
        fullWidthMobile
        variant="raised"
        disabled={!stepCompleted}
        onClick={nextAction.action}
      >
        {nextAction.label}
      </Button>
    ) : null}
  </div>
);

export default CheckoutNavigation;
