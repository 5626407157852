import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import addresses from './addresses';
import app from './app';
import checkout from './checkout';
// import checkoutAddress from './checkoutAddress';
// import checkoutPayment from './checkoutPayment';
import deliveries from './deliveries';
import dialog from './dialog';
import loading from './loading';
import invoices from './invoices';
import payments from './payments';
import snackbar from './snackbar';
import plan from './plans';
import subscriptions from './subscriptions';
import user from './user';

const rootReducer = combineReducers({
  addresses,
  app,
  checkout,
  // checkoutAddress,
  // checkoutPayment,
  deliveries,
  dialog,
  invoices,
  loading,
  payments,
  plan,
  router: routerReducer,
  snackbar,
  subscriptions,
  user,
});

export default rootReducer;
