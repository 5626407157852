import { DIALOG_CLOSE, DIALOG_OPEN } from '../constants/actionTypes';

export const setDialogOpen = payload => {
  return {
    type: DIALOG_OPEN,
    payload,
  };
};

export const setDialogClosed = () => {
  return {
    type: DIALOG_CLOSE,
  };
};
