import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '../layout/Paper';
import { showSubscriptionStartOrEndState } from '../../types/subscription';

const styles = () => ({
  actionAlignRight: {
    textAlign: 'right',
  },
  status: {
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  statusActive: {
    color: '#28bf1c',
  },
  statusDelivery: {
    color: '#1890ff',
  },
  statusCanceled: {
    color: '#bf1c1c',
  },
  statusPaused: {
    color: 'gray',
  },
});

const getStateClass = (classes, status) => {
  switch (status) {
    case 'PAUSED':
      return classes.statusPaused;
    case 'DELIVERY':
      return classes.statusDelivery;
    case 'CANCELED':
      return classes.statusCanceled;
    case 'CLOSED':
      return classes.statusClosed;
    default:
      return classes.statusActive;
  }
};

const getStatus = status => {
  switch (status) {
    case 'PAUSED':
      return 'Pausiert';
    default:
      return 'Aktiv';
  }
};

const SubscriptionListItem = ({
  title,
  servingOptionTitle,
  start,
  end,
  imageSrc,
  status,
  classes,
  editComponent,
}) => (
  <Paper>
    <Grid container alignItems={'center'} justify={'space-between'}>
      <Grid item xs={4} sm={2}>
        <img width={60} src={imageSrc} alt={title} />
      </Grid>
      <Grid item xs={8} sm={5}>
        <strong>{title}</strong> für {servingOptionTitle}
        <div>{showSubscriptionStartOrEndState(start, end)}</div>
      </Grid>
      <Grid item xs={6} sm={2}>
        <span
          className={[classes.status, getStateClass(classes, status)].join(' ')}
        >
          {getStatus(status)}
        </span>
      </Grid>
      <Grid item xs={6} sm={3} className={classes.actionAlignRight}>
        {editComponent}
      </Grid>
    </Grid>
  </Paper>
);

SubscriptionListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  start: PropTypes.string,
  end: PropTypes.string,
  status: PropTypes.string,
  servingOptionTitle: PropTypes.string.isRequired,
  editComponent: PropTypes.any,
};

export default withStyles(styles)(SubscriptionListItem);
