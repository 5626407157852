import React, { Component, Fragment } from 'react';
import {
  Elements,
  StripeProvider,
  CardElement,
  injectStripe,
} from 'react-stripe-elements';
import './Payment.css';
import Card from '../payments/Card';
import CheckoutNavigation from './CheckoutNavigation';
import Paper from '../layout/Paper';
import {
  CHECKOUT_TYPE_SUBSCRIPTION,
  CHECKOUT_TYPE_SUBSCRIPTION_TRIAL,
} from '../../redux/checkout';
import CardSelector from '../payments/CardSelector';
import Button from '../inputs/Button';

const createOptions = () => ({
  style: {
    base: {
      fontSize: '18px',
      color: '#424770',
      letterSpacing: '0.025em',
      fontFamily: 'Source Code Pro, Menlo, monospace',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
});

class CardForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      completed: false,
    };
  }

  handleSubmit = ev => {
    ev.preventDefault();

    if (!this.props.stripe) {
      console.log("Stripe.js hasn't loaded yet.");
      throw new Error('no stripe');
    }

    this.props.createStripeCardRequest();

    this.props.stripe
      .createToken()
      .then(payload => {
        if (payload.hasOwnProperty('error')) {
          // todo: display error
          this.props.createStripeCardFailure();
          throw Error(payload.error);
        }

        if (payload.hasOwnProperty('token') === false) {
          // todo: display error
          this.props.createStripeCardFailure();
          throw Error('no stripe token found');
        }

        this.props.setSource({ payload: payload.token });
      })
      .then(() => this.props.navigation.nextAction.action())
      .then(() => this.props.createStripeCardSuccess())
      .catch(err => console.log(err));
  };

  handleChange = change =>
    change.complete
      ? this.setState({ completed: true })
      : this.setState({ completed: false });

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form__group">
          <CardElement
            onChange={this.handleChange}
            hidePostalCode={true}
            elementRef={element => (this._element = element)}
            {...createOptions()}
          />
        </div>

        <div
          style={{
            marginBottom: '1rem',
            textAlign: 'right',
          }}
        >
          <Button
            fullWidthMobile
            variant="raised"
            disabled={this.props.loading.source || !this.state.completed}
            type="submit"
          >
            Weiter
          </Button>
        </div>
      </form>
    );
  }
}

const StripeCardFrorm = injectStripe(CardForm);

class StripePaymentForm extends Component {
  componentWillMount() {
    this.props.loadPaymentSources();
  }

  render() {
    const STRIPE_PK = process.env.REACT_APP_STRIPE_PK;
    const { checkout } = this.props;

    if (!STRIPE_PK) {
      throw Error('Stripe key not found');
    }

    return (
      <div>
        {this.props.paymentSources.length > 0 ? (
          <Fragment>
            <CardSelector
              checkoutSetPaymentCard={this.props.checkoutSetPaymentCard}
              paymentSource={checkout.paymentSource}
              paymentSources={this.props.paymentSources}
              checkoutRemovePaymentSource={
                this.props.checkoutRemovePaymentSource
              }
            />
            {checkout.paymentSource &&
            checkout.paymentSource.hasOwnProperty('card') &&
            checkout.paymentSource.card.hasOwnProperty('id') ? (
              <CheckoutNavigation {...this.props.navigation} noGutter />
            ) : null}
          </Fragment>
        ) : null}

        {checkout.paymentSource &&
        checkout.paymentSource.card &&
        !checkout.paymentSource.card.id ? (
          <Fragment>
            <Paper>
              <Card card={checkout.paymentSource.card} />
            </Paper>
            <div>
              <span
                className="link link--gutter"
                onClick={() => this.props.checkoutRemovePaymentSource()}
              >
                Karte entfernen
              </span>
            </div>
            <CheckoutNavigation {...this.props.navigation} />
          </Fragment>
        ) : null}

        {checkout.paymentSource && checkout.paymentSource.card ? null : (
          <StripeProvider apiKey={STRIPE_PK}>
            <Elements locale={'de'}>
              <StripeCardFrorm {...this.props} />
            </Elements>
          </StripeProvider>
        )}

        {[CHECKOUT_TYPE_SUBSCRIPTION, CHECKOUT_TYPE_SUBSCRIPTION_TRIAL].indexOf(
          checkout.productType
        ) !== -1 ? (
          <p>
            Die Belastung Deiner Kreditkarte findet jeweils{' '}
            <strong>am Freitag um 12 Uhr vor der Lieferwoche statt.</strong> Bis
            zu diesem Zeitpunkt kannst Du Deine Foodbox jederzeit ändern oder
            pausieren.
          </p>
        ) : null}

        {checkout.paymentSource &&
        checkout.paymentSource.card &&
        checkout.paymentSource.card.brand === 'American Express' ? (
          <p>
            Unser Zahlungsprovider Stripe akzeptiert diese Zahlungsmethode
            (American Express) grundsätzlich. Dieser Dienst befindet sich jedoch
            in der Einführungsphase und es kann vorkommen, dass Kreditkarten
            unbegründet abgelehnt werden. Sollte Deine Karte davon betroffen
            sein, wähle bitte eine andere Zahlungsmethode.
          </p>
        ) : null}
      </div>
    );
  }
}

export default StripePaymentForm;
