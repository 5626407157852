// @flow

import * as React from 'react';
import {
  CHECKOUT_TYPE_GIFTBOX_BUY,
  CHECKOUT_TYPE_GIFTBOX_REDEEM,
  CHECKOUT_TYPE_SUBSCRIPTION,
  CHECKOUT_TYPE_SUBSCRIPTION_TRIAL,
  deliveryIntervalOptions,
} from '../../redux/checkout';
import Paper from '../layout/Paper';
import './CheckoutHeader.css';
import { DeliveryOption } from '../../types/delivery';
import CheckoutTitle from './CheckoutTitle';

export const checkoutHeaderHeadline = (
  productType: string,
  title: string
): string => {
  switch (productType) {
    case CHECKOUT_TYPE_GIFTBOX_BUY:
    case CHECKOUT_TYPE_GIFTBOX_REDEEM:
      return `Geschenkbox: ${title}`;
    case CHECKOUT_TYPE_SUBSCRIPTION_TRIAL: {
      return `Probebox: ${title}`;
    }
    default:
      return `Abonnement: ${title}`;
  }
};

const getIntervalText = interval => {
  const intervalObject: ?DeliveryOption = deliveryIntervalOptions.find(
    option => option.interval === interval
  );

  if (!intervalObject) {
    return null;
  }

  return (
    <React.Fragment>, Lieferzyklus: {intervalObject.title}</React.Fragment>
  );
};

type Props = {
  title: string,
  subtitle?: string,
  deliveryDate: string,
  toggleCheckoutDialog: Function,
  imageSrc: string,
  isSubscription: boolean,
  productType: string,
  interval: number,
};

export default ({
  title,
  subtitle,
  deliveryDate,
  toggleCheckoutDialog,
  imageSrc,
  isSubscription,
  productType,
  interval,
}: Props) => (
  <div className="checkoutHeader__wrapper">
    <CheckoutTitle>Deine Bestellung</CheckoutTitle>
    <Paper>
      <div className="checkoutHeader">
        <div className="checkoutHeader__image">
          <img className="image" src={imageSrc} alt={title} />
        </div>
        <div className="checkoutHeader__content">
          <h3 className="checkoutHeader__headline">
            {checkoutHeaderHeadline(productType, title)} für {subtitle}
          </h3>
          <div>
            {productType !== CHECKOUT_TYPE_GIFTBOX_BUY ? (
              <p className="checkoutHeader__text">
                {isSubscription ? 'Dein erster' : null} Liefertermin:{' '}
                {deliveryDate}
                {productType === CHECKOUT_TYPE_SUBSCRIPTION
                  ? getIntervalText(interval)
                  : null}
              </p>
            ) : null}
          </div>
        </div>

        {productType !== CHECKOUT_TYPE_GIFTBOX_BUY && (
          <div className="checkoutHeader__edit" onClick={toggleCheckoutDialog}>
            Editieren
          </div>
        )}
      </div>
    </Paper>
  </div>
);
