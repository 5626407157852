// @flow
import React, { Component, Fragment } from 'react';
import type { Checkout } from '../../redux/checkout';
import type { ServingOption } from '../../redux/plans';
import type { Address, Plan } from '../../types';
import { connect } from 'react-redux';
import {
  checkoutCheckVoucher,
  checkoutClearVoucher,
  checkoutNextStep,
  checkoutPrevStep,
  checkoutRemovePaymentSource,
  checkoutSetAddress,
  checkoutSetAddressField,
  checkoutSetFields,
  checkoutSetPaymentSource,
  checkoutSetStepLocation,
  checkoutSubmitNew,
  checkoutToggleHasAlternateBilling,
  checkoutSetLocationWithTagPush,
  checkoutSetPaymentCard,
} from '../../actions/checkout';
import { dateFormatLong } from '../../utils/dateHelper';
import CheckoutHeader from '../../components/checkout/CheckoutHeader';
import LoginRegister from './LoginRegister';
import CheckoutSteps from '../../components/checkout/CheckoutSteps';
import { BUTTON_NEXT } from '../../constants/constants';
import { setDialogOpen } from '../../actions/dialog';
import { getProductImage } from '../../utils/format';
import Paper from '../../components/layout/Paper';
import { HeadlineTitle } from '../../components/typography/Headlines';
import Addresses from '../../components/checkout/Addresses';
import Confirmation from '../../components/checkout/Confirmation';
import StripePaymentForm from '../../components/checkout/StripePaymentForm';
import './Checkout.css';
import { isValidAddress } from '../../redux/addresses';
import type { Source } from '../../redux/payments';
import {
  createStripeCardFailure,
  createStripeCardRequest,
  createStripeCardSuccess,
  loadPaymentsSources,
} from '../../actions/payment';
import { changeLocation } from '../../actions/router';
import {
  CHECKOUT_TYPE_GIFTBOX_BUY,
  CHECKOUT_TYPE_GIFTBOX_REDEEM,
  CHECKOUT_TYPE_SUBSCRIPTION,
  CHECKOUT_TYPE_SUBSCRIPTION_TRIAL,
} from '../../redux/checkout';
import {
  CheckoutGiftBoxSuccess,
  CheckoutSubscriptionSuccess,
  CheckoutTrialSuccess,
  CheckoutVoucherSuccess,
} from '../../components/checkout/CheckoutSuccess';
import { loadAddresses } from '../../actions/address';
import PaymentTypeSelector from '../payment/PaymentTypeSelector';
import BrainTreePaymentProvider from '../payment/BrainTreePaymentProvider';
import { paymentTypes } from '../../types/payment';

type Props = {
  checkout: Checkout,
  plans: Plan[],
  isAuthorized: boolean,
  currentStepIndex: number,
  loading: any,
  stepsCompleted: any,
  currentStep: any,
  nextStep: any,
  prevStep: any,
  match: any,
  selectedPlan: Plan,
  checkoutSetFields: Function,
  setDialogOpen: Function,
  checkoutNextStep: Function,
  checkoutSetAddressField: Function,
  checkoutToggleHasAlternateBilling: Function,
  checkoutSetStepLocation: Function,
  checkoutCheckVoucher: Function,
  checkoutClearVoucher: Function,
  checkoutSetPaymentSource: Function,
  checkoutSubmitNew: Function,
  checkoutSetAddress: Function,
  checkoutSetLocationWithTagPush: Function,
  createStripeCardRequest: Function,
  createStripeCardSuccess: Function,
  createStripeCardFailure: Function,
  checkoutSetPaymentCard: Function,
  checkoutRemovePaymentSource: Function,
  paymentSources: Function,
  loadAddresses: Function,
  loadPaymentsSources: Function,
  changeLocation: Function,
  user: any,
  selectedSource: Source,
  hasError: boolean,
  addresses: Address,
  nextLocation: any,
};

type State = {
  hasError: boolean,
};

const stepAddressIsCompleted = (checkout: Checkout): boolean => {
  const billingAddressIsValid = isValidAddress(
    checkout.addresses.billing,
    checkout.addresses.billing &&
      checkout.addresses.billing.hasOwnProperty('id') === false
  );

  if (checkout.hasOnlyBillingAddress) {
    return billingAddressIsValid;
  }

  const deliveryAddressIsValid = isValidAddress(
    checkout.addresses.delivery,
    checkout.addresses.delivery &&
      checkout.addresses.delivery.hasOwnProperty('id') === false
  );

  return checkout.hasAlternateBilling
    ? billingAddressIsValid && deliveryAddressIsValid
    : deliveryAddressIsValid;
};

const stepPaymentIsCompleted = (checkout: Checkout): boolean => {
  if (checkout.sourceId) {
    return true;
  }

  if (checkout.paymentType === paymentTypes.creditCard) {
    if (checkout.paymentSource.hasOwnProperty('card')) {
      // check if paymentSource has token and card object
      if (
        checkout.paymentSource.hasOwnProperty('token') ||
        (checkout.paymentSource.card &&
          checkout.paymentSource.card.hasOwnProperty('id'))
      ) {
        return true;
      }
    }
  }

  if (checkout.paymentType === paymentTypes.paypal) {
    if (checkout.paymentBrainTreeClientToken) {
      return true;
    }
  }

  return false;
};

export class CheckoutContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static redirectUserIfAuthorized(props: Props) {
    if (
      (props.isAuthorized && props.match.params.step === undefined) ||
      (props.isAuthorized && props.match.params.step === 'auth')
    ) {
      props.checkoutNextStep(`/checkout/address`);
    }
  }

  componentDidCatch(error: any, info: any) {
    this.setState({ hasError: true });
    console.log(error, info);
  }

  componentWillReceiveProps(nextProps: Props) {
    CheckoutContainer.redirectUserIfAuthorized(nextProps);
  }

  componentWillMount() {
    const { checkout, stepsCompleted, currentStep } = this.props;
    const lastCompletedStep = Object.values(stepsCompleted).indexOf(false);
    if (lastCompletedStep > -1) {
      this.props.changeLocation(
        `/checkout/${checkout.steps[lastCompletedStep].key}`
      );
      return;
    }

    if (!currentStep) {
      this.props.changeLocation(
        `/checkout/${checkout.steps[checkout.steps.length - 1].key}`
      );
    }
  }

  render() {
    const {
      currentStep,
      nextStep,
      selectedPlan,
      currentStepIndex,
      stepsCompleted,
      nextLocation,
    } = this.props;

    const checkout: Checkout = this.props.checkout;

    if (this.state.hasError) {
      return (
        <div>
          Bei der Bestellung ist leider ein Fehler aufgetreten. Bitte melde dich
          beim Support.
        </div>
      );
    }

    if (!currentStep) {
      return <div>Sorry, the step was not found</div>;
    }

    if (currentStep.key === 'success') {
      if (checkout.productType === CHECKOUT_TYPE_SUBSCRIPTION) {
        return (
          <CheckoutSubscriptionSuccess
            changeLocation={this.props.changeLocation}
          />
        );
      }

      if (checkout.productType === CHECKOUT_TYPE_SUBSCRIPTION_TRIAL) {
        return (
          <CheckoutTrialSuccess changeLocation={this.props.changeLocation} />
        );
      }

      if (checkout.productType === CHECKOUT_TYPE_GIFTBOX_BUY) {
        return (
          <CheckoutGiftBoxSuccess changeLocation={this.props.changeLocation} />
        );
      }

      if (checkout.productType === CHECKOUT_TYPE_GIFTBOX_REDEEM) {
        return (
          <CheckoutVoucherSuccess changeLocation={this.props.changeLocation} />
        );
      }
    }

    if (
      checkout.planId === undefined ||
      selectedPlan === undefined ||
      checkout.servingOptionId === undefined
    ) {
      return <div>Missing required parameters for checkout.</div>;
    }

    const selectedServingOption: ServingOption =
      selectedPlan.serving_options.find(
        s => s.id === checkout.servingOptionId
      ) || selectedPlan.serving_options[0];

    const checkoutNavigationProps = {
      currentStep,
      nextStep,
      nextLocation,
      stepCompleted: stepsCompleted[currentStep.key],
      nextAction: {
        action: () =>
          this.props.checkoutSetLocationWithTagPush(
            nextStep.key,
            currentStep.key
          ),
        label: BUTTON_NEXT,
      },
    };

    const Header = () => (
      <CheckoutHeader
        productType={checkout.productType}
        imageSrc={getProductImage(selectedPlan.id)}
        title={selectedPlan.title}
        subtitle={selectedServingOption.title} // $FlowFixMe // todo: check
        deliveryDate={dateFormatLong(checkout.firstDeliveryDate)} // $FlowFixMe // todo: check
        isSubscription={checkout.isSubscription}
        toggleCheckoutDialog={() =>
          this.props.setDialogOpen({ target: 'confirmation' })
        } // $FlowFixMe // todo: check
        interval={checkout.interval}
      />
    );

    return (
      <div>
        {currentStep.key !== 'confirmation' ? <Header /> : null}

        <CheckoutSteps
          index={currentStepIndex}
          steps={checkout.steps}
          stepsCompleted={stepsCompleted}
        />

        {currentStep.key === 'auth' ? (
          <LoginRegister
            postAction={checkoutNavigationProps.nextAction.action}
            redirect={nextLocation}
          />
        ) : null}

        {currentStep.key === 'address' ? (
          <Addresses
            addresses={this.props.addresses}
            checkout={checkout}
            navigation={checkoutNavigationProps}
            stepComplete={stepsCompleted.address}
            checkoutSetAddressBillingId={() => {}}
            checkoutSetAddressField={this.props.checkoutSetAddressField}
            checkoutToggleHasAlternateBilling={
              this.props.checkoutToggleHasAlternateBilling
            }
            checkoutSetAddress={this.props.checkoutSetAddress}
            loadAddresses={this.props.loadAddresses}
          />
        ) : null}

        {currentStep.key === 'payment' ? (
          <div className="checkout__step">
            <PaymentTypeSelector />

            {checkout.paymentType === paymentTypes.paypal ? (
              <React.Fragment>
                <BrainTreePaymentProvider
                  checkout={checkout}
                  navigation={checkoutNavigationProps}
                />
                <p>
                  Die Weiterleitung zu PayPal erfolgt am Ende des
                  Bestellvorgangs zur Zahlungsabwicklung. Die Bestellung ist
                  danach erfolgreich abgeschlossen.
                </p>
              </React.Fragment>
            ) : null}

            {checkout.paymentType === paymentTypes.creditCard && (
              <StripePaymentForm
                checkoutSetPaymentCard={this.props.checkoutSetPaymentCard}
                checkout={checkout}
                navigation={checkoutNavigationProps}
                setSource={this.props.checkoutSetPaymentSource}
                createStripeCardRequest={this.props.createStripeCardRequest}
                createStripeCardSuccess={this.props.createStripeCardSuccess}
                createStripeCardFailure={this.props.createStripeCardFailure}
                checkoutRemovePaymentSource={
                  this.props.checkoutRemovePaymentSource
                }
                loading={this.props.loading}
                paymentSources={this.props.paymentSources}
                loadPaymentSources={this.props.loadPaymentsSources}
              />
            )}

            {[
              CHECKOUT_TYPE_SUBSCRIPTION,
              CHECKOUT_TYPE_SUBSCRIPTION_TRIAL,
            ].indexOf(checkout.productType) !== -1 ? (
              <p>
                Wenn Du einen <strong>Rabattcode</strong> hast, kannst Du diesen
                im nächsten Schritt einlösen.
              </p>
            ) : null}
          </div>
        ) : null}

        {currentStep.key === 'confirmation' ? (
          <Fragment>
            <HeadlineTitle title={'Bestätigung'} color={'gray'} />
            <Paper extraClassName={'paper--medium'} desktopOnly>
              <Confirmation
                selectedPlan={selectedPlan}
                selectedSource={this.props.selectedSource}
                checkoutCheckVoucher={this.props.checkoutCheckVoucher}
                checkoutClearVoucher={this.props.checkoutClearVoucher}
                checkoutToggleHasAlternateBilling={
                  this.props.checkoutToggleHasAlternateBilling
                }
                setStep={this.props.checkoutSetStepLocation}
                checkout={checkout}
                checkoutSubmitNew={this.props.checkoutSubmitNew}
                header={<Header callToActionAsBlock={true} />}
                stepsCompleted={
                  stepsCompleted.loginRegister &&
                  stepsCompleted.address &&
                  stepsCompleted.payment
                }
                loading={this.props.loading.checkout}
              />
            </Paper>
          </Fragment>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const user = state.user;
  const checkout = state.checkout;

  const stepAuthCompleted = !!user.id;
  const stepAddressCompleted = stepAddressIsCompleted(checkout);
  const stepPaymentCompleted = stepPaymentIsCompleted(checkout);

  const stepsCompleted = {
    auth: stepAuthCompleted,
    address: stepAddressCompleted,
    payment: stepPaymentCompleted,
    confirmation:
      stepAuthCompleted && stepAddressCompleted && stepPaymentCompleted,
  };

  // get current step from URL
  const location = ownProps.match.params.step;
  const currentStepIndex =
    checkout.steps.findIndex(s => s.key === location) || 0;
  const currentStep = checkout.steps[currentStepIndex];
  const nextStep = checkout.steps[currentStepIndex + 1];
  const prevStep = checkout.steps[currentStepIndex - 1];

  const nextLocation = nextStep ? `/checkout/${nextStep.key}` : null;

  return {
    addresses: state.addresses,
    plans: state.plan,
    selectedPlan: state.plan.find(p => p.id === state.checkout.planId),
    isLoading: state.loading.checkout,
    loading: state.loading,
    isAuthorized: state.user.isAuthorized,
    dialog: state.dialog,
    paymentSources: state.payments,
    selectedSource: state.payments.find(s => s.id === state.checkout.sourceId),
    checkout,
    user,
    nextLocation,
    stepsCompleted,
    currentStepIndex,
    currentStep,
    nextStep,
    prevStep,
  };
};

const mapDispatchToProps = {
  checkoutNextStep,
  checkoutPrevStep,
  checkoutSetStepLocation,
  checkoutSetFields,
  checkoutSetAddressField,
  checkoutToggleHasAlternateBilling,
  setDialogOpen,
  checkoutCheckVoucher,
  checkoutClearVoucher,
  checkoutSetPaymentSource,
  checkoutSubmitNew,
  checkoutSetAddress,
  createStripeCardRequest,
  createStripeCardSuccess,
  createStripeCardFailure,
  checkoutSetPaymentCard,
  checkoutRemovePaymentSource,
  checkoutSetLocationWithTagPush,
  changeLocation,
  loadAddresses,
  loadPaymentsSources,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckoutContainer);
