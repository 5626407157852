import * as React from 'react';
import PropTypes from 'prop-types';
import logo from '../../static/svg/juts_logo.svg';
import logoutIcon from '../../static/svg/logout.svg';
import userIcon from '../../static/svg/user-icon.svg';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { LANDING_PAGE_URL } from '../../index';
import './PageHeader.css';
import Hidden from '@material-ui/core/Hidden';

class PageHeader extends React.Component {
  state = {
    navOpen: false,
  };

  handleMenuClick = () => this.setState({ navOpen: !this.state.navOpen });

  render() {
    const { isAuthorized, changeLocation, userLogout } = this.props;

    const { navOpen } = this.state;

    return (
      <div className="page-header">
        <div className="page-header__bar">
          {isAuthorized ? (
            <img src={logo} className="page-header__logo" alt="logo" />
          ) : (
            <a href={LANDING_PAGE_URL}>
              <img src={logo} className="page-header__logo" alt="logo" />
            </a>
          )}
          <div className="page-header__nav">
            <div
              className={[
                'page-header__nav-inner',
                navOpen ? 'page-header__nav-inner--open' : undefined,
              ].join(' ')}
            >
              <a className={'page-header__link'} href={`${LANDING_PAGE_URL}`}>
                Startseite
              </a>
              <a
                className={'page-header__link'}
                href={`${LANDING_PAGE_URL}/aktuelle-rezepte`}
              >
                Aktuelle Rezepte
              </a>
              <a
                className={'page-header__link'}
                href={`${LANDING_PAGE_URL}/gutschein/`}
              >
                Geschenkboxen
              </a>
            </div>
            {isAuthorized ? (
              <img
                className="page-header__link--icon page-header__logout"
                onClick={() => userLogout()}
                src={logoutIcon}
                title="Abmelden"
                alt="Abmelden"
                height={36}
                width={40}
                role="button"
              />
            ) : (
              <img
                className="page-header__link--icon page-header__logout"
                onClick={() => changeLocation('/login')}
                src={userIcon}
                title="Anmelden"
                alt="Anmelden"
                height={40}
                width={40}
                role="button"
              />
            )}
            <Hidden mdUp>
              <div
                role="button"
                className={[
                  'page-header__mobile',
                  navOpen ? 'page-header__mobile--open' : undefined,
                ].join(' ')}
              >
                {!navOpen && <MenuIcon onClick={this.handleMenuClick} />}
                {navOpen && <CloseIcon onClick={this.handleMenuClick} />}
              </div>
            </Hidden>
          </div>
        </div>
      </div>
    );
  }
}

PageHeader.propTypes = {
  showStoreButton: PropTypes.bool,
  isAuthorized: PropTypes.bool,
  userLogout: PropTypes.func.isRequired,
  changeLocation: PropTypes.func.isRequired,
};

export default PageHeader;
