import {
  CHECKOUT_RESET_VOUCHER,
  CHECKOUT_SET_ADDRESS_BILLING_ID,
  CHECKOUT_SET_ADDRESS_DELIVERY_ID,
  CHECKOUT_SET_DELIVERY_DATE,
  CHECKOUT_SET_DIALOG_CLOSE,
  CHECKOUT_SET_FIELDS,
  CHECKOUT_SET_HAS_ALTERNATE_BILLING,
  CHECKOUT_SET_SOURCE_ERROR,
  CHECKOUT_SET_SOURCE_ID,
  CHECKOUT_SET_STEP,
  CHECKOUT_START,
  CHECKOUT_START_REDEEM,
  CHECKOUT_SUBMIT,
  DIALOG_CLOSE,
} from '../constants/actionTypes';
import { push } from 'react-router-redux';
import { callApi, fetchApi } from '../middleware/api';
import {
  ADDRESSES_CREATE_FAILURE,
  ADDRESSES_CREATE_REQUEST,
  ADDRESSES_CREATE_SUCCESS,
} from './address';
import { loadPaymentsSources } from './payment';
import {
  VOUCHER_DISCOUNT_FAILURE,
  VOUCHER_DISCOUNT_REQUEST,
  VOUCHER_DISCOUNT_SUCCESS,
} from './voucher';
import { appChangeLocation } from './router';
import {
  CHECKOUT_TYPE_GIFTBOX_BUY,
  CHECKOUT_TYPE_GIFTBOX_REDEEM,
  CHECKOUT_TYPE_SUBSCRIPTION,
  CHECKOUT_TYPE_SUBSCRIPTION_TRIAL,
} from '../redux/checkout';
import Cookies from 'js-cookie';
import dataLayerPush from '../utils/dataLayerPush';
import { paymentTypes } from '../types/payment';

export const checkoutNextStep = redirect => dispatch =>
  Promise.resolve().then(() => dispatch(push(redirect)));

export const checkoutPrevStep = redirect => dispatch =>
  Promise.resolve().then(() => dispatch(push(redirect)));

export const checkoutSetStepLocation = (step, path) => dispatch =>
  Promise.resolve()
    .then(() => dispatch(checkoutSetStep(step)))
    .then(() => dispatch(push(`/checkout/${path}`))); // todo

const pushDataLayerEvent = (step, getState) => {
  const getDataLayerEventFromStep = step => {
    switch (step) {
      case 'auth':
        return 'SignupCompleted';
      case 'address':
        return 'ShippingCompleted';
      case 'payment':
        return 'PaymentCompleted';
      case 'confirmation':
        return 'OrderCompleted';
      case CHECKOUT_TYPE_SUBSCRIPTION_TRIAL:
        return 'Purchased_Trial';
      case CHECKOUT_TYPE_SUBSCRIPTION:
        return 'Purchased_Subscription';
      case CHECKOUT_TYPE_GIFTBOX_BUY:
        return 'Purchased_Voucher';
      default:
        return '';
    }
  };

  if (step === 'auth') {
    if (window && window.hasOwnProperty('mt')) {
      const { email, isNewUser } = getState().user;
      if (isNewUser) {
        window.mt('send', 'pageview', { email });
      }
    }
  }

  if (step === 'address') {
    if (window && window.hasOwnProperty('mt')) {
      const { delivery, billing } = getState().checkout.addresses;
      const { isNewUser } = getState().user;
      const address = billing ? billing : delivery;
      if (isNewUser) {
        window.mt('send', 'pageview', {
          firstname: address.first_name,
          lastname: address.last_name,
        });
      }
    }
  }

  try {
    if (step === 'address' || step === 'payment' || step === 'confirmation') {
      Cookies.set('jco', step, {
        domain: '.juts.ch',
        expires: 14,
        secure: true,
      });
    }

    if (
      step === CHECKOUT_TYPE_SUBSCRIPTION_TRIAL ||
      step === CHECKOUT_TYPE_SUBSCRIPTION ||
      step === CHECKOUT_TYPE_GIFTBOX_BUY ||
      step === CHECKOUT_TYPE_GIFTBOX_REDEEM
    ) {
      Cookies.remove('jco');
    }
  } catch (err) {}

  return dataLayerPush(getDataLayerEventFromStep(step));
};

export const checkoutSetLocationWithTagPush = (path, step) => (
  dispatch,
  getState
) => {
  const fullPath = `/checkout/${path}`;

  return Promise.resolve()
    .then(() => dispatch(appChangeLocation(fullPath)))
    .then(() => pushDataLayerEvent(step, getState));
};

export const checkoutCreateAddress = (data, addressType) => async (
  dispatch,
  getState
) => {
  dispatch({ type: ADDRESSES_CREATE_REQUEST });
  try {
    return await callApi(
      '/api/v1/addresses/',
      'POST',
      getState().app.accessToken,
      data
    )
      .then(response => {
        dispatch({ type: ADDRESSES_CREATE_SUCCESS, payload: response });
        return response;
      })
      .then(
        response =>
          addressType === 'delivery'
            ? dispatch(checkoutSetAddressDeliveryId(response.id))
            : dispatch(checkoutSetAddressBillingId(response.id))
      )
      .then(() => dispatch({ type: DIALOG_CLOSE }));
  } catch (err) {
    dispatch({ type: ADDRESSES_CREATE_FAILURE, payload: err });
  }
};

export const checkoutCheckVoucher = code => async (dispatch, getState) => {
  dispatch({ type: VOUCHER_DISCOUNT_REQUEST });
  try {
    return await callApi(
      `/api/v1/voucher/${code}/`,
      'GET',
      getState().app.accessToken,
      code
    ).then(response =>
      dispatch({
        type: VOUCHER_DISCOUNT_SUCCESS,
        payload: { code, ...response },
      })
    );
  } catch (err) {
    dispatch({ type: VOUCHER_DISCOUNT_FAILURE, payload: err });
  }
};

export const CHECKOUT_CREATE_GIFTBOX_REQUEST =
  'CHECKOUT_CREATE_GIFTBOX_REQUEST';
export const CHECKOUT_CREATE_GIFTBOX_SUCCESS =
  'CHECKOUT_CREATE_GIFTBOX_SUCCESS';
export const CHECKOUT_CREATE_GIFTBOX_FAILURE =
  'CHECKOUT_CREATE_GIFTBOX_FAILURE';

export const checkoutClearVoucher = () => ({
  type: CHECKOUT_RESET_VOUCHER,
});

export const CHECKOUT_CREATE_CARD_REQUEST = 'CHECKOUT_CREATE_CARD_REQUEST';
export const CHECKOUT_CREATE_CARD_SUCCESS = 'CHECKOUT_CREATE_CARD_SUCCESS';
export const CHECKOUT_CREATE_CARD_FAILURE = 'CHECKOUT_CREATE_CARD_FAILURE';

export const checkoutCreateCard = data => async (dispatch, getState) => {
  dispatch({ type: CHECKOUT_CREATE_CARD_REQUEST });
  try {
    return await callApi(
      '/api/v1/payment/register/',
      'POST',
      getState().app.accessToken,
      data
    )
      .then(response => dispatch(checkoutSetSourceId(response.id)))
      .then(() => dispatch({ type: CHECKOUT_CREATE_CARD_SUCCESS }))
      .then(() => dispatch(loadPaymentsSources()))
      .then(() => dispatch({ type: DIALOG_CLOSE }));
  } catch (err) {
    dispatch({ type: CHECKOUT_CREATE_CARD_FAILURE, payload: err });
  }
};

const getPaymentMethod = checkout => {
  if (checkout.paymentType === paymentTypes.creditCard) {
    if (
      checkout.productType !== CHECKOUT_TYPE_GIFTBOX_REDEEM &&
      checkout.paymentSource.card &&
      checkout.paymentSource.card.id
    ) {
      return { payment_source: checkout.paymentSource.card.id };
    }

    return {
      token: checkout.paymentSource.token.id,
      livemode: checkout.paymentSource.token.livemode,
    };
  }

  if (checkout.paymentType === paymentTypes.paypal) {
    return {
      nonce: checkout.paymentSource.nonce,
    };
  }
};

const getPayload = checkout => {
  const redirect = `success`;
  const { productType } = checkout;
  const paymentSource = getPaymentMethod(checkout);

  if (productType === CHECKOUT_TYPE_GIFTBOX_REDEEM) {
    return {
      action: {
        endpoint: '/api/v1/subscriptions/giftbox/',
        redirect,
      },
      payload: {
        delivery_address: checkout.addresses.delivery,
        code: checkout.giftBoxVoucherCode,
        start: checkout.firstDeliveryDate,
      },
    };
  }

  if (!paymentSource) {
    throw new Error('payment source not found');
  }

  if (
    productType === CHECKOUT_TYPE_SUBSCRIPTION ||
    productType === CHECKOUT_TYPE_SUBSCRIPTION_TRIAL
  ) {
    return {
      action: {
        endpoint: '/api/v1/checkoutdemo/',
        redirect,
      },
      payload: {
        plan: checkout.planId,
        serving_option: checkout.servingOptionId,
        interval: checkout.interval,
        delivery_address: checkout.addresses.delivery,
        billing_address: checkout.addresses.billing,
        start: checkout.firstDeliveryDate,
        is_trial: checkout.productType === CHECKOUT_TYPE_SUBSCRIPTION_TRIAL,
        vouchers:
          checkout.discountVoucherValid === true
            ? [checkout.discountVoucherCode]
            : [],
        ...paymentSource,
      },
    };
  }

  if (productType === CHECKOUT_TYPE_GIFTBOX_BUY) {
    return {
      action: {
        endpoint: '/api/v1/giftbox/',
        redirect,
      },
      payload: {
        plan: checkout.planId,
        serving_option: checkout.servingOptionId,
        billing_address: checkout.addresses.billing,
        message: Object.values(checkout.message).some(v => v !== undefined)
          ? checkout.message
          : undefined,
        ...paymentSource,
      },
    };
  }

  // else raise error?
};

export const CHECKOUT_NEW_SUBMIT_REQUEST = 'CHECKOUT_NEW_SUBMIT_REQUEST';
export const CHECKOUT_NEW_SUBMIT_SUCCESS = 'CHECKOUT_NEW_SUBMIT_SUCCESS';
export const CHECKOUT_NEW_SUBMIT_FAILURE = 'CHECKOUT_NEW_SUBMIT_FAILURE';

export const checkoutSubmitNew = brainTreeResponse => async (
  dispatch,
  getState
) => {
  dispatch({ type: CHECKOUT_NEW_SUBMIT_REQUEST });
  const checkout = getState().checkout;
  const c = brainTreeResponse
    ? {
        ...checkout,
        paymentSource: {
          nonce: brainTreeResponse.nonce,
        },
      }
    : checkout;

  const { payload, action } = getPayload(c);

  if (!action.endpoint) {
    return;
  }

  try {
    await fetchApi({
      endpoint: action.endpoint,
      method: 'POST',
      authorization: getState().app.accessToken,
      payload: payload,
    });

    dispatch({ type: CHECKOUT_NEW_SUBMIT_SUCCESS });
    return Promise.resolve()
      .then(() =>
        dispatch(
          checkoutSetLocationWithTagPush(action.redirect, 'confirmation')
        )
      )
      .then(() => pushDataLayerEvent(checkout.productType))
      .then(() => dispatch(checkoutSubmit()));
  } catch (err) {
    if (err && err.error && err.error.detail) {
      dispatch({
        type: CHECKOUT_NEW_SUBMIT_FAILURE,
        payload: { message: err.error.detail },
      });
    } else {
      // unknown error
      dispatch({
        type: CHECKOUT_NEW_SUBMIT_FAILURE,
        payload: {
          message:
            'Bei der Bestellung ist leider ein Fehler aufgetreten. Bitte melde dich beim Support.',
        },
      });
    }
  }
};

export const checkoutSetDeliveryDate = payload => ({
  type: CHECKOUT_SET_DELIVERY_DATE,
  payload,
});

export const checkoutSetStep = payload => ({
  type: CHECKOUT_SET_STEP,
  payload,
});

export const checkoutSubmit = () => ({
  type: CHECKOUT_SUBMIT,
});

export const checkoutStart = payload => ({
  type: CHECKOUT_START,
  payload,
});

export const checkoutStartRedeemGiftBox = payload => ({
  type: CHECKOUT_START_REDEEM,
  payload,
});

export const checkoutSetSourceId = payload => ({
  type: CHECKOUT_SET_SOURCE_ID,
  payload,
});

export const checkoutSetSourceError = payload => ({
  type: CHECKOUT_SET_SOURCE_ERROR,
  payload,
});

export const checkoutSetFields = payload => ({
  type: CHECKOUT_SET_FIELDS,
  payload,
});

export const checkoutSetAddressDeliveryId = payload => ({
  type: CHECKOUT_SET_ADDRESS_DELIVERY_ID,
  payload,
});

export const checkoutSetAddressBillingId = payload => ({
  type: CHECKOUT_SET_ADDRESS_BILLING_ID,
  payload,
});

export const checkoutSetDialogClose = () => ({
  type: CHECKOUT_SET_DIALOG_CLOSE,
});

export const checkoutToggleHasAlternateBilling = () => ({
  type: CHECKOUT_SET_HAS_ALTERNATE_BILLING,
});

// address
export const CHECKOUT_SET_ADDRESS_FIELD = 'CHECKOUT_SET_ADDRESS_FIELD';
export const checkoutSetAddressField = payload => ({
  type: CHECKOUT_SET_ADDRESS_FIELD,
  payload,
});

export const CHECKOUT_SET_ADDRESS = 'CHECKOUT_SET_ADDRESS';
export const checkoutSetAddress = payload => ({
  type: CHECKOUT_SET_ADDRESS,
  payload,
});

// payment
export const CHECKOUT_SET_PAYMENT_SOURCE = 'CHECKOUT_SET_PAYMENT_SOURCE';
export const checkoutSetPaymentSource = payload => ({
  type: CHECKOUT_SET_PAYMENT_SOURCE,
  payload,
});

export const CHECKOUT_REMOVE_PAYMENT_SOURCE = 'CHECKOUT_REMOVE_PAYMENT_SOURCE';
export const checkoutRemovePaymentSource = () => ({
  type: CHECKOUT_REMOVE_PAYMENT_SOURCE,
});

export const CHECKOUT_SET_PAYMENT_CARD = 'CHECKOUT_SET_PAYMENT_CARD';
export const checkoutSetPaymentCard = payload => ({
  type: CHECKOUT_SET_PAYMENT_CARD,
  payload,
});

export const CHECKOUT_SET_PAYMENT_TYPE = 'CHECKOUT_SET_PAYMENT_TYPE';
export const checkoutSetPaymentType = payload => ({
  type: CHECKOUT_SET_PAYMENT_TYPE,
  payload,
});

export const CHECKOUT_SET_BRAIN_TREE_NONCE = 'CHECKOUT_SET_BRAIN_TREE_NONCE';
export const checkoutSetBrainTreeNonce = payload => ({
  type: CHECKOUT_SET_BRAIN_TREE_NONCE,
  payload,
});
