import React from 'react';
import Grid from '@material-ui/core/Grid';
import { LANDING_PAGE_URL } from '../../index';
import logoFacebook from '../../static/svg/logo_facebook.svg';
import './PageFooter.css';

const PageFooter = () => (
  <footer className="page-footer">
    <div className="container page-footer__inner">
      <Grid container>
        <Grid item xs={12} sm={6} md={3}>
          <div className="page-footer__title">JUTS AG</div>
          <div className="page-footer__title page-footer__title--gap">
            Bahnhofstrasse 92 <br />
            8500 Frauenfeld
          </div>
          <div className="page-footer__title">
            Telefon +41 52 722 11 44 <br />
            E-Mail: kontakt@juts.ch
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <div className="page-footer__title">Service</div>
          <nav>
            <a
              className={'page-footer__link page-footer__link--block'}
              href={`${LANDING_PAGE_URL}/#foodboxen`}
            >
              Unsere Foodboxen
            </a>

            <a
              className={'page-footer__link page-footer__link--block'}
              href={`/`}
            >
              Mein Konto
            </a>

            <a
              className={'page-footer__link page-footer__link--block'}
              href={`${LANDING_PAGE_URL}/faq`}
            >
              Häufige Fragen
            </a>

            <a
              className={'page-footer__link page-footer__link--block'}
              href={`${LANDING_PAGE_URL}/rezepte/`}
            >
              Rezepte-Archiv
            </a>

            <a
              className={'page-footer__link page-footer__link--block'}
              href={`${LANDING_PAGE_URL}/SWISSNESS/`}
            >
              SWISSNESS
            </a>
          </nav>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <div className="page-footer__title">Unternehmen</div>
          <nav>
            <a
              className={'page-footer__link page-footer__link--block'}
              target={'_blank'}
              href={`${LANDING_PAGE_URL}/ueber-juts/`}
            >
              Über Juts
            </a>
            <a
              className={'page-footer__link page-footer__link--block'}
              target={'_blank'}
              href={`${LANDING_PAGE_URL}/kontakt/`}
            >
              Kontakt
            </a>
            <a
              className={'page-footer__link page-footer__link--block'}
              target={'_blank'}
              href={`${LANDING_PAGE_URL}/legal/agb/`}
            >
              AGB
            </a>
            <a
              className={'page-footer__link page-footer__link--block'}
              target={'_blank'}
              href={`${LANDING_PAGE_URL}/legal/disclaimer/`}
            >
              Disclaimer
            </a>
            <a
              className={'page-footer__link page-footer__link--block'}
              target={'_blank'}
              href={`${LANDING_PAGE_URL}/legal/impressum/`}
            >
              Impressum
            </a>
          </nav>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div>
            <div className="page-footer__title">Kundenservice</div>
            <div className="page-footer__title">
              MO bis FR: 09.00 – 16.00 Uhr
            </div>
            <a href="tel:+41527221144" className="page-footer__phone">
              052 722 11 44
            </a>
          </div>
        </Grid>
        <Grid item xs={12} sm={1}>
          <a href="https://www.facebook.com/jutsfoodbox" target={'_blank'}>
            <img
              src={logoFacebook}
              alt="Folge uns bei Facebook"
              className="page-footer__fb-logo"
            />
          </a>
        </Grid>
      </Grid>
    </div>
    <div className="page-footer__copyright">
      ® 2018 JUTS. All Rights Reserved.
    </div>
  </footer>
);

export default PageFooter;
