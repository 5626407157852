import React from 'react';
import { connect } from 'react-redux';
import {
  checkoutCheckVoucher,
  checkoutSetFields,
  checkoutStart,
} from '../../actions/checkout';
import CheckoutContainer from './CheckoutContainer';
import type { Plan } from '../../redux/plans';
import { CHECKOUT_TYPE_SUBSCRIPTION } from '../../redux/checkout';
import {
  getSubscriptionFromUrlParams,
  redirectToLandingPageWithError,
} from '../../utils/checkout';
import { getContactSourceFromQueryParams } from '../../utils/contactSource';
import { setContactSource } from '../../actions/user';

const mapStateToProps = state => ({
  plans: state.plan,
  isAuthorized: state.user.isAuthorized,
});

const mapDispatchToProps = {
  checkoutSetFields,
  checkoutStart,
  setContactSource,
  checkoutCheckVoucher,
};

const ViewSubscriptionCheckout = WrappedComponent =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          hasError: false,
        };
      }

      handleVoucherCodeCheck(code) {
        this.props.checkoutCheckVoucher(code);
      }

      componentWillMount() {
        const {
          checkoutStart,
          isAuthorized,
          checkoutSetFields,
          setContactSource,
          plans,
        } = this.props;

        checkoutStart(isAuthorized);
        setContactSource(getContactSourceFromQueryParams());

        const urlParams = new URLSearchParams(window.location.search);
        const useDefaultParams = urlParams.has('default');
        const {
          planId,
          quantity,
          interval,
          deliveryDate,
          hasError,
          code,
        } = getSubscriptionFromUrlParams(urlParams, useDefaultParams, true);

        if (hasError) {
          this.setState({ hasError: true });
          return;
        }

        if (code) {
          this.handleVoucherCodeCheck(code);
        }

        const selectedPlan: Plan = plans.find(p => p.id === planId) || plans[0];
        const selectedServingOption =
          selectedPlan.serving_options.find(s => s.quantity === quantity) ||
          selectedPlan.serving_options[0];

        const start =
          selectedPlan.delivery_dates.indexOf(deliveryDate) === -1
            ? selectedPlan.delivery_dates[0]
            : deliveryDate;

        const checkout = {
          servingOptionId: selectedServingOption.id,
          firstDeliveryDate: start,
          interval: interval,
          planId: planId,
          productType: CHECKOUT_TYPE_SUBSCRIPTION,
          isSubscription: true,
          isTrial: false,
        };

        checkoutSetFields(checkout);
      }

      render() {
        if (this.state.hasError) {
          return redirectToLandingPageWithError(CHECKOUT_TYPE_SUBSCRIPTION);
        }

        return <WrappedComponent {...this.props} />;
      }
    }
  );

export default ViewSubscriptionCheckout(CheckoutContainer);
