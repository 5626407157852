import React, { Component } from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import CardForm from './CardForm';
import { STRIPE_PK } from '../../index';

class StripeCheckout extends Component {
  render() {
    const { elementFontSize } = this.state;
    const {
      checkoutCreateSource,
      checkoutSetSourceId,
      createSource,
      checkoutSetSourceError,
      closeDialog,
      checkoutCreateCard,
      createStripeCardRequest,
      createStripeCardSuccess,
      createStripeCardFailure,
      loading,
    } = this.props;

    return (
      <StripeProvider apiKey={STRIPE_PK}>
        <Elements locale={'de'}>
          <CardForm
            checkoutCreateSource={checkoutCreateSource}
            checkoutSetSourceId={checkoutSetSourceId}
            createSource={createSource}
            checkoutSetSourceError={checkoutSetSourceError}
            closeDialog={closeDialog}
            fontSize={elementFontSize}
            checkoutCreateCard={checkoutCreateCard}
            createStripeCardRequest={createStripeCardRequest}
            createStripeCardSuccess={createStripeCardSuccess}
            createStripeCardFailure={createStripeCardFailure}
            loading={loading}
          />
        </Elements>
      </StripeProvider>
    );
  }
}

export default StripeCheckout;
