import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import { Dialog as DialogType } from '../../redux/dialog';
import { setDialogClosed } from '../../actions/dialog';
import { DeliveryDialogWithData } from './withDelivery';
import CheckoutDialog from './CheckoutDialog';
import ActionDialog from '../../components/dialog/ActionDialog';
import { deletePaymentSource } from '../../actions/payment';
import { deleteAddress } from '../../actions/address';
import Progress from '../../components/progress/Progress';
import './Dialog.css';
import {
  deleteSubscription,
  pauseSubscription,
  resumeSubscription,
} from '../../actions/subscription';
import CheckoutPaymentDialog from './CheckoutPaymentDialog';
import CheckoutAddressDialog from './CheckoutAddressDialog';

class DialogContainer extends Component {
  render() {
    const dialog: DialogType = this.props.dialog;
    const isLoading = Object.values(this.props.loading).some(l => l === true);

    return (
      <Dialog
        open={dialog.open}
        onClose={isLoading ? () => {} : this.props.setDialogClosed}
      >
        <Progress loading={this.props.loading} />
        {dialog.target === 'delivery' ? <DeliveryDialogWithData /> : null}
        {dialog.target === 'address' ? <CheckoutAddressDialog /> : null}
        {dialog.target === 'confirmation' ? <CheckoutDialog /> : null}
        {dialog.target === 'payments' || dialog.target === 'payment' ? (
          <CheckoutPaymentDialog />
        ) : null}

        {dialog.target === 'deletePayment' ? (
          <ActionDialog
            title={'Zahlungsmethode löschen'}
            text={'Möchten Sie die Zahlungsmethode wirklich löschen?'}
            onSuccess={() =>
              this.props.deletePaymentSource(
                dialog.targetId,
                null,
                this.props.setDialogClosed
              )
            }
            onDismiss={() => this.props.setDialogClosed()}
          />
        ) : null}
        {dialog.target === 'deleteAddress' ? (
          <ActionDialog
            title={'Addresse löschen'}
            text={'Möchten du die Addresse löschen?'}
            onSuccess={() => this.props.deleteAddress(dialog.targetId)}
            onDismiss={() => this.props.setDialogClosed()}
          />
        ) : null}
        {dialog.target === 'pauseSubscription' ? (
          <ActionDialog
            title={'Willst Du Dein Abonnement wirklich pausieren?'}
            text={
              <Fragment>
                <p className="dialog__text">
                  Mit dem Klick auf den Button "Abo pausieren", erhält Dein
                  Abonnement den Status pausiert. Somit erhältst Du keine
                  Lieferungen mehr, bis Dein Abo wieder aktiviert wurde.
                </p>
                <p className="dialog__text">
                  Wenn Du lediglich eine Lieferung überspringen möchtest, weil
                  Du beispielsweise im Urlaub oder auf Dienstreise bist, dann
                  wähle im Bereich Lieferungen das entsprechende Datum und setze
                  diese auf "nicht aktiv".
                </p>
              </Fragment>
            }
            onSuccess={() => this.props.pauseSubscription(dialog.targetId)}
            onDismiss={() => this.props.setDialogClosed()}
            successTitle={'Abo pausieren'}
          />
        ) : null}
        {dialog.target === 'resumeSubscription' ? (
          <ActionDialog
            title={'Abo wiederaufnehmen'}
            text={
              'Klicke jetzt auf "Abo wiederaufnehmen", um den Status Deines Abonnements wieder auf "aktiv" zu setzen. Du erhältst dann wieder regelmäßige Lieferungen, so wie Du es eingestellt hast.'
            }
            onSuccess={() => this.props.resumeSubscription(dialog.targetId)}
            onDismiss={() => this.props.setDialogClosed()}
            successTitle={'Abo wiederaufnehmen'}
          />
        ) : null}
        {dialog.target === 'cancelSubscription' ? (
          <ActionDialog
            title={'Willst Du Dein Abonnement wirklich löschen?'}
            text={
              'Mit dem Klick auf den Button "Abo löschen", wird Dein Abonnement unwiderruflich gelöscht. Um wieder zu starten, muss ein neues Abo erstellt werden'
            }
            onSuccess={() =>
              Promise.resolve()
                .then(() => this.props.deleteSubscription(dialog.targetId))
                .then(() => this.props.setDialogClosed())
            }
            onDismiss={() => this.props.setDialogClosed()}
            successTitle={'Abo löschen'}
          />
        ) : null}
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  dialog: state.dialog,
  loading: state.loading,
});

export default connect(
  mapStateToProps,
  {
    deleteAddress,
    setDialogClosed,
    deletePaymentSource,
    deleteSubscription,
    pauseSubscription,
    resumeSubscription,
  }
)(DialogContainer);
