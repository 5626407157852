import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from '../inputs/Select';
const CHECKOUT_ADD_NEW_CREDITCARD = 'Neue Kreditkarte hinzufügen';

class CardSelector extends Component {
  handlePaymentChange = ev => {
    const { value } = ev.target;
    if (value) {
      const paymentId = parseInt(ev.target.value, 10);
      const payment = this.props.paymentSources.find(p => p.id === paymentId);

      if (!payment) {
        return;
      }

      this.props.checkoutSetPaymentCard(payment);
      return;
    }

    this.props.checkoutRemovePaymentSource();
  };

  componentWillMount() {
    const { paymentSource, paymentSources = [] } = this.props;
    if (paymentSources.length === 0) {
      return;
    }

    if (
      paymentSource &&
      paymentSource.card &&
      paymentSource.card.hasOwnProperty('id')
    ) {
      return;
    }

    if (paymentSource && paymentSource.card) {
      return;
    }

    this.props.checkoutSetPaymentCard(paymentSources[0]);
  }

  render() {
    const { paymentSource, paymentSources = [] } = this.props;
    const paymentId =
      paymentSource &&
      paymentSource.card &&
      paymentSource.card.hasOwnProperty('id')
        ? paymentSource.card.id
        : '';

    const optionList = paymentSources.map(a => (
      <option key={`cardId${a.id}`} value={a.id}>
        {a.brand}, XXXX-XXXX-XXXX-
        {a.last4}, {a.exp_month}/{a.exp_year}
      </option>
    ));

    return (
      <div className="form-control__group">
        <Select
          fullWidth
          native
          onChange={this.handlePaymentChange}
          value={paymentId}
        >
          {optionList}
          <option value="">-- {CHECKOUT_ADD_NEW_CREDITCARD} --</option>
        </Select>

        {paymentId ? (
          <span
            className="link link--gutter"
            onClick={() => this.props.checkoutRemovePaymentSource()}
          >
            {CHECKOUT_ADD_NEW_CREDITCARD}
          </span>
        ) : null}
      </div>
    );
  }
}

CardSelector.propTypes = {
  checkoutRemovePaymentSource: PropTypes.func.isRequired,
  checkoutSetPaymentCard: PropTypes.func.isRequired,
  paymentSource: PropTypes.object,
  paymentSources: PropTypes.array,
};

export default CardSelector;
