import { SNACKBAR_OPEN, SNACKBAR_CLOSE } from '../constants/actionTypes';

export const setSnackbarOpen = payload => {
  return {
    type: SNACKBAR_OPEN,
    payload,
  };
};

export const setSnackbarClosed = () => {
  return {
    type: SNACKBAR_CLOSE,
  };
};
