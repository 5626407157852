// @flow

export default function(name: string): Promise<any> {
  return new Promise(resolve =>
    resolve(
      name &&
        window &&
        window.hasOwnProperty('dataLayer') &&
        typeof window.dataLayer.push === 'function' &&
        window.dataLayer.push({ event: name })
    )
  );
}
