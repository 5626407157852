// @flow

import * as React from 'react';
import './ActionDialog.css';
import Button from '../inputs/Button';

type Props = {
  title: string,
  text: string,
  successTitle: ?string,
  cancelTitle: ?string,
  onSuccess: Function,
  onDismiss: Function,
};

export default function({
  title,
  text,
  successTitle,
  cancelTitle,
  onSuccess,
  onDismiss,
}: Props) {
  return (
    <div className="action-dialog">
      <div className="action-dialog__title">{title}</div>
      <div className="action-dialog__content">{text}</div>
      <div className="action-dialog__actions">
        <Button onClick={() => onDismiss()} variant="flat">
          {cancelTitle || 'Abbrechen'}
        </Button>
        <Button onClick={() => onSuccess()} color={'primary'} variant="raised">
          {successTitle || 'Bestätigen'}
        </Button>
      </div>
    </div>
  );
}
