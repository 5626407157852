import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { DialogContent, DialogActions } from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';

const createOptions = fontSize => ({
  style: {
    base: {
      fontSize,
      color: '#424770',
      letterSpacing: '0.025em',
      fontFamily: 'Source Code Pro, Menlo, monospace',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
});

class CardForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: true,
    };
  }

  handleChange = change =>
    this.setState({ disabled: change.complete === false });

  handleCreate = () => {
    this.props.createStripeCardRequest();
    // todo: check if something changed in v2 (createToken)
    this.props.stripe.createToken().then(payload => {
      if (payload.hasOwnProperty('error')) {
        // todo: display error
        this.props.createStripeCardFailure();
        return;
      }

      if (payload.hasOwnProperty('token') === false) {
        // todo: display error
        this.props.createStripeCardFailure();
        return;
      }

      // const { brand, exp_month, exp_year, last4 } = payload.token.card;
      this.props.createStripeCardSuccess();
      this.props.checkoutCreateCard({
        token: payload.token.id,
        created_at: parseInt(payload.token.created, 10),
        livemode: payload.token.livemode,
      });
    });
  };

  render() {
    const { loading } = this.props;
    return (
      <Fragment>
        <DialogContent>
          <label>
            <CardElement
              onChange={this.handleChange}
              {...createOptions(this.props.fontSize)}
              hidePostalCode={true}
            />
          </label>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.props.closeDialog()}
            disabled={loading.source}
          >
            Verwerfen
          </Button>
          <Button
            onClick={() => this.handleCreate()}
            disabled={loading.source || this.state.disabled}
            variant="raised"
            color={'primary'}
          >
            Zahlungsmethode anlegen
          </Button>
        </DialogActions>
      </Fragment>
    );
  }
}

CardForm.propTypes = {
  checkoutCreateSource: PropTypes.func.isRequired,
  checkoutSetSourceError: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  checkoutCreateCard: PropTypes.func.isRequired,
  loading: PropTypes.object.isRequired,
};

export default injectStripe(CardForm);
