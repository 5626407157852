import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadDeliveries } from '../../actions/deliveries';
import { changeLocation } from '../../actions/router';
import { HeadlineTitle } from '../../components/typography/Headlines';
import Item from '../../components/lists/DeliveryListItem';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import type { Delivery } from '../../redux/deliveries';
import { dateFormatDeliveryDate } from '../../utils/dateHelper';
import { setDialogOpen } from '../../actions/dialog';
import { DELIVERIES_LIST_EMPTY } from '../../constants/constants';
import { NavLink } from 'react-router-dom';
import { ROUTE_PROFILE_CALENDAR } from '../../constants/routes';

class DeliveriesList extends Component {
  componentWillMount() {
    this.props.loadDeliveries();
  }

  render() {
    const {
      deliveries,
      showElements,
      setDialogOpen,
      showOnlyFirstClosedDelivery,
    } = this.props;

    const maxItems = showElements || deliveries.length;

    const closedDeliveries = deliveries
      .filter(
        (d: Delivery) =>
          showOnlyFirstClosedDelivery ? d.status === 'CLOSED' : d
      )
      .slice(-1);

    const upcomingDeliveries = deliveries.length ? (
      [
        ...closedDeliveries,
        ...deliveries.filter(
          (d: Delivery) =>
            showOnlyFirstClosedDelivery ? d.status !== 'CLOSED' : d
        ),
      ]
        .slice(0, maxItems)
        .map((d: Delivery) => {
          const deliveryDate = dateFormatDeliveryDate(d.delivery_date);

          return (
            <Grid key={d.id} item xs={12} sm={12}>
              <Item
                title={d.plan_title}
                servingOptionTitle={d.serving_option_title}
                deliveryState={d.status}
                deliveryDate={deliveryDate}
                isTrial={d.is_trial}
                isGift={d.is_giftbox}
                editHandler={() =>
                  setDialogOpen({
                    target: 'delivery',
                    targetId: d.id,
                  })
                }
              />
            </Grid>
          );
        })
    ) : (
      <Grid item xs={12}>
        <Typography variant="body1">
          {this.props.loading.deliveries
            ? 'Lieferungen werden geladen...'
            : DELIVERIES_LIST_EMPTY}
        </Typography>
      </Grid>
    );

    const ShowAllDeliveries = () => (
      <NavLink to={ROUTE_PROFILE_CALENDAR} className="link link--gutter">
        Alle Lieferungen anzeigen
      </NavLink>
    );

    return (
      <Fragment>
        <HeadlineTitle title={'Meine Lieferungen'} />
        <Grid container spacing={16}>
          {upcomingDeliveries}
        </Grid>
        {deliveries.length > 0 && this.props.showAllElementsLink ? (
          <ShowAllDeliveries />
        ) : null}
      </Fragment>
    );
  }
}

DeliveriesList.propTypes = {
  deliveries: PropTypes.array.isRequired,
  showElements: PropTypes.number,
  showAllElementsLink: PropTypes.bool,
  showOnlyFirstClosedDelivery: PropTypes.bool,
};

const mapStateToProps = state => ({
  dialog: state.dialog,
  deliveries: state.deliveries,
  loading: state.loading,
});

export default connect(
  mapStateToProps,
  {
    loadDeliveries,
    changeLocation,
    setDialogOpen,
  }
)(DeliveriesList);
