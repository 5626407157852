import React, { Component } from 'react';
import SubscriptionEdit from '../../components/subscription/SubscriptionEdit';
import { connect } from 'react-redux';
import {
  loadSubscriptions,
  updateSubscription,
} from '../../actions/subscription';

const mapStateToProps = (state, ownProps) => ({
  subscriptionId: ownProps.match.params.id
    ? parseInt(ownProps.match.params.id, 10)
    : undefined,
  subscriptions: state.subscriptions,
  plans: state.plan,
  loading: state.loading.subscription,
});

const mapDispatchToProps = {
  loadSubscriptions,
  updateSubscription,
};

const ViewSubscriptionEdit = WrappedComponent =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class extends Component {
      constructor(props) {
        super(props);
        this.state = {
          loaded: false,
          hasError: false,
          subscriptionId: null,
          subscription: {},
        };
      }

      componentDidMount() {
        const subscriptionId = this.props.match.params.id
          ? parseInt(this.props.match.params.id, 10)
          : null;

        if (subscriptionId) {
          this.setState({
            subscriptionId,
          });
        }

        this.props.loadSubscriptions();
      }

      componentWillReceiveProps(nextProps) {
        if (this.state.loaded === false && !this.state.subscription.id) {
          const subscription = nextProps.subscriptions.find(
            s => s.id === this.state.subscriptionId
          );

          if (subscription) {
            this.setState({
              subscription,
              loaded: true,
            });
          }
        }
      }

      render() {
        if (!this.state.subscription.id) {
          return <div>...Wird geladen</div>;
        }

        return (
          <WrappedComponent
            subscription={this.state.subscription}
            plans={this.props.plans}
            updateSubscription={this.props.updateSubscription}
            loading={this.props.loading}
          />
        );
      }
    }
  );

export default ViewSubscriptionEdit(SubscriptionEdit);
