// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { changeUserPassword, passwordResetConfirm } from '../../actions/user';
import {
  BUTTON_PW_RESET_CONFIRM,
  FORM_LOGIN_LABEL_CURRENT_PASSWORD,
  FORM_LOGIN_LABEL_NEW_CURRENT_PASSWORD,
} from '../../constants/constants';
import FormControl from '@material-ui/core/FormControl';
import { appChangeLocation } from '../../actions/router';
import { ROUTE_PROFILE } from '../../constants/routes';
import { HeadlineTitle } from '../../components/typography/Headlines';
import InputField from '../../components/inputs/InputField';
import Button from '../../components/inputs/Button';

const PASSWORDS_EQUAL = 'Die Passwörter sind identisch.';

type Props = {
  changeUserPassword: Function,
  appChangeLocation: Function,
  loading: boolean,
};

type State = {
  hasError: boolean,
  errorMessage: string,
  currentPassword: string,
  newPassword: string,
};

export class PasswordChange extends React.Component<Props, State> {
  state = {
    hasError: false,
    errorMessage: '',
    currentPassword: '',
    newPassword: '',
  };

  handleChange = (name: string) => (event: any) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSubmit = async (event: any) => {
    event.preventDefault();
    const { currentPassword, newPassword } = this.state;

    if (currentPassword === '' || newPassword === '') {
      this.setState({
        hasError: true,
      });
      return;
    }

    if (currentPassword === newPassword) {
      this.setState({
        hasError: true,
        errorMessage: PASSWORDS_EQUAL,
      });
      return;
    }

    this.props
      .changeUserPassword({
        new_password: newPassword,
        current_password: currentPassword,
      })
      .then(() => this.props.appChangeLocation(ROUTE_PROFILE))
      .catch(() => this.setState({ hasError: true }));
  };

  render() {
    const { loading } = this.props;
    const { newPassword, currentPassword, hasError, errorMessage } = this.state;
    const disabled = loading || newPassword === '' || currentPassword === '';

    return (
      <div>
        <HeadlineTitle title={'Passwort ändern'} />
        <p>
          Lege nun ein neues Passwort fest und wiederhole das Passwort im
          darauffolgenden Formularfeld.
        </p>
        <form onSubmit={this.handleSubmit}>
          <div className={'form__group'}>
            <InputField
              fullWidth
              id={'passwort-reset-aktuelles-passwort'}
              type={'password'}
              hasError={hasError}
              label={FORM_LOGIN_LABEL_CURRENT_PASSWORD}
              placeholder={FORM_LOGIN_LABEL_CURRENT_PASSWORD}
              value={currentPassword}
              disabled={loading}
              onChange={this.handleChange('currentPassword')}
            />
          </div>
          <div className={'form__group'}>
            <InputField
              fullWidth
              type={'password'}
              hasError={hasError}
              label={FORM_LOGIN_LABEL_NEW_CURRENT_PASSWORD}
              placeholder={FORM_LOGIN_LABEL_NEW_CURRENT_PASSWORD}
              value={newPassword}
              disabled={loading}
              onChange={this.handleChange('newPassword')}
              id={'passwort-reset-neues-passwort'}
            />
          </div>
          {errorMessage && errorMessage !== '' ? (
            <p className="error__text">{errorMessage}</p>
          ) : null}
          <FormControl>
            <Button
              type={'submit'}
              variant="raised"
              color="primary"
              disabled={disabled}
            >
              {BUTTON_PW_RESET_CONFIRM}
            </Button>
          </FormControl>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loading: state.loading.user,
  ...ownProps.match.params,
  app: state.app,
});

export default connect(
  mapStateToProps,
  {
    passwordResetConfirm,
    appChangeLocation,
    changeUserPassword,
  }
)(PasswordChange);
