import React from 'react';
import { connect } from 'react-redux';
import {
  checkoutSetFields,
  checkoutStartRedeemGiftBox,
} from '../../actions/checkout';
import CheckoutContainer from './CheckoutContainer';
import type { Plan } from '../../redux/plans';
import { CHECKOUT_TYPE_GIFTBOX_REDEEM } from '../../redux/checkout';
import {
  getGiftBoxRedeemFromUrlParams,
  redirectToLandingPageWithError,
} from '../../utils/checkout';
import { API_ROOT } from '../../index';
import { headers } from '../../middleware/api';
import Button from '@material-ui/core/Button';
import { getContactSourceFromQueryParams } from '../../utils/contactSource';
import { setContactSource } from '../../actions/user';

const mapStateToProps = state => ({
  plans: state.plan,
  isAuthorized: state.user.isAuthorized,
  checkout: state.checkout,
});

const mapDispatchToProps = {
  checkoutSetFields,
  checkoutStartRedeemGiftBox,
  setContactSource,
};

const ViewGiftBoxRedeemCheckout = WrappedComponent =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          hasError: false,
        };
      }

      async componentWillMount() {
        const { isAuthorized, plans, setContactSource } = this.props;

        setContactSource(getContactSourceFromQueryParams());

        const urlParams = new URLSearchParams(window.location.search);
        const { code, deliveryDate, hasError } = getGiftBoxRedeemFromUrlParams(
          urlParams
        );

        if (hasError) {
          this.setState({ hasError: true });
          return;
        }

        const voucher = await this.handleVoucherCodeCheck(code);
        if (voucher.valid) {
          const selectedPlan: Plan = plans.find(p => p.id === voucher.plan);

          if (!selectedPlan) {
            this.setState({ hasError: true });
            return;
          }

          const start =
            selectedPlan.delivery_dates.indexOf(deliveryDate) === -1
              ? selectedPlan.delivery_dates[0]
              : deliveryDate;

          return this.props.checkoutStartRedeemGiftBox({
            isNewCustomer: isAuthorized === false,
            productType: CHECKOUT_TYPE_GIFTBOX_REDEEM,
            firstDeliveryDate: start,
            isSubscription: false,
            isDeliveryOnly: true,
            planId: voucher.plan,
            servingOptionId: voucher.serving_option,
            giftBoxVoucherCode: voucher.valid ? code : null,
          });
        }

        return this.props.checkoutSetFields({
          productType: CHECKOUT_TYPE_GIFTBOX_REDEEM,
          giftBoxVoucherCode: voucher.valid ? code : null,
        });
      }

      async handleVoucherCodeCheck(code) {
        try {
          const response = await fetch(`${API_ROOT}/api/v1/giftbox/${code}/`, {
            headers,
          });
          if ((await response.status) !== 200) {
            throw new Error();
          }
          return await response.json();
        } catch (err) {
          return {
            valid: false,
          };
        }
      }

      render() {
        if (this.state.hasError) {
          return redirectToLandingPageWithError(CHECKOUT_TYPE_GIFTBOX_REDEEM);
        }

        if (this.props.checkout.giftBoxVoucherCode === undefined) {
          return (
            <div>
              <p>Wird geladen...</p>
            </div>
          );
        }

        if (this.props.checkout.giftBoxVoucherCode === null) {
          return (
            <div>
              <p>Es wurde leider kein gültiger Rabattcode gefunden.</p>
              <Button
                color="primary"
                href="https://www.juts.ch?ref=giftBoxVoucherInvalid"
              >
                Zurück zu juts.ch
              </Button>
            </div>
          );
        }

        return <WrappedComponent {...this.props} />;
      }
    }
  );

export default ViewGiftBoxRedeemCheckout(CheckoutContainer);
