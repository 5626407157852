import { CALL_API } from '../middleware/api';

// address index request
export const PLANS_REQUEST = 'PLANS_REQUEST';
export const PLANS_SUCCESS = 'PLANS_SUCCESS';
export const PLANS_FAILURE = 'PLANS_FAILURE';

const fetchPlans = () => ({
  type: 'BOXES_REQUEST_START',
  [CALL_API]: {
    types: [PLANS_REQUEST, PLANS_SUCCESS, PLANS_FAILURE],
    endpoint: `/api/v1/plans/`,
  },
});

export const loadPlans = () => dispatch => dispatch(fetchPlans());
