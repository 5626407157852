import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { loadSubscriptions } from '../../actions/subscription';
import Grid from '@material-ui/core/Grid';
import { changeLocation } from '../../actions/router';
import { HeadlineTitle } from '../../components/typography/Headlines';
import SubscriptionListItem from '../../components/lists/SubscriptionListItem';
import type { Subscription } from '../../redux/subscriptions';
import { getProductImage } from '../../utils/format';
import { ROUTE_CHECKOUT_SUBSCRIPTION } from '../../constants/routes';
import { NavLink } from 'react-router-dom';
import SubscriptionListItemMenu from './SubscriptionListItemMenu';
import { setDialogOpen } from '../../actions/dialog';
import { NoActiveSubscriptions } from '../../constants/constants';

class SubscriptionList extends Component {
  componentWillMount() {
    this.props.loadSubscriptions();
  }

  render() {
    const { subscriptions, plans, setDialogOpen } = this.props;

    if (plans.length === 0) {
      return null;
    }

    return (
      <Fragment>
        <HeadlineTitle title={'Meine Abos'} />

        <Grid container spacing={16}>
          {subscriptions.length > 0 ? (
            subscriptions.map((s: Subscription) => (
              <Grid item key={s.id} xs={12}>
                <SubscriptionListItem
                  id={s.id}
                  title={s.plan_title}
                  start={s.start}
                  end={s.end_date}
                  servingOptionTitle={s.serving_option_title}
                  imageSrc={getProductImage(s.plan)}
                  status={s.status}
                  editComponent={
                    <SubscriptionListItemMenu
                      status={s.status}
                      subscriptionId={s.id}
                      isEditable={s.is_editable}
                      setDialogOpen={setDialogOpen}
                    />
                  }
                />
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              {this.props.loading.subscription ? (
                <p>Abonnements werden geladen...</p>
              ) : (
                <NoActiveSubscriptions />
              )}
            </Grid>
          )}
        </Grid>
        {subscriptions.length > 0 ? (
          <NavLink
            className="link link--gutter"
            to={`${ROUTE_CHECKOUT_SUBSCRIPTION}?dialog=true&default=true`}
          >
            Zusätzliches Abo hinzufügen
          </NavLink>
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.loading,
  subscriptions: state.subscriptions,
  plans: state.plan,
});

export default connect(
  mapStateToProps,
  {
    changeLocation,
    loadSubscriptions,
    setDialogOpen,
  }
)(SubscriptionList);
