import {
  USER_AUTH_FROM_LOCAL_STORAGE,
  USER_SET_CONTACT_SOURCE,
  USER_LOGOUT,
} from '../constants/actionTypes';
import { CALL_API, callApi, fetchApi } from '../middleware/api';
import { appChangeLocation, changeLocation } from './router';
import { ROUTE_PROFILE } from '../constants/routes';
import dataLayerPush from '../utils/dataLayerPush';

// user change password
const userErrorMessages = error => {
  if (error && Object.keys(error).length) {
    return Object.keys(error)
      .map(key => error[key])
      .join(' ');
  }

  return false;
};

export const userLogout = () => dispatch =>
  Promise.resolve()
    .then(() => dispatch({ type: USER_LOGOUT }))
    .then(() => dispatch(appChangeLocation('/login')));

export const userLoginFromLocalStorage = payload => ({
  type: USER_AUTH_FROM_LOCAL_STORAGE,
  payload,
});

export const setContactSource = contactSource => ({
  type: USER_SET_CONTACT_SOURCE,
  payload: contactSource,
});

// user login
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';

export const loginUser = (
  credentials,
  redirect,
  postAction
) => async dispatch => {
  dispatch({ type: USER_LOGIN_REQUEST });

  try {
    const payload = await fetchApi({
      endpoint: '/api/v1/authorization/token/',
      method: 'POST',
      payload: credentials,
    });

    await dispatch({ type: USER_LOGIN_SUCCESS, payload });
    await dispatch(appChangeLocation(redirect));

    if (postAction) {
      await dispatch(postAction);
    }

    return Promise.resolve(payload);
  } catch (err) {
    dispatch({ type: USER_LOGIN_FAILURE, error: err.message });
    const error = await err;
    return Promise.reject(error);
  }
};

export const loginUserDataLayerPush = (
  credentials,
  redirect,
  postAction
) => async dispatch => {
  try {
    const payload = await dispatch(
      loginUser(credentials, redirect, postAction)
    );

    dataLayerPush('IsCustomer');

    return Promise.resolve(payload);
  } catch (err) {
    const error = await err;
    return Promise.reject(error);
  }
};

export const USER_LOGIN_FROM_TOKEN_ONLY = 'USER_LOGIN_FROM_TOKEN_ONLY';
export const loginUserFromToken = payload => dispatch =>
  Promise.resolve()
    .then(() =>
      dispatch({
        type: USER_LOGIN_FROM_TOKEN_ONLY,
        payload,
      })
    )
    .then(() => dispatch(appChangeLocation('/')));

// user register
export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAILURE = 'USER_REGISTER_FAILURE';

export const registerUser = (data, redirect, postAction) => async (
  dispatch,
  getState
) => {
  dispatch({ type: USER_REGISTER_REQUEST });

  const first_contact_source = getState().user.firstContactSource;
  const last_contact_source = getState().user.lastContactSource;

  try {
    const payload = await fetchApi({
      endpoint: '/api/v1/authorization/users/create/',
      method: 'POST',
      payload: { ...data, first_contact_source, last_contact_source },
    });
    dispatch({ type: USER_REGISTER_SUCCESS, payload });

    if (redirect) {
      dispatch(appChangeLocation(redirect));
    }

    if (postAction) {
      dispatch(postAction);
    }
  } catch (err) {
    const { error } = await err;

    const message = userErrorMessages(error);
    if (message) {
      dispatch({
        type: USER_REGISTER_FAILURE,
        payload: { message },
      });
    } else {
      dispatch({ type: USER_REGISTER_FAILURE });
    }

    return Promise.reject(error);
  }
};

// get user information
export const USER_INFO_REQUEST = 'USER_INFO_REQUEST';
export const USER_INFO_SUCCESS = 'USER_INFO_SUCCESS';
export const USER_INFO_FAILURE = 'USER_INFO_FAILURE';

const fetchUser = login => ({
  type: 'USER_INFO_REQUEST_START',
  [CALL_API]: {
    types: [USER_INFO_REQUEST, USER_INFO_SUCCESS, USER_INFO_FAILURE],
    endpoint: `/api/v1/users/${login}/`,
  },
});

export const loadUser = login => dispatch => dispatch(fetchUser(login));

export const USER_PASSWORD_CHANGE_REQUEST = 'USER_PASSWORD_CHANGE_REQUEST';
export const USER_PASSWORD_CHANGE_SUCCESS = 'USER_PASSWORD_CHANGE_SUCCESS';
export const USER_PASSWORD_CHANGE_FAILURE = 'USER_PASSWORD_CHANGE_FAILURE';

export const changeUserPassword = data => async (dispatch, getState) => {
  dispatch({ type: USER_PASSWORD_CHANGE_REQUEST });
  try {
    const payload = await fetchApi({
      endpoint: '/api/v1/authorization/password/',
      method: 'POST',
      authorization: getState().app.accessToken,
      payload: data,
    });
    dispatch({ type: USER_PASSWORD_CHANGE_SUCCESS, payload });
    return Promise.resolve(payload);
  } catch (err) {
    const { error } = await err;
    const message = userErrorMessages(error);
    dispatch({ type: USER_PASSWORD_CHANGE_FAILURE, payload: { message } });
    return Promise.reject(error);
  }
};

// user password reset confirm
export const USER_PASSWORD_RESET_CONFIRM_REQUEST =
  'USER_PASSWORD_RESET_CONFIRM_REQUEST';
export const USER_PASSWORD_RESET_CONFIRM_SUCCESS =
  'USER_PASSWORD_RESET_CONFIRM_SUCCESS';
export const USER_PASSWORD_RESET_CONFIRM_FAILURE =
  'USER_PASSWORD_RESET_CONFIRM_FAILURE';

export const passwordResetConfirm = data => async (dispatch, getState) => {
  dispatch({ type: USER_PASSWORD_RESET_CONFIRM_REQUEST });
  try {
    return await callApi(
      '/api/v1/authorization/password/reset/confirm/',
      'POST',
      null,
      data
    )
      .then(() => dispatch({ type: USER_PASSWORD_RESET_CONFIRM_SUCCESS }))
      .then(() => dispatch(changeLocation('/login')));
  } catch (err) {
    dispatch({ type: USER_PASSWORD_RESET_CONFIRM_FAILURE, payload: err });
  }
};

// login user
export const USER_TOKEN_REFRESH_REQUEST = 'USER_TOKEN_REFRESH_REQUEST';
export const USER_TOKEN_REFRESH_SUCCESS = 'USER_TOKEN_REFRESH_SUCCESS';
export const USER_TOKEN_REFRESH_FAILURE = 'USER_TOKEN_REFRESH_FAILURE';

export const refreshToken = token => async dispatch => {
  dispatch({ type: USER_TOKEN_REFRESH_REQUEST });

  try {
    const payload = await fetchApi({
      endpoint: '/api/v1/authorization/refresh/',
      method: 'POST',
      payload: { token },
    });

    dispatch({ type: USER_TOKEN_REFRESH_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: USER_TOKEN_REFRESH_FAILURE });
    dispatch(userLogout());
  }
};

// user change email
export const USER_EMAIL_CHANGE_REQUEST = 'USER_EMAIL_CHANGE_REQUEST';
export const USER_EMAIL_CHANGE_SUCCESS = 'USER_EMAIL_CHANGE_SUCCESS';
export const USER_EMAIL_CHANGE_FAILURE = 'USER_EMAIL_CHANGE_FAILURE';

export const changeUserEmail = data => async (dispatch, getState) => {
  dispatch({ type: USER_EMAIL_CHANGE_REQUEST });
  try {
    const payload = await fetchApi({
      endpoint: '/api/v1/authorization/email/',
      method: 'POST',
      authorization: getState().app.accessToken,
      payload: { new_email: data.email, current_password: data.password },
    });
    dispatch({ type: USER_EMAIL_CHANGE_SUCCESS, payload });
    dispatch(loginUser(data, ROUTE_PROFILE, undefined));
    return payload;
  } catch (err) {
    const { error } = await err;
    dispatch({ type: USER_EMAIL_CHANGE_FAILURE });
    return Promise.reject(error);
  }
};

// login user
export const USER_PASSWORD_RESET_REQUEST = 'USER_PASSWORD_RESET_REQUEST';
export const USER_PASSWORD_RESET_SUCCESS = 'USER_PASSWORD_RESET_SUCCESS';
export const USER_PASSWORD_RESET_FAILURE = 'USER_PASSWORD_RESET_FAILURE';

export const requestUserPassword = (data, redirect) => async dispatch => {
  dispatch({ type: USER_PASSWORD_RESET_REQUEST });

  try {
    return await fetchApi({
      endpoint: '/api/v1/authorization/password/reset/',
      method: 'POST',
      payload: data,
    })
      .then(() => dispatch({ type: USER_PASSWORD_RESET_SUCCESS }))
      .then(() => dispatch(appChangeLocation(redirect)));
  } catch (err) {
    dispatch({ type: USER_PASSWORD_RESET_FAILURE });
    const { error } = await err;
    return Promise.reject(error);
  }
};
