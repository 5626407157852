import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from '../inputs/Select';
const ADDRESS_SELECTOR_NEW_ADDRESS = 'Neue Adresse hinzufügen';

class AddressSelector extends Component {
  handleAddressChange = ev => {
    const { value } = ev.target;
    const { addressType } = this.props;
    if (value) {
      const addressId = parseInt(ev.target.value, 10);
      const address = this.props.addresses.find(a => a.id === addressId);

      if (!address) {
        return;
      }

      this.props.setAddress({
        addressType,
        address,
      });
      return;
    }

    this.props.setAddress({
      addressType,
      address: undefined,
    });
  };

  componentWillMount() {
    const { address, addresses, addressType } = this.props;
    if (addresses.length === 0) {
      return;
    }

    if (address && address.hasOwnProperty('id')) {
      return;
    }

    if (address) {
      return;
    }

    this.props.setAddress({
      addressType,
      address: addresses[0],
    });
  }

  render() {
    const { address = {}, addresses = [], isLoading = false } = this.props;
    const addressId = address && address.id ? address.id : '';
    const optionList = addresses.map(a => (
      <option key={`addressId${a.id}`} value={a.id}>
        {a.first_name} {a.last_name}, {a.street}, {a.zip} {a.city}
      </option>
    ));

    return (
      <div className="form-control__group">
        <Select
          fullWidth
          onChange={this.handleAddressChange}
          value={addressId}
          disabled={isLoading}
        >
          {optionList}
          <option value="">-- {ADDRESS_SELECTOR_NEW_ADDRESS} --</option>
        </Select>
        {addressId ? (
          <span
            className="link link--gutter"
            onClick={() =>
              this.props.setAddress({
                addressType: this.props.addressType,
                address: undefined,
              })
            }
          >
            {ADDRESS_SELECTOR_NEW_ADDRESS}
          </span>
        ) : null}
      </div>
    );
  }
}

AddressSelector.propTypes = {
  addressType: PropTypes.string.isRequired,
  setAddress: PropTypes.func.isRequired,
  addresses: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default AddressSelector;
