// @flow
import { SNACKBAR_CLOSE, SNACKBAR_OPEN } from '../constants/actionTypes';
import {
  USER_LOGIN_FAILURE,
  USER_LOGIN_SUCCESS,
  USER_PASSWORD_CHANGE_FAILURE,
  USER_PASSWORD_CHANGE_SUCCESS,
  USER_PASSWORD_RESET_CONFIRM_FAILURE,
  USER_PASSWORD_RESET_CONFIRM_SUCCESS,
  USER_PASSWORD_RESET_FAILURE,
  USER_PASSWORD_RESET_SUCCESS,
  USER_REGISTER_FAILURE,
} from '../actions/user';
import { user } from '../constants/messages';
import { CHECKOUT_NEW_SUBMIT_FAILURE } from '../actions/checkout';

export type Snack = {};

type DefaultState = {
  open: boolean,
  message: string,
  messages: Snack[],
};

const initialState: DefaultState = {
  open: false,
  text: '',
  message: '',
  messages: [],
};

const addSnack = (actionType, payload) => {
  const message =
    payload && payload.hasOwnProperty('message')
      ? payload.message
      : user[actionType];

  return {
    open: true,
    message,
  };
};

export default (state: DefaultState = initialState, action: any) => {
  switch (action.type) {
    case SNACKBAR_OPEN: {
      return {
        ...state,
        open: true,
        message: action.payload.message,
      };
    }

    case USER_LOGIN_SUCCESS:
    case SNACKBAR_CLOSE: {
      return {
        ...state,
        open: false,
        message: '',
      };
    }

    case USER_LOGIN_FAILURE:
    case USER_REGISTER_FAILURE:
    case USER_PASSWORD_RESET_SUCCESS:
    case USER_PASSWORD_RESET_FAILURE:
    case USER_PASSWORD_CHANGE_SUCCESS:
    case USER_PASSWORD_CHANGE_FAILURE:
    case USER_PASSWORD_RESET_CONFIRM_SUCCESS:
    case USER_PASSWORD_RESET_CONFIRM_FAILURE:
    case CHECKOUT_NEW_SUBMIT_FAILURE:
      return addSnack(action.type, action.payload);

    default:
      return state;
  }
};
