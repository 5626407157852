import {
  APP_SET_TOKEN,
  APP_TOGGLE_NAVIGATION,
  APP_TOGGLE_NAVIGATION_CLOSE,
  USER_LOGOUT,
} from '../constants/actionTypes';
import { PLANS_FAILURE } from '../actions/plans';

const initialState = {
  accessToken: null,
  navigationOpen: false,
  backendServiceAvailable: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case APP_SET_TOKEN: {
      return { accessToken: action.payload.token };
    }
    case APP_TOGGLE_NAVIGATION: {
      return {
        ...state,
        navigationOpen: !state.navigationOpen,
      };
    }
    case APP_TOGGLE_NAVIGATION_CLOSE: {
      return {
        ...state,
        navigationOpen: false,
      };
    }
    case USER_LOGOUT: {
      return initialState;
    }
    case PLANS_FAILURE: {
      return {
        ...state,
        backendServiceAvailable: false,
      };
    }
    default:
      return state;
  }
};
